// TODO: spelling / grammar check
export default {
  // Maintenance message
  maintenance: [
    'Servidores en mantenimiento. En breve podrás volver a acceder a My inBiot. Disculpa las molestias.',
    'Servers in maintenance. Shortly you will be able to access My inBiot again. Sorry for the inconvenience.',
    'Server in Wartung. In Kürze werden Sie wieder auf Mein inBiot zugreifen können. Entschuldigung für die Unannehmlichkeiten.'
  ],
  companyPost: ['Cargo en la empresa', 'Position in the company', 'Position in der empressa'],
  whatIsNew: {
    mainPageTitle: [
      '¡Nuevos Informes de Estado de Calibración!',
      'New Calibration Status Reports!',
      'Neue Kalibrierungsstatusberichte!'
    ],
    contentDescription: [
      'Ahora podrás verificar fácilmente el estado de calibración de tus dispositivos y justificar el correcto rendimiento de los sensores en tus proyectos más exigentes, incluyendo los de certificación WELL.',
      'Now you can easily verify the calibration status of your devices and justify the correct performance of the sensors in your most demanding projects, including those for WELL certification.',
      'Jetzt können Sie den Kalibrierungsstatus Ihrer Geräte leicht überprüfen und die korrekte Leistung der Sensoren in Ihren anspruchsvollsten Projekten, einschließlich der WELL-Zertifizierung, nachweisen.'
    ],
    explore: ['Explorar ahora', 'Explore now', 'Jetzt erforschen'],
    knowMore: ['Saber más', 'Know more', 'Mehr erfahren'],
    understood: [
      'Salir y no mostrar más',
      'Exit and show no more',
      'Beenden und nicht mehr anzeigen'
    ],
    exit: [
      'Salir y mostrar la próxima vez',
      'Exit and show next time',
      'Beenden und beim nächsten Mal anzeigen'
    ],

    close: ['Salir', 'Exit', 'Ausfahrt'],
    remindLater: ['¿Recordar más tarde?', 'Remind me later?', 'Erinnern Sie sich später?'],
    moreInformation: ['Más información', 'More information', 'Weitere Informationen'],
    remindMeLater: ['Recuérdamelo más tarde', 'Remind me later', 'Erinnern Sie mich später'],
    myInbiotNewsUnderstood: ['¡Entendido!', 'Understood!', "Ich hab's!"]
  },

  // Login & sign up
  discoverInbiot: ['Descubre inBiot', 'Discover inBiot', 'Entdecken Sie inBiot'],
  copyright: [
    'Copyright © inBiot. Todos los derechos reservados.',
    'Copyright © inBiot. All rights reserved.',
    'Copyright © inBiot. Alle Rechte vorbehalten.'
  ],
  login: ['Iniciar sesión', 'Login', 'Melden Sie sich an'],
  signUp: ['Crear cuenta', 'Sign Up', 'Registrierung'],
  premiumFunctionalityText: [
    'Pásate a My inBiot Business para disfrutar de esta funcionalidad. Consúltanos en info@inbiot.es',
    'Switch to My inBiot Business to enjoy this functionality. Contact us at info@inbiot.es',
    'Gehen Sie zu My inBiot Business, um diese Funktion zu nutzen. Kontaktieren Sie uns unter info@inbiot.es'
  ],
  contactUs: [
    'Consúltanos en info@inbiot.es',
    'Contact us at info@inbiot.es',
    'Kontaktieren Sie uns unter info@inbiot.es'
  ],
  premiumDownloadData: [
    'Pásate a My inBiot Business para disfrutar de esta funcionalidad. Puedes descargar datos de manera individual desde la vista de cada uno de sus dispositivos.',
    'Switch to My inBiot Business to enjoy this functionality. You can download data individually from the view of each of your devices.',
    'Wechseln Sie zu Mein inBiot Business, um diese Funktion zu nutzen. Sie können die Daten einzeln aus der Ansicht jedes Ihrer Geräte herunterladen.'
  ],
  businessPlan: ['Licencia Business', 'Business License', 'Business License'],
  comingSoon: ['Próximamente...', 'Coming soon...', 'Demnächst...'],
  firstName: ['Nombre', 'First name', 'Vorname'],
  lastName: ['Apellidos', 'Last name', 'Nachname'],
  country: ['País', 'Country', 'Land'],
  measurementSystem: ['Sistema de mediciones', 'Measurement system', 'Messsystem'],
  metricSystem: [
    'Sistema métrico (ºC, hPa)',
    'Metric system (ºC, hPa)',
    'Metrisches System (ºC, hPa)'
  ],
  imperialSystem: [
    'Sistema imperial (ºF, PSI)',
    'Imperial system (ºF, PSI)',
    'Imperiales System (ºF, PSI)'
  ],
  open: ['ABRIR', 'OPEN', 'ÖFFNEN'],
  company: ['Empresa', 'Company', 'Firmenname '],
  position: ['Cargo en la empresa', 'Company position', 'Position in der Firma'],
  otherPosition: ['Otro (Rellenar)', 'Other (Fill in)', 'Anderes (bitte ausfüllen)'],
  positionPlaceholder: ['Cargo en la empresa', 'Position in the company', 'Position in der Firma'],
  positions: {
    facilityManager: ['Facility Manager', 'Facility Manager', 'Facility Manager'],
    infraestructureManager: [
      'Responsable de infraestructuras',
      'Infrastructure Manager',
      'Infrastruktur-Manager'
    ],
    maintenanceManager: [
      'Responsable de mantenimiento',
      'Maintenance Manager',
      'Leiter Instandhaltung'
    ],
    rrhhManager: ['Responsable RRHH', 'RRHH Manager', 'RRHH-Manager'],
    rrllManager: [
      'Responsable prevención y RRLL',
      'Prevention and RRLL Manager',
      'Manager für Prävention und RRLL'
    ],
    hseManager: ['Responsable HSE', 'HSE Manager', 'HSE-Manager'],
    marketingManager: ['Responsable Marketing', 'Marketing Manager', 'Marketing-Manager'],
    rscManager: ['Responsable RSC', 'RSC Manager', 'RSC-Manager'],
    other: ['Otro', 'Other', 'Andere']
  },
  manageGroup: ['GESTIONAR GRUPO', 'MANAGE GROUP', 'GRUPPE VERWALTEN'],
  day: ['Día', 'Day', 'Tag'],
  hours: ['horas', 'hours', 'Stunden'],
  hour: ['Hora', 'Hour', 'Stunde'],
  week: ['semana', 'week', 'Woche'],
  weeks: ['semanas', 'weeks', 'Wochen'],
  month: ['mes', 'month', 'Monat'],
  months: ['meses', 'months', 'Monate'],
  email: ['Correo electrónico', 'Email address', 'E-Mail-Adresse'],
  emailContact: ['Correo de contacto', 'Contact Email', 'Kontaktpost'],
  phoneContact: ['Teléfono de contacto', 'Contact phone', 'Kontakt Telefon'],
  password: ['Contraseña', 'Password', 'Passwort'],
  oldPassword: ['Contraseña antigua', 'Old password', 'Altes Passwort'],
  newPassword: ['Contraseña nueva', 'New password', 'Neues Passwort'],
  repeatPassword: ['Repetir contraseña', 'Repeat password', 'Passwort bestätigen'],
  add: ['Añadir', 'Add', 'Hinzufügen'],
  myMicas: ['Mis Dispositivos', 'My Devices', 'Meine Geräte'],
  newGroup: ['Nuevo grupo', 'New group', 'Neue Gruppe'],
  multipleDownload: ['Descarga múltiple', 'Multiple download', 'Mehrfacher Download'],
  transferDevice: ['Transferir dispositivos', 'Transfer devices', 'Geräte übertragen'],
  devicesStatus: ['Estado de dispositivos', 'Device status', 'Gerätestatus'],
  indicateEmail: [
    'Indique el correo electrónico de la cuenta a la que desea transferir los dispositivios.',
    'Indicate the e-mail address of the account to which you wish to transfer the devices.',
    'Bitte geben Sie die E-Mail-Adresse des Kontos ein, auf das Sie die Geräte übertragen möchten.'
  ],
  emailUser: [
    'El correo debe estar asociado a una cuenta de My inBiot.',
    'The email must be associated with My inBiot account.',
    'Die E-Mail muss mit einem My inBiot-Konto verknüpft sein.'
  ],
  RemoveDevices: ['Quitar dispositivos', 'Remove devices', 'Geräte entfernen'],
  options: ['Opciones', 'Options', 'Auswahl'],
  summaryView: ['Resumen', 'Summary', 'Zusammenfassung'],
  detailView: ['En detalle', 'View details', 'Details anzeigen'],
  addSystem: ['AÑADIR DISPOSITIVO', 'ADD DEVICE', 'GERÄT HINZUFÜGEN'],
  addGroup: ['Añadir grupo', 'Add group', 'Gruppe hinzufügen'],
  editGroup: ['Editar grupo', 'Edit group', 'Gruppe bearbeiten'],
  groupName: ['Nombre del grupo', 'Group name', 'Gruppenname'],
  searchMica: ['Buscar MICA', 'Search MICA', 'Suche MICA'],
  searchDevice: ['Buscar dispositivo', 'Search device', 'Gerät suchen'],
  searchNameIdSn: ['Buscar (nombre/ID/SN)', 'Search (name/ID/SN)', 'Suche (Name/ID/SN)'],
  save: ['Guardar', 'Save', 'Speichern'],
  saveChanges: ['Guardar cambios', 'Save changes', 'Änderungen speichern'],
  edit: ['Editar', 'Edit', 'Bearbeiten'],
  editSystem: ['Editar dispositivo', 'Edit device', 'Gerät bearbeiten'],
  delete: ['Borrar', 'Delete', 'Löschen'],
  deleteSystem: ['Borrar dispositivo', 'Delete device', 'System löschen'],
  clearData: ['Limpiar datos', 'Clear data', 'Daten löschen'],
  downloadStatistics: [
    'Descargar estadísticos',
    'Download statistics',
    'Statistiken herunterladen'
  ],
  Personalization: ['Personalización', 'Personalization', 'die Personalisierung'],
  units: ['Unidades', 'Units', 'Einheiten'],
  dailyStatistics: ['Estadísticos diarios', 'Daily statistics', 'Tägliche Statistiken'],
  weeklyStatistics: ['Estadísticos semanales', 'Weekly statistics', 'Wöchentliche Statistiken'],
  monthlyStatistics: ['Estadísticos mensuales', 'Monthly statistics', 'Monatliche Statistiken'],
  dashboard: ['Dashboard', 'Dashboard', 'Dashboard'],
  home: ['Inicio', 'Home', 'Home'],
  parameters: ['Parámetros', 'Parameters', 'Parameter'],
  timeRange: ['Rango de tiempo', 'Time range', 'Zeitspanne'],
  chartAvg: ['med.', 'avg.', 'avg.'],
  chartMax: ['max.', 'max.', 'max.'],
  chartMin: ['max.', 'max.', 'max.'],
  indicators: ['Indicadores', 'Indicators', 'Indikatoren'],
  iaqIndicator: ['Indicador IAQ', 'IAQ Indicator', 'IAQ Indikator'],
  ventilationIndicator: [
    'Eficacia de Ventilación',
    'Ventilation Efficiency',
    'Effizienz der Belüftung'
  ],
  virusIndicator: ['Resistencia a Virus', 'Resistance to Virus', 'Virusresistenz'],
  thermalIndicator: ['Confort Térmico', 'Thermal Comfort', 'Thermischer Komfort'],
  iaqInterior: ['Calidad de Aire Interior', 'Indoor Air Quality', 'Luftqualität in Innenräumen'],
  indicatorThermico: ['Confort Térmico', 'Thermal Comfort', 'Thermischer Komfort'],
  ViricoIndicator: ['Resistencia a Virus', 'Resistance to Virus', 'Virusresistenz'],
  VentilaciónIndicator: [
    'Eficacia de Ventilación',
    'Ventilation Efficiency',
    'Qualität der Belüftung'
  ],

  chart: ['Gráfica', 'Chart', 'Grafik'],
  DESK: ['MICA Desk', 'MICA Desk', 'MICA Desk'],
  MICA_LITE: ['MICA Lite', 'MICA Lite', 'MICA Lite'],
  MICA_WELL: ['MICA WELL', 'MICA WELL', 'MICA WELL'],
  MICA_PLUS: ['MICA Plus', 'MICA Plus', 'MICA Plus'],
  MICA: ['MICA', 'MICA', 'MICA'],
  MICA_MINI: ['MICA Mini', 'MICA Mini', 'MICA Mini'],
  availability: ['Fechas disponibles', 'Dates available', 'Verfügbare Termine'],
  availabilityText: [
    'La cantidad de dispositivos y parámetros escogidos afectarán el intervalo de fechas seleccionable.',
    'The number of devices and parameters chosen will affect the selectable date range.',
    'Die Anzahl der Geräte und die gewählten Parameter beeinflussen den wählbaren Datumsbereich.'
  ],
  groupVis: ['Visualización de grupos', 'Group visualization', 'Gruppenanzeige'],
  recogidos: ['Grupos recogidos', 'Groups collected', 'Gruppen gesammelt'],
  desplegados: ['Grupos desplegados', 'Groups deployed', 'Eingesetzte Gruppen'],
  report: ['Informes Inteligentes', 'Smart Reports', 'Intelligente Berichterstattung'],
  compare: ['Comparar', 'Compare', 'Vergleichen'],
  fullscreenView: ['Pantallas', 'Views', 'Bildschirme'],
  shareables: ['Compartibles', 'Shareables', 'Shareables'],
  alerts: ['Alertas', 'Alerts', 'Warnungen'],
  certifications: ['Certificaciones', 'Certifications', 'Zertifizierungen'],

  transfertDeviceTitle: [
    'Solicitud de Tranferencia de Dispositivo',
    'Request for Device Transfer',
    'Anfrage zur Geräteübertragung'
  ],
  transdferDeviceFirstText: ['La cuenta ', 'The account ', 'Das Konto '],
  transdferDeviceSecondText: [
    ' ha solicitado transferir ',
    ' has requested to transfer ',
    ' hat beantragt, '
  ],

  transdferDeviceThirdText: [
    ' dispositivo a esta cuenta. Indica si deseas aceptar o denegar el traspaso.',
    ' device to this account. Indicate whether you want to accept or deny the transfer.',
    ' Gerät auf dieses Konto zu übertragen. Geben Sie an, ob Sie die Übertragung annehmen oder ablehnen möchten.'
  ],
  transdferDeviceFourthText: [
    ' dispositivos a esta cuenta. Indica si deseas aceptar o denegar el traspaso.',
    ' devices to this account. Indicate whether you want to accept or deny the transfer.',
    ' Geräte auf dieses Konto zu übertragen. Geben Sie an, ob Sie die Übertragung annehmen oder ablehnen möchten.'
  ],

  confirmDeleteFirstText: [
    'Confirma que deseas eliminar',
    'Confirm that you want to remove',
    'Bestätigen Sie, dass Sie Gerät'
  ],
  confirmDeleteSecondText: [
    'dispositivo de tu cuenta.',
    'device from your account.',
    'aus Ihrem Konto entfernen möchten.'
  ],
  confirmDeleteThirdText: [
    'dispositivos de tu cuenta.',
    'devices from your account.',
    'aus Ihrem Konto entfernen möchten.'
  ],

  confirmDeleteFourthText: [
    'Todas las alertas y compartibles creadas para este dispositivo se borrarán. Si deseas migrar el dispostivo de cuenta con sus alertas y compartibles incluídas, puedes hacerlo desde la opción “Transferir dispositivos”.',
    'All alerts and shareables created for this device will be deleted. If you want to migrate the device from the account with its alerts and shareables included, you can do it from the "Transfer devices" option.',
    'Alle für dieses Gerät erstellten Warnungen und Freigaben werden gelöscht. Wenn Sie das Kontogerät mit seinen Warnungen und Freigaben migrieren möchten, können Sie dies über die Option "Geräte übertragen" tun.'
  ],

  confirmTransferFirstText: [
    'Confirma que deseas transferir ',
    'Confirm that you want to transfer ',
    'Bestätigen Sie, dass Sie '
  ],

  confirmTransferSecondText: [
    ' dispositivos a la cuenta  ',
    ' devices to the account ',
    ' Geräte auf das Konto '
  ],

  confirmTransferFourthText: [
    'Todas las alertas y compartibles creadas para este dispositivo migrarán a la nueva cuenta y ya no podrás visualizar los datos de las mediciones. ',
    'All alerts and sharables created for this device will migrate to the new account and you will no longer be able to view the measurement data. ',
    'Alle für dieses Gerät erstellten Warnungen und Freigaben werden auf das neue Konto übertragen und Sie können die Messdaten nicht mehr anzeigen. '
  ],

  confirmTransferThirdText: [
    ' dispositivo  a la cuenta  ',
    ' device to the account ',
    ' Gerät auf das Konto '
  ],

  transferButton: ['TRANSFERIR', 'TRANSFER', 'ÜBERWEISUNG'],

  cancelButton: ['ELIMINAR', 'REMOVE', 'ENTFERNEN'],
  infoTextTransfer: [
    'La cuenta de destino deberá aceptar la transferencia',
    'Destination account must accept the transfer',
    'Das Zielkonto muss die Überweisung akzeptieren'
  ],

  acceptTransfer: ['ACEPTAR', 'ACCEPT', 'AKZEPT'],
  refuseTransfer: ['DENEGAR', 'REFUSE', 'VERWEIGERN'],

  help: ['Ayuda', 'Help', 'Hilfe'],
  user: ['Mi cuenta', 'My account', 'Mein Konto'],
  thisUser: ['(Este usuario)', '(This user)', '(Dieser Benutzer)'],
  language: ['Idioma', 'Language', 'Sprache'],
  ES: ['Español', 'Spanish', 'Spanisch'],
  EN: ['Inglés', 'English', 'Englisch'],
  faqs: ['Preguntas frecuentes', 'FAQs', 'Häufig gestellte Fragen'],

  profile: ['Perfil', 'Profile', 'Profil'],
  editProfile: ['Editar perfil', 'Edit profile', 'Profil bearbeiten'],
  dataVis: ['Visualización de datos', 'Data visualization ', 'Datenvisualisierung '],
  visualization: ['Visualización', 'Visualization', 'Visualisierung'],
  restorePassword: ['Restablecer contraseña', 'Restore password', 'Passwort wiederherstellen'],
  uploadLogo: ['Subir o cargar logotipo', 'Upload logo', 'Logo hochladen'],
  deleteLogo: ['Eliminar logotipo', 'Delete logo', 'Logo löschen'],
  deleteAccount: ['Eliminar cuenta', 'Delete account', 'Konto löschen'],
  deleteAccountConfirmationText: [
    'Vas a eliminar esta cuenta de My inBiot, todos los dispositivos dejarán de estar asociados a esta cuenta y los datos recolectados se perderán. ¿Estás seguro?',
    'If you delete this account from My inBiot, all devices will no longer be associated with this account and the collected data will be lost. Are you sure?',
    'Wenn Sie dieses Konto aus Mein inBiot löschen, werden alle Geräte nicht mehr mit diesem Konto verbunden und die gesammelten Daten gehen verloren. Sind Sie sicher?'
  ],
  deleteUserAccessConfirmationText: [
    'Vas a eliminar el acceso de este usuario de tus dispositivos de My inBiot. ¿Estás seguro?',
    "You are going to remove this user's access from your My inBiot devices. Are you sure?",
    'Sie werden den Zugang dieses Benutzers zu Ihren My inBiot-Geräten entfernen. Sind Sie sicher?'
  ],
  deleteCollaborativeAccessConfirmationText: [
    'Vas a borrar tu acceso a los dispositivos de esta cuenta. ¿Estás seguro?',
    "You are going to delete your access to the selected account's devices. Are you sure?",
    'Sie werden Ihren Zugriff auf die Geräte des ausgewählten Kontos löschen. Sind Sie sicher?'
  ],
  logout: ['Cerrar sesión', 'Logout', 'Abmelden'],
  close: ['Cerrar', 'Close', 'schließen'],

  name: ['Nombre', 'Name', 'Name'],
  systemName: ['Nombra el dispositivo', 'Name your device', 'Benennen Sie Ihr Gerät'],
  systemDescription: ['Descripción del dispositivo', 'Device description', 'Gerätebeschreibung'],
  sensorId: ['Id de sensor', 'Sensor id', 'Sensor id'],
  description: ['Descripción', 'Description', 'Beschreibung'],
  zipCode: ['Código postal', 'Zip code', 'Postleitzahl'],

  micaMiniThermalInfoText: ['Confort Térmico', 'Thermal Comfort', 'Thermischer Komfort'],
  micaMiniInfoText: ['Confort Térmico', 'Thermal Comfort', 'Thermischer Komfort'],
  micaDeskInfoText: ['Indicador IAQ', 'IAQ indicator', 'IAQ Indikator'],
  micaDeskInfoTextTitle: [
    'Indicador de Calidad del Aire Interior',
    'Indoor Air Quality Indicator',
    'Luftqualität in Innenräumen Luftqualität in Innenräumen'
  ],
  micaLiteInfoText: ['Resistencia a Virus', 'Resistance to Virus', 'Virusresistenz'],
  micaLiteInfoTextTitle: [
    'Indicador de Resistencia a la Propagación de Virus',
    'Resistance to Virus Spread Indicator',
    'Indikator für Resistenz gegen Virusausbreitung Resistenzindikator'
  ],
  micaInfoText: ['Calidad de Aire Interior', 'Indoor Air Quality', 'Luftqualität in Innenräumen'],
  micaMiniInfoTextTitle: [
    'Indicador del Confort Termohigrométrico',
    'Thermohygrometric Comfort Indicator',
    'Thermo-hygrometrischer Komfort-Indikator'
  ],
  micaWellInfoText: [
    'Calidad de Aire Interior',
    'Indoor Air Quality',
    'Luftqualität in Innenräumen'
  ],
  micaLiteInfo: [
    'El Indicador Virus, basado en el RESET VIRAL INDEX, muestra en una escala 0-100 la resistencia que ofrece el aire de un espacio a la propagación de virus. </br></br>' +
      'Nos ayuda a optimizar los sistemas de un edificio para minimizar el potencial de infección vírica por vía aérea.',

    'The Virus Indicator, based on the RESET VIRAL INDEX, shows on a scale of 0-100 the resistance of the air in a space to the spread of viruses. </br></br>' +
      "It helps us to optimize a building's systems to minimize the potential for airborne viral infection.",

    'Der Virus-Indikator, der auf dem RESET VIRAL INDEX basiert, zeigt auf einer Skala von 0-100 an, wie resistent die Luft in einem Raum gegenüber der Verbreitung von Viren ist. </br></br>' +
      'Er hilft uns, die Systeme eines Gebäudes so zu optimieren, dass das Potenzial für Virusinfektionen über die Luft minimiert wird.'
  ],
  micaInfo: [
    'Basado en el RESET AIR INDEX, permite comunicar de manera sencilla, en una escala 0-100, la calidad del aire interior de un espacio. </br></br>' +
      'Está basado en los parámetros CO₂, TVOC y PM2.5.',

    'Based on the RESET AIR INDEX, it allows to communicate in a simple way, on a 0-100 scale, the indoor air quality of a space. </br></br>' +
      'It is based on the parameters CO₂, TVOC and PM2.5.',

    'Basierend auf dem RESET AIR INDEX ermöglicht es auf einfache Weise, auf einer Skala von 0-100, die Innenraumluftqualität eines Raumes zu kommunizieren. </br></br>' +
      'Er basiert auf den Parametern CO₂, TVOC und PM2.5.'
  ],
  micaInfoReduced: [
    'Basado en el RESET AIR INDEX, permite comunicar de manera sencilla, en una escala 0-100, la calidad del aire interior de un espacio. </br></br>' +
      'Está basado en los parámetros CO₂, TVOC y PM2.5.',

    'Based on the RESET AIR INDEX, it allows to communicate in a simple way, on a 0-100 scale, the indoor air quality of a space. </br></br>' +
      'It is based on the parameters CO₂, TVOC and PM2.5.',

    'Basierend auf dem RESET AIR INDEX ermöglicht es auf einfache Weise, auf einer Skala von 0-100, die Innenraumluftqualität eines Raumes zu kommunizieren. </br></br>' +
      'Er basiert auf den Parametern CO₂, TVOC und PM2.5.'
  ],
  micaWellInfo: [
    'Basado en el RESET AIR INDEX, permite comunicar de manera sencilla, en una escala 0-100, la calidad del aire interior de un espacio. </br></br>' +
      'Está basado en los parámetros CO₂, TVOC y PM2.5.',

    'Based on the RESET AIR INDEX, it allows to communicate in a simple way, on a 0-100 scale, the indoor air quality of a space. </br></br>' +
      'It is based on the parameters CO₂, TVOC and PM2.5.',

    'Basierend auf dem RESET AIR INDEX ermöglicht es auf einfache Weise, auf einer Skala von 0-100, die Innenraumluftqualität eines Raumes zu kommunizieren. </br></br>' +
      'Er basiert auf den Parametern CO₂, TVOC und PM2.5.'
  ],
  micaDeskInfo: [
    'Basado en el RESET AIR INDEX, permite comunicar de manera sencilla, en una escala 0-100, la calidad del aire interior de un espacio. </br></br>' +
      'Está basado en los parámetros CO₂, TVOC y PM2.5.',

    'Based on the RESET AIR INDEX, it allows to communicate in a simple way, on a 0-100 scale, the indoor air quality of a space. </br></br>' +
      'It is based on the parameters CO₂, TVOC and PM2.5.',

    'Basierend auf dem RESET AIR INDEX ermöglicht es auf einfache Weise, auf einer Skala von 0-100, die Innenraumluftqualität eines Raumes zu kommunizieren. </br></br>' +
      'Er basiert auf den Parametern CO₂, TVOC und PM2.5.'
  ],
  ventilationInfoText: [
    'Eficacia de Ventilación',
    'Ventilation Efficiency',
    'Effizienz der Belüftung'
  ],
  ventilationInfoTextTitle: [
    'Indicador de Eficacia de Ventilación',
    'Ventilation Efficiency Indicator',
    'Indikator für den Lüftungswirkungsgrad'
  ],
  ventilationInfo: [
    'Mide en tiempo real la eficacia de la ventilación en un espacio interior, representada en una escala 0-100 y basada en la concentración de CO₂ y de TVOC. ',
    'It measures in real time the ventilation efficiency in an indoor space, represented on a 0-100 scale and based on CO₂ and TVOC concentration.',
    'Es misst in Echtzeit die Lüftungseffizienz eines Innenraums, dargestellt auf einer Skala von 0-100 und basierend auf der CO₂- und TVOC-Konzentration.'
  ],
  ventilationInfoVocs: [
    'Mide en tiempo real la eficacia de la ventilación en un espacio interior, representada en una escala 0-100 y basada en la concentración de CO₂. ',
    'It measures in real time the ventilation efficiency in an indoor space, represented on a 0-100 scale and based on CO₂ concentration.',
    'Es misst in Echtzeit die Lüftungseffizienz eines Innenraums, dargestellt auf einer Skala von 0-100 und basierend auf der CO₂-Konzentration.'
  ],
  micaMiniInfo: [
    'Permite identificar de manera sencilla el rango de temperatura y humedad relativa que representa el bienestar térmico.   </br></br>' +
      'Nos ayuda a objetivizar las condiciones de temperatura y humedad de un espacio interior.',

    'It allows to identify in a simple way the range of temperature and relative humidity that represents the thermal comfort. <br/> <br/>' +
      'It helps us to objectify the temperature and humidity conditions of an interior space.',

    'Sie ermöglicht es uns, den Bereich der Temperatur und der relativen Luftfeuchtigkeit, der für die thermische Behaglichkeit ausschlaggebend ist, leicht zu bestimmen. <br/> <br/>' +
      'Sie hilft uns, die Temperatur- und Feuchtigkeitsbedingungen in einem Innenraum zu objektivieren.'
  ],
  iaqIndicatorText: [
    'Calidad Interior del Aire',
    'Indoor Air Quality',
    'Qualität der Innenraumluft'
  ],
  moreInfo: ['Ampliar Información', 'More information', 'Weitere Informationen'],
  learnMore: ['SABER MÁS', 'LEARN MORE', 'MEHR LERNEN'],
  veryGoodIndoorAirQuality: [
    'Calidad de Aire Interior muy buena',
    'Very good Indoor Air Quality',
    'Sehr gute Luftqualität in Innenräumen'
  ],
  veryGoodIndoorAirQualityTip: [
    'Aire limpio e higiénico. No se requiere acción.',
    'Clean and hygienic air. No action required.',
    'Saubere und hygienische Luft. Keine Maßnahmen erforderlich.'
  ],
  goodIndoorAirQuality: [
    'Calidad de Aire Interior buena',
    'Good Indoor Air Quality',
    'Gute Luftqualität in Innenräumen'
  ],
  goodIndoorAirQualityTip: [
    'Buena calidad del aire sin superar umbrales de riesgo.',
    'Good air quality without exceeding risk thresholds.',
    'Gute Luftqualität ohne Überschreitung der Risikogrenzen.'
  ],
  mediumIndoorAirQuality: [
    'Calidad de Aire Interior media',
    'Medium Indoor Air Quality',
    'Mittlere Luftqualität in Innenräumen'
  ],
  mediumIndoorAirQualityTip: [
    'Calidad del aire interior no recomendada para grupos de riesgo. Sensación de disconfort en exposiciones de larga duración. Mejora la ventilación y reduce fuentes de contaminación.',
    'Indoor air quality not recommended for risk groups. Feeling of discomfort in long-term exposures Improve ventilation and reduce sources of contamination.',
    'Raumluftqualität für Risikogruppen nicht empfohlen. Gefühl des Unbehagens bei Langzeitexposition Verbesserung der Belüftung und Verringerung der Verschmutzungsquellen.'
  ],
  poorIndoorAirQuality: [
    'Calidad de Aire Interior baja',
    'Poor Indoor Air Quality',
    'Schlechte Luftqualität in Innenräumen'
  ],
  poorIndoorAirQualityTip: [
    'Calidad del aire no recomendada para exposiciones continuas. Mejora e incrementa la ventilación y reduce las fuentes de contaminación.',
    'Air quality not recommended for continuous exposures. Improve and increase ventilation and reduce sources of indoor pollutants.',
    'Die Luftqualität wird für eine kontinuierliche Exposition nicht empfohlen. Verbessern und erhöhen Sie Belüftung und Verringerung der Quellen von Schadstoffen in Innenräumen.'
  ],
  veryPoorIndoorAirQuality: [
    'Calidad de Aire Interior muy baja',
    'Very poor Indoor Air Quality',
    'Sehr schlechte Luftqualität in Innenräumen'
  ],
  veryPoorIndoorAirQualityTip: [
    'Condiciones de calidad del aire interior no adecuadas. Se necesita ventilar y mejorar las condiciones de calidad del aire interior de forma urgente.',
    'Ventilation and improvement of indoor air quality conditions is urgently needed.',
    'Die Belüftung und Verbesserung der Luftqualität in Innenräumen ist dringend erforderlich.'
  ],
  highSpreadProbability: [
    'Probabilidad de propagación alta.',
    'High probability of spread',
    'Hohe Wahrscheinlichkeit der Ausbreitung'
  ],
  highSpreadProbabilityTip: [
    'Es urgente revisar el nivel de ventilación y el aforo del espacio, ajustar las condiciones de confort termohigrométrico y reducir la presencia de partículas en suspensión.',
    'It is urgent to review the ventilation level and the space occupancy, adjust the thermo-hygrometric comfort conditions and reduce the presence of Particulate Matter.',
    'Es ist dringend erforderlich, die Belüftungsstufe und die Raumbelegung zu überprüfen, die thermohygrometrischen Komfortbedingungen anzupassen und das Vorhandensein von Feinstaub zu reduzieren.'
  ],
  mediumSpreadProbability: [
    'Probabilidad de propagación media',
    'Medium probability of spread',
    'Mittlere Wahrscheinlichkeit der Ausbreitung'
  ],
  mediumSpreadProbabilityTip: [
    'Se recomienda revisar las condiciones específicas de ventilación, confort termohigrométrico y propagación de partículas para reducir el riesgo de propagación de virus.',
    'It is recommended to review ventilation rate, thermo-hygrometric comfort and PM spread to reduce the risk of virus spread.',
    'Es wird empfohlen, die Beatmungsrate, den thermohygrometrischen Komfort und die PM-Ausbreitung zu überprüfen, um das Risiko einer Virusausbreitung zu verringern.'
  ],
  lowSpreadProbability: [
    'Probabilidad de propagación baja',
    'Low probability of spread',
    'Geringe Wahrscheinlichkeit der Ausbreitung'
  ],
  lowSpreadProbabilityTip: [
    'No se requiere acción específica',
    'No specific action is required.',
    'Es sind keine besonderen Maßnahmen erforderlich.'
  ],
  lowVentilationNeeds: [
    'No se requiere ventilar',
    'No ventilation required',
    'Keine Belüftung erforderlich'
  ],
  mediumVentilationNeeds: [
    'Se recomienda ventilar',
    'Ventilation recommended',
    'Belüftung empfohlen'
  ],
  highVentilationNeeds: [
    'Es necesario ventilar',
    'Ventilation is required',
    'Belüftung ist erforderlich'
  ],
  sensorErrorMessage: [
    'No se están recibiendo datos de este sensor. Encuentra soluciones o contáctanos.',
    'No data is being received from this sensor. Find solutions or contact us.',
    'Es werden keine Daten von diesem Sensor empfangen. Finden Sie Lösungen oder kontaktieren Sie uns.'
  ],
  sensorPreheatingMessage: [
    'El sensor se encuentra en fase de pre-calentamiento. Espere unas horas para recibir medidas.',
    'The sensor is in the pre-heating phase. Wait a few hours to receive measurements.',
    'Der Sensor befindet sich in der Vorwärmphase. Warten Sie ein paar Stunden, um Messungen zu erhalten.'
  ],
  supportButton: ['Ayuda', 'Help', 'Hilfe'],
  optimalTemperatureTip: [
    '¡Muy bien! El rango de temperatura para garantizar el confort térmico es adecuado!',
    'Very good! The temperature range to ensure thermal comfort is adequate!',
    'Sehr gut! Der Temperaturbereich zur Gewährleistung des thermischen Komforts ist ausreichend!'
  ],
  regularTemperatureTip: [
    'Trata de regular la temperatura del termostato para garantizar un confort térmico más adecuado.',
    'Try to regulate the thermostat temperature to ensure a more adequate thermal comfort.',
    'Versuchen Sie, die Temperatur des Thermostats zu regulieren, um einen angemessenen Wärmekomfort zu gewährleisten.'
  ],
  lowTemperatureTip: [
    'Ajusta la temperatura del termostato hasta valores en torno a los 20-21ºC.',
    'Adjust the thermostat temperature to values around 20-21ºC.',
    'Stellen Sie die Temperatur des Thermostats auf Werte um 20-21°C ein.'
  ],
  lowKelvinTemperature: [
    'Ajusta la temperatura del termostato hasta valores en torno a los 293-294ºk.',
    'Adjust the thermostat temperature to values around 293-294ºk.',
    'Stellen Sie die Temperatur des Thermostats auf Werte um 293-294ºk.'
  ],
  highKelvinTemperature: [
    'Ajusta la temperatura del termostato hasta valores en torno a los 293 - 294ºk.',
    'Adjust the thermostat temperature to values around 293-294ºk.',
    'Stellen Sie die Temperatur des Thermostats auf Werte um 293-294ºk.'
  ],
  lowTemperatureTipImperial: [
    'Ajusta la temperatura del termostato hasta valores en torno a los 68-69ºF.',
    'Adjust the thermostat temperature to values around 68-69ºF.',
    'Stellen Sie die Temperatur des Thermostats auf Werte um 68-69ºF.'
  ],
  highTemperatureTip: [
    'Ajusta la temperatura del termostato hasta valores en torno a los 20-21ºC',
    'Adjust the thermostat temperature to values around 20-21ºC.',
    'Stellen Sie die Temperatur des Thermostats auf Werte um 20-21°C ein.'
  ],
  highTemperatureTipImperial: [
    'Ajusta la temperatura del termostato hasta valores en torno a los 68-69ºF',
    'Adjust the thermostat temperature to values around 68-69ºF.',
    'Stellen Sie die Temperatur des Thermostats auf Werte um 68-69ºF.'
  ],
  optimalHumidityTip: [
    '¡Muy bien! Rango de humedad relativa adecuado para un ambiente interior saludable.',
    'Very good! Relative humidity range suitable for a healthy indoor environment.',
    'Sehr gut! Der Bereich der relativen Luftfeuchtigkeit ist für ein gesundes Raumklima geeignet.'
  ],
  regularHumidityTip: [
    'Trata de ajustar la humedad hasta alcanzar niveles de humedad relativa  en torno al 50%.',
    'Try to adjust the humidity to reach relative humidity levels of around 50%.',
    'Versuchen Sie, die Luftfeuchtigkeit so einzustellen, dass die relative Luftfeuchtigkeit etwa 50 % beträgt.'
  ],
  badHumidityTip: [
    'Trata de ajustar la humedad hasta alcanzar niveles de humedad relativa entre el 35% y el 60%.',
    'Try to adjust the humidity to reach relative humidity levels between 35% and 60%.',
    'Versuchen Sie, die Luftfeuchtigkeit so einzustellen, dass die relative Luftfeuchtigkeit zwischen 35 % und 60 % liegt.'
  ],
  optimalCo2Tip: [
    '¡Excelente! Nivel de CO₂ óptimo para un ambiente interior saludable. Continúa garantizando adecuada renovación de aire para seguir por debajo de 800 ppm.',
    'Excellent! Optimal CO₂ level for a healthy indoor environment. Continues to ensure adequate air renewal to remain below 800 ppm.',
    'Ausgezeichnet! Optimaler CO₂-Wert für ein gesundes Raumklima. Sorgt weiterhin für eine angemessene Lufterneuerung, um unter 800 ppm zu bleiben.'
  ],
  regularCo2Tip: [
    'Aumenta la tasa de ventilación del espacio.',
    'Increase the ventilation rate of the space.',
    'Erhöhen Sie die Belüftungsrate des Raums.'
  ],
  badCo2Tip: [
    'Incrementa la tasa de renovación de aire y ajusta el sistema de ventilación a las necesidades reales.',
    'Increase the air renewal rate and adjust the Ventilation System system to real needs.',
    'Erhöhen Sie die Lufterneuerungsrate und passen Sie das Belüftungssystem an den tatsächlichen Bedarf an.'
  ],
  optimalVocsTip: [
    '¡Muy bien! Continúa garantizando niveles bajos de TVOC de forma continua.',
    'Very good! Continues to ensure low levels of TVOC on a continuous basis.',
    'Sehr gut! Sorgt weiterhin kontinuierlich für niedrige TVOC-Werte.'
  ],
  regularVocsTip: [
    'Ajusta los niveles de ventilación para reducir la concentración de TVOC. Asegura una ventilación efectiva al utilizar productos de limpieza, pinturas o disolventes.',
    'Adjust ventilation levels to reduce TVOC concentration. Ensure effective ventilation when using cleaners, paints or solvents.',
    'Passen Sie die Belüftungsstufen an, um die TVOC-Konzentration zu verringern. Sorgen Sie für gute Belüftung, wenn Sie Reinigungsmittel, Farben oder Lösungsmittel verwenden.'
  ],
  badVocsTip: [
    'Implanta un sistema de ventilación eficaz, un sistema de purificación de aire con tecnología específica para adsorción de TVOC y utiliza productos sustitutivos de bajas emisiones. Consulta con profesional especializado si se mantienen niveles altos de forma continua.',
    'Implement an effective ventilation system, an air purification system with specific TVOC adsorption technology and use low-emission substitutes. Consult a specialised professional if high levels are maintained on a continuous basis.',
    'Führen Sie ein wirksames Belüftungssystem und ein Luftreinigungssystem mit spezieller TVOC-Adsorptionstechnologie ein und verwenden Sie emissionsarme Ersatzstoffe. Wenden Sie sich an einen Fachmann, wenn ständig hohe Werte auftreten.'
  ],
  optimalPm25Tip: [
    '¡Muy bien! Sigue así y mantén los niveles de partículas PM2.5 siempre por debajo de 12 µg/m³.',
    'Good job! Keep it up and keep PM2.5 particulate levels always below 12 µg/m³.',
    'Gute Arbeit! Machen Sie weiter so und halten Sie die PM2,5-Feinstaubwerte stets unter 12 µg/m³.'
  ],
  regularPm25Tip: [
    'Aumenta la tasa de ventilación y mejora el filtrado de partículas en suspensión.',
    'Increase ventilation rate and improve the PM (Particulate Matter) filter.',
    'Erhöhen Sie die Belüftungsrate und verbessern Sie den PM-Filter (Feinstaub).'
  ],
  badPm25Tip: [
    'Implementa sistemas de filtrado con filtros de alta eficiencia o purificadores de aire que filtren partículas finas (hasta 0,3 micras).',
    'Implement filtration systems with high-efficiency filters or air purifiers that filter fine particles (up to 0.3 microns).',
    'Verwenden Sie Filtersysteme mit hocheffizienten Filtern oder Luftreiniger, die feine Partikel (bis zu 0,3 Mikrometer) filtern.'
  ],
  optimalPm1Tip: [
    '¡Muy bien! Sigue así y mantén los niveles de partículas PM1.0 siempre por debajo de 12 µg/m³.',
    'Good job! Keep it up and keep PM1.0 particulate levels always below 12 µg/m³.',
    'Gute Arbeit! Machen Sie weiter so und halten Sie die PM1.0-Feinstaubwerte stets unter 12 µg/m³.'
  ],
  regularPm1Tip: [
    'Aumenta la tasa de ventilación y mejora el filtrado de partículas en suspensión.',
    'Increase ventilation rate and improve the PM (Particulate Matter) filter.',
    'Erhöhen Sie die Belüftungsrate und verbessern Sie den PM-Filter (Feinstaub).'
  ],
  badPm1Tip: [
    'Implementa sistemas de filtrado con filtros de alta eficiencia o purificadores de aire que filtren partículas finas (hasta 0,3 micras).',
    'Implement filtration systems with high-efficiency filters or air purifiers that filter fine particles (up to 0.3 microns).',
    'Verwenden Sie Filtersysteme mit hocheffizienten Filtern oder Luftreiniger, die feine Partikel (bis zu 0,3 Mikrometer) filtern.'
  ],
  optimalPm4Tip: [
    '¡Muy bien! Sigue así y mantén los niveles de partículas PM4.0 siempre por debajo de 12 µg/m³.',
    'Good job! Keep it up and keep PM4.0 particulate levels always below 12 µg/m³.',
    'Gute Arbeit! Machen Sie weiter so und halten Sie die PM4.0-Feinstaubwerte stets unter 12 µg/m³.'
  ],
  regularPm4Tip: [
    'Aumenta la tasa de ventilación y mejora el filtrado de partículas en suspensión.',
    'Increase ventilation rate and improve the PM (Particulate Matter) filter.',
    'Erhöhen Sie die Belüftungsrate und verbessern Sie den PM-Filter (Feinstaub).'
  ],
  badPm4Tip: [
    'Implementa sistemas de filtrado con filtros de alta eficiencia o purificadores de aire que filtren partículas finas (hasta 0,3 micras).',
    'Implement filtration systems with high-efficiency filters or air purifiers that filter fine particles (up to 0.3 microns).',
    'Verwenden Sie Filtersysteme mit hocheffizienten Filtern oder Luftreiniger, die feine Partikel (bis zu 0,3 Mikrometer) filtern.'
  ],
  optimalPm10Tip: [
    '¡Muy bien! Sigue así y mantén los niveles de partículas PM10 siempre por debajo de 50 µg/m³.',
    'Good job! Keep it up and keep PM10 particulate levels always below 50 µg/m³.',
    'Gute Arbeit! Machen Sie weiter so und halten Sie die PM10-Feinstaubwerte stets unter 50 µg/m³.'
  ],
  regularPm10Tip: [
    'Aumenta la tasa de ventilación y mejora el filtrado de partículas en suspensión.',
    'Increase ventilation rate and improve the PM (Particulate Matter) filter.',
    'Erhöhen Sie die Belüftungsrate und verbessern Sie den PM-Filter (Feinstaub).'
  ],
  badPm10Tip: [
    'Implementa sistemas de filtrado con filtros de alta eficiencia o purificadores de aire que filtren partículas finas (hasta 0,3 micras).',
    'Implement filtration systems with high-efficiency filters or air purifiers that filter fine particles (up to 0.3 microns).',
    'Verwenden Sie Filtersysteme mit hocheffizienten Filtern oder Luftreiniger, die feine Partikel (bis zu 0,3 Mikrometer) filtern.'
  ],
  optimalFormaldehydeTip: [
    'Enhorabuena, el nivel de formaldehído es bajo, adecuado para un ambiente interior saludable.',
    'Congratulations, the formaldehyde level is low, suitable for a healthy indoor environment.',
    'Herzlichen Glückwunsch, der Formaldehydgehalt ist niedrig und eignet sich für ein gesundes Raumklima.'
  ],
  regularFormaldehydeTip: [
    'Revisa defectos en los materiales de acabado o equipamiento y utiliza productos sellantes para formaldehído. Asegura buenos niveles de ventilación al utilizar productos de limpieza, pinturas o disolventes.',
    'Check for defects in finishing materials or equipment and use formaldehyde sealants. Ensure good ventilation levels when using cleaning products, paints or solvents.',
    'Prüfen Sie auf Mängel an den Endbearbeitungsmaterialien oder -geräten und verwenden Sie Formaldehyd-Dichtungsmittel. Sorgen Sie für gute Belüftung, wenn Sie Reinigungsmittel, Farben oder Lösungsmittel verwenden.'
  ],
  badFormaldehydeTip: [
    'Revisa defectos en los materiales de acabado o equipamiento, utiliza productos sellantes para formaldehído o selecciona productos sustitutivos para las fuentes de emisión detectadas. Consulta con profesional especializado si estos niveles se mantienen de forma continua.',
    'Check for defects in finishing materials or equipment, use formaldehyde sealants or select substitute products for detected emission sources. Consult a professional if these levels are maintained on a continuous basis.',
    'Prüfen Sie auf Defekte an den Ausbaumaterialien oder Geräten, verwenden Sie Formaldehyd-Dichtstoffe oder wählen Sie Ersatzprodukte für festgestellte Emissionsquellen. Wenden Sie sich an einen Fachmann, wenn diese Werte dauerhaft aufrechterhalten werden.'
  ],
  optimalO3Tip: [
    '¡Muy bien! Continúa garantizando niveles bajos de O₃ de forma continua.',
    'Very good! Continues to ensure continuously low O₃ levels.',
    'Sehr gut! Sorgt weiterhin für kontinuierlich niedrige O₃-Werte.'
  ],
  regularO3Tip: [
    'Aumenta la tasa de ventilación y revisa posibles fuentes de emisión de O₃ en equipos de ofimática, purificadores de aire, o equipos con lámparas UV.',
    'Increase the ventilation rate and check for possible sources of O₃ emissions from office equipment, air purifiers, or equipment with UV lamps.',
    'Erhöhen Sie die Belüftungsrate und überprüfen Sie mögliche Quellen von O₃-Emissionen aus Bürogeräten, Luftreinigern oder Geräten mit UV-Lampen.'
  ],
  badO3Tip: [
    'Incrementa la tasa de renovación de aire y elimina cualquier fuente de emisión de O₃ en equipos de ofimática (impresoras), purificadores de aire o equipos con lámparas UV. Consulta con un profesional especializado si estos niveles se mantienen de forma continua.',
    'Increase the air renewal rate and eliminate any source of O₃ emissions in office equipment (printers), air purifiers or equipment with UV lamps. Consult a specialised professional if these levels are maintained on a continuous basis.',
    'Erhöhen Sie die Lufterneuerungsrate und beseitigen Sie alle Quellen von O₃-Emissionen in Bürogeräten (Drucker), Luftreinigern oder Geräten mit UV-Lampen. Wenden Sie sich an einen Fachmann, wenn diese Werte ständig aufrechterhalten werden.'
  ],
  optimalCoTip: [
    '¡Muy bien! Continúa garantizando niveles bajos de CO de forma continua.',
    'Very good! Continues to ensure low CO levels on a continuous basis.',
    'Sehr gut! Sorgt weiterhin kontinuierlich für niedrige CO-Werte.'
  ],
  regularCoTip: [
    'Aumenta la tasa de ventilación, localiza cualquier equipo de combustión (estufas, hornos, barbacoas, etc) que no esté funcionando correctamente y evita el humo del tabaco',
    'Increase ventilation, locate any combustion appliances (cookers, ovens, barbecues, etc.) that are not working properly and avoid tobacco smoke.',
    'Erhöhen Sie die Belüftungsrate, suchen Sie nach Verbrennungsgeräten (Herde, Öfen, Grills usw.), die nicht ordnungsgemäß funktionieren, und vermeiden Sie Tabakrauch.'
  ],
  badCoTip: [
    'Apaga cualquier equipo de combustión y sal al aire libre y/o a un lugar no contaminado, hasta que los niveles de CO<9ppm (10 mg/m3).',
    'Shut down all combustion equipment and move to fresh air and/or an uncontaminated area until CO levels are <9ppm (10 mg/m3).',
    'Schalten Sie alle Verbrennungsanlagen aus und begeben Sie sich an die frische Luft und/oder in einen nicht kontaminierten Bereich, bis die CO-Werte unter 9ppm (10 mg/m3) liegen.'
  ],
  optimalNo2Tip: [
    '¡Excelente, sigue así! Mantén los niveles de NO₂ por debajo de 21 ppb (40 ug/m3).',
    'Excellent, keep it up! Keep NO₂ levels below 21 ppb (40 ug/m3).',
    'Ausgezeichnet, weiter so! Halten Sie die NO₂-Werte unter 21 ppb (40 ug/m3).'
  ],
  regularNo2Tip: [
    'Aumenta la tasa de ventilación, localiza cualquier equipo de combustión (estufas, hornos, barbacoas, etc) que no esté funcionando correctamente y evita el humo del tabaco',
    'Increase ventilation, locate any combustion appliances (cookers, ovens, barbecues, etc.) that are not working properly and avoid tobacco smoke.',
    'Erhöhen Sie die Belüftungsrate, suchen Sie nach Verbrennungsgeräten (Herde, Öfen, Grills usw.), die nicht ordnungsgemäß funktionieren, und vermeiden Sie Tabakrauch.'
  ],
  badNo2Tip: [
    'Apaga cualquier equipo de combustión y sal al aire libre y/o a un lugar no contaminado, hasta que los niveles de CO<9ppm (10 mg/m3).',
    'Shut down all combustion equipment and move to fresh air and/or an uncontaminated area until CO levels are <9ppm (10 mg/m3).',
    'Schalten Sie alle Verbrennungsanlagen aus und begeben Sie sich an die frische Luft und/oder in einen nicht kontaminierten Bereich, bis die CO-Werte unter 9ppm (10 mg/m3) liegen.'
  ],
  optimalNoxTip: [
    '¡Muy bien! Continúa garantizando niveles bajos de NOx de forma continua.',
    'Very good! Continues to ensure low NOx levels on a continuous basis.',
    'Sehr gut! Sorgt weiterhin kontinuierlich für niedrige NOx-Werte.'
  ],
  regularNoxTip: [
    'Ajusta los niveles de ventilación para reducir la concentración de NOx. Localiza cualquier equipo de combustión (estufas, hornos, barbacoas, etc) que no esté funcionando correctamente y evita el humo del tabaco y del tráfico.',
    'Adjust ventilation levels to reduce NOx concentrations. Locate any combustion appliances (cookers, ovens, barbecues, etc.) that are not working properly and avoid tobacco and traffic smoke.',
    'Passen Sie die Belüftungsstufen an, um die NOx-Konzentration zu verringern. Suchen Sie alle Verbrennungsgeräte (Herde, Öfen, Backöfen, Grills usw.), die nicht ordnungsgemäß funktionieren, und vermeiden Sie Tabak- und Verkehrsrauch.'
  ],
  badNoxTip: [
    'Apaga cualquier equipo de combustión, aumenta la tasa de renovación de aire y elimina cualquier posible fuente hasta que los niveles de NOx bajen de 150.',
    'Shut down all combustion equipment, increase air renewal rates and eliminate all possible sources until NOx levels drop below 150.',
    'Schalten Sie alle Verbrennungsanlagen aus, erhöhen Sie die Lufterneuerungsrate und beseitigen Sie alle möglichen Quellen, bis die NOx-Werte unter 150 fallen.'
  ],
  optimalNoiseTip: [
    '¡Excelente, sigue así! Continúa garantizando niveles de ruido aéreo por debajo de 30dB.',
    'Excellent, keep it up! Continue to maintain airborne noise levels below 30dB.',
    'Ausgezeichnet, weiter so! Sorgen Sie weiterhin für Luftschallpegel unter 30 dB.'
  ],
  regularNoiseTip: [
    'Revise las fuentes de contaminación por ruido aéreo (tráfico, industria, falta de aislamiento acústico) y busque estrategias para reducir la exposición al ruido.',
    'Review sources of airborne noise pollution (traffic, industry, lack of soundproofing) and look for strategies to reduce noise exposure.',
    'Überprüfen Sie die Quellen der Luftschallbelastung (Verkehr, Industrie, fehlende Schalldämmung) und suchen Sie nach Strategien zur Verringerung der Lärmbelastung.'
  ],
  badNoiseTip: [
    'Garantiza un aislamiento acústico adecuado en este espacio interior - ajusta la carpintería para evitar el efecto del ruido exterior. Consulta con un profesional especializado si estos niveles se mantienen de forma continua.',
    'Ensure adequate soundproofing in this interior space - adjust joinery to avoid the effect of outside noise. Consult a specialist if these levels are to be maintained on a continuous basis. ',
    'Sorgen Sie für eine ausreichende Schalldämmung in diesem Innenraum - passen Sie die Tischlerarbeiten an, um die Auswirkungen von Außengeräuschen zu vermeiden. Ziehen Sie einen Fachmann zu Rate, wenn die Lärmbelastung über einen längeren Zeitraum anhält.'
  ],
  optimalLightTip: [
    '¡Muy bien! Un ambiente interior lumínicamente saludable requiere de una iluminancia de entre 100 y 500 luxes para puestos de trabajo con exigencias visuales bajas,moderadas y altas.',
    'Very good! A healthy indoor lighting environment requires illuminance between 100 and 500 lux for workplaces with low, medium and high visual demands.',
    'Sehr gut! Eine gesunde Innenraumbeleuchtung erfordert Beleuchtungsstärken zwischen 100 und 500 Lux für Arbeitsplätze mit geringen, mittleren und hohen Sehanforderungen.'
  ],
  regularLightTip: [
    'Ajusta la intensidad luminosa para evitar  fatiga visual y mental, molestias oculares o deslumbramientos.',
    'Adjust light intensity to prevent visual and mental fatigue, eye discomfort or glare.',
    'Anpassung der Lichtintensität, um visuelle und mentale Ermüdung, Augenbeschwerden oder Blendung zu vermeiden.'
  ],
  badLightTip: [
    'Ajuste los niveles de iluminación para evitar exceso o defecto de iluminancia.',
    'Adjust the lighting levels to avoid over- or under-illumination.',
    'Die Beleuchtungsstärke so einstellen, dass eine Über- oder Unterbelichtung vermieden wird.'
  ],
  noDataMeasured: ['No se han medido datos', 'No data measured', 'Keine Daten gemessen'],
  forgotPassword: ['¿Has olvidado la contraseña?', 'Forgot password?', 'Passwort vergessen?'],
  resendCode: [
    'Reenviar correo de confirmación',
    'Resend confirmation email',
    'Bestätigungsmail erneut senden'
  ],
  loading: ['Cargando', 'Loading', 'Wird geladen'],
  min: ['Mín', 'Min', 'Min'],
  max: ['Máx', 'Max', 'Max'],
  mean: ['Media', 'Mean', 'Bedeuten'],

  startDate: ['Fecha de inicio', 'Start date', 'Startdatum'],
  endDate: ['Fecha final', 'End date', 'Endtermin'],
  download: ['Descargar datos', 'Download data', 'Daten herunterladen'],
  downloadData: ['Descargar datos', 'Download data', 'Daten herunterladen'],
  downloadMultipleData: [
    'Descarga de datos múltiple',
    'Multiple data download',
    'Download mehrerer Daten'
  ],
  createAlarm: ['Crear alerta', 'Create alert', 'Einen Alarm erstellen'],
  next: ['Siguiente', 'Next', 'Nächste'],
  back: ['Atrás', 'Back', 'Zurück'],

  noWeatherDataAvailable: [
    'No hay datos de tiempo disponibles.',
    'No weather data available.',
    'Keine Wetterdaten verfügbar.'
  ],
  fewMinutes: [
    'Vuelve en unos minutos',
    'Come back in a few minutes',
    'Kommen Sie in ein paar Minuten wieder'
  ],
  noWeatherDataForZipcode: [
    'Configurar la ubicación para mostrar información meteorológica externa',
    'Setup location to display external weather information',
    'Standort für die Anzeige externer Wetterinformationen einrichten'
  ],
  baseStationLocation: ['Estación base: ', 'Base station: ', 'Basisstation: '],
  outside: ['Exterior: ', 'Outside: ', 'Außen: '],
  waitingForData: [
    'Obteniendo datos de la estación base, esto puede tardar un rato, por favor vuelva más tarde.',
    'Getting data from the base station, this may take a while, please come back later.',
    'Daten von der Basisstation abrufen, dies kann einige Zeit dauern, bitte kommen Sie später zurück.'
  ],
  changeZipcode: [
    'Puedes probar a cambiarlo por un código postal cercano.',
    'You can try to change it by a near zip code.',
    'You can try to change it by a near zip code.'
  ],
  externalWeather: ['Tiempo externo', 'External weather', 'Externe Wetterbedingungen'],
  navWeatherLocation: ['2. Ubicación', '2. Location', '2. Standort'],
  navWeatherDevices: ['1. Dispositivos', '1. Devices', '1. Geräte'],
  indicateAdress: [
    'Indique la dirección que desea mostrar para este dispositivo',
    'Indicate the address you want to display for this device',
    'Geben Sie die Adresse an, die Sie für dieses Gerät anzeigen möchten'
  ],
  applyLocation: [
    'Aplique esta ubicación a los siguientes dispositivos:',
    'Apply this location to the following devices:',
    'Wenden Sie diesen Standort auf die folgenden Geräte an:'
  ],
  onlyOneDevice: ['Este dispositivo sólo', 'This device only', 'Dieses Gerät nur'],
  multipleDevice: [
    'Seleccionar varios dispositivos',
    'Select multiple devices',
    'Mehrere Geräte auswählen'
  ],
  currentLocation: [
    'Utilizar mi ubicación actual (requiere servicios de localización)',
    'Use my current location (requires location services)',
    'Verwenden Sie meinen aktuellen Standort (erfordert Standortdienste)'
  ],
  searchByAddressOrZipCode: [
    'Buscar por dirección o código postal disponible',
    'Search by address or zip code available',
    'Suche nach Adresse oder Postleitzahl verfügbar'
  ],
  searchAdress: [
    'Buscar dirección o código postal',
    'Search adress or zip code',
    'Suche Adresse oder Postleitzahl'
  ],
  selectMap: ['Seleccionar en el mapa', 'Select on the map', 'Wählen Sie auf der Karte'],
  setupLocation: ['Configurar ubicación', 'Setup location', 'Standort einrichten'],
  noInformation: ['No hay información', 'No information', 'Keine Informationen'],
  customValues: ['Valores personalizados', 'Custom values', 'benutzerdefinierte Werte'],

  yellowWarning: ['Nivel Amarillo', 'Yellow Warning', 'Gelbe Warnung'],

  redWarning: ['Nivel Rojo', 'Red Warning', 'Rote Warnung'],

  Whoops: ['Ups', 'Whoops!', 'Hoppla!'],

  noWeatherDataAvailableExtra: [
    '¿Acabas de añadir este sensor? Los datos del tiempo tardarán una media hora en aparecer.',
    'Did u just recently added this system? It will take about half an hour before we have your weather data measured.'
  ],
  makeSureCorrectZipCode: [
    'Asegurate de que has introducido el código postal correctamente.',
    'Make sure you entered the zip code correctly.',
    'Haben Sie diesen Sensor gerade hinzugefügt? Es dauert etwa eine halbe Stunde, bis die Wetterdaten angezeigt werden.'
  ],
  apply: ['APLICAR', 'APPLY', 'APPLY'],
  navDevice: ['1. Dispositivo', '1. Device', '1. Gerät'],
  navDevices: ['1. Dispositivos', '1. Devices', '1. Geräte'],
  navAccount: ['2. Cuenta', '2. Account', '2. Konto'],
  navEnviroment: ['2. Entorno', '2. Environment', '2. Umgebung'],
  navParams: ['2. Parámetros', '2. Parameters', '2. Parameter'],
  editNavParams: ['1. Parámetros', '1. Parameters', '1. Parameter'],
  navDateAndFormat: ['2. Fechas y formato', '2. Date and format', '2. Daten und Format'],
  multipleNavDateAndFormat: ['3. Fechas y formato', '3. Date and format', '3. Daten und Format'],
  infoId: [
    'El Id se obtiene al configurar el dispositivo por primera vez desde la app',
    'Id is obtained the first time the device is configured from the app',
    'Die ID wird bei der ersten Konfiguration des Geräts über die App abgerufen'
  ],
  agree: ['Sí, eliminarlo', 'Yes, delete', 'Akzeptieren'],
  cancel: ['Cancelar', 'Cancel', 'Stornieren'],
  definedValues: ['Valores predefinidos: ', 'Define value: ', 'Wert definieren'],
  addDevice: ['Añadir dispositivo', 'Add device', 'Gerät hinzufügen'],
  buildingTypeInfo: [
    'Los siguientes campos opcionales ayudarán a optimizar más tu experiencia en My inBiot clasificando el espacio en el que has colocado tu MICA. Si no los conoces puedes añadirlos más adelante.',
    "The following optional fields will help to further optimise your My inBiot experience by classifying the space in which you have placed your MICA. If you don't know them you can add them later.",
    'Die folgenden optionalen Felder helfen Ihnen, Ihr My inBiot-Erlebnis weiter zu optimieren, indem sie den Raum klassifizieren, in dem Sie Ihr MICA platziert haben. Wenn Sie sie nicht kennen, können Sie sie später hinzufügen.'
  ],
  typeOfUseBuildingInfoN: [
    '<h4> Tipo de uso </h4>' +
      '<br/> Es importante conocer cuál es el uso principal del espacio a \n' +
      'monitorizar para ayudar a identificar patrones de uso y \n' +
      'referentes normativos. <b>Residencial</b> para uso vivienda y <b>terciario</b> para \n' +
      'oficinas, retail, centros educativos, hostelería, etc.',
    '<h4> Use of building </h4>' +
      '<br/> It is important to know the main use of the space to be monitored in the building to help identify usage patterns and regulatory references. <b>Residential</b> for housing use and <b>tertiary</b> for offices, retail, educational centres, hotels, etc.',
    '<h4> Tipo de uso </h4>' +
      '<br/>Es ist wichtig, die Hauptnutzung des zu überwachenden Raums im Gebäude zu kennen, um Nutzungsmuster und gesetzliche Vorgaben zu ermitteln. <b>Wohngebiete</b> für Wohnzwecke und <b>Tertiärgebiete</b> für Büros, Einzelhandel, Bildungszentren, Hotels usw.'
  ],
  ventilationBuildingInfoN: [
    '<h4> Tipo de ventilación </h4>' +
      '<br/> Identifica si hay o no un sistema mecánico de ventilación con impulsión y/o extracción mecánica. O si la ventilación está integrada a través del sistema de climatización. Si no hay nada y se ventila a través de ventanas y puertas, será ventilación manual.',
    '<h4> Ventilation type </h4>' +
      '<br/> Determine if there is a mechanical ventilation system with mechanical supply and/or exhaust. Or if ventilation is provided by the air conditioning system. If there is none and ventilation is through windows and doors, it is manual ventilation.'
  ],
  occupancyBuildingInfoN: [
    '<h4> Ocupación </h4>' +
      '<br/> ¿El espacio se ocupa de forma continuada - permanente, con un horario de ocupación a lo largo de todo el año – oficinas, centros educativos, hostelería, o sólo se utiliza estacionalmente, como segundas residencias o espacios para eventos u alquileres temporales?',
    '<h4> Occupancy </h4>' +
      '<br/> Is the space occupied on a continuous basis - permanent, with a year-round occupancy schedule - offices, educational facilities, hospitality - or is it used seasonally, as a second home or space for events or temporary rentals?'
  ],
  airTightnessLevelBuildingInfoN: [
    '<h4> Nivel de hermeticidad </h4>' +
      '<br/> Conocer el nivel de hermeticidad de un edificio ayuda a controlar las fugas de aire no controladas (infiltraciones) en el edificio por juntas entre materiales y/o sistemas constructivos.  Se verifican a través de pruebas de hermeticidad – generalmente blower door test n50.',
    '<h4> Tightness level </h4>' +
      '<br/> Knowing the level of airtightness of a building helps to control uncontrolled air leakage (infiltration) into the building through joints between materials and/or building systems.  It is verified by airtightness tests - usually the Blower Door test n50.'
  ],
  editDevice: ['Editar dispositivo', 'Edit device', 'Gerät bearbeiten'],
  editDevicePeriodicity: [
    'Periodicidad subida datos',
    'Periodicity of uploading data',
    'Periodizität des Hochladens von Daten'
  ],
  timeInterval: ['Periodicidad en minutos', 'Periodicity in minutes', 'Periodizität in Minuten'],
  periodicity: ['Periodicidad', 'Periodicity', 'Periodizität'],
  timeIntervalInfo: [
    'Introduce la periodicidad (en minutos) en la que tu MICA enviará datos a My inBiot.',
    'Enter the frequency (in minutes) at which your MICA will send data to My inBiot.',
    'Geben Sie die Häufigkeit (in Minuten) an, mit der Ihr MICA Daten an My inBiot senden soll.'
  ],
  editDeviceLights: ['Luz indicadora', 'Indicator light', 'Anzeigelampe'],
  co2Calibration: [
    'Auto-calibración CO<sub>2</sub>',
    'CO<sub>2</sub> auto-calibration',
    'CO<sub>2</sub> Auto-Kalibrierung'
  ],
  enableDeviceLights: [
    'Activar luz indicadora',
    'Activate indicator light',
    'Anzeigelampe einschalten'
  ],
  disableDeviceLights: [
    'Desactivar luz indicadora',
    'Deactivate indicator light',
    'Kontrollleuchte deaktivieren'
  ],
  enableLightsInfo: [
    'Si activas esta opción el MICA mantendrá encendida la luz indicadora de necesidad de ventilación.' +
      '<br/> <br/> Nota: Los cambios se aplicarán en el MICA en el siguiente ciclo subida de datos.',
    'If you activate this option, the MICA will keep the ventilation indicator light on.' +
      '<br/> <br/> Note: The changes shall be applied in the MICA on the next data upload cycle.',
    'Wenn Sie diese Option aktivieren, lässt die MICA die Belüftungsanzeige leuchten.' +
      '<br/> <br/> Anmerkung: Die Änderungen werden beim nächsten Datenupload-Zyklus in die MICA übernommen.'
  ],

  disableLightsInfo: [
    'Si desactivas esta opción el MICA mantendrá apagada la luz indicadora de necesidad de ventilación. Además, al pulsar el botón táctil, la luz indicadora se encenderá por 3 segundos y luego se apagará.' +
      '<br/> <br/> Nota: Los cambios se aplicarán en el MICA en el siguiente ciclo subida de datos.',
    'If you disable this option, the MICA will keep the ventilation indicator light off. In addition, when the touch button is pressed, the indicator light will turn on for 3 seconds and then turn off.' +
      '<br/> <br/> Note: The changes will be applied in the MICA in the next data upload cycle.',
    'Wenn Sie diese Option deaktivieren, bleibt die Lüftungsanzeige des MICA ausgeschaltet. Außerdem leuchtet beim Drücken der Berührungstaste die Kontrollleuchte 3 Sekunden lang auf und geht dann aus.' +
      '<br/> <br/> Anmerkung: Die Änderungen werden beim nächsten Datenupload-Zyklus in die MICA übernommen.'
  ],
  deviceUpdateInfo: [
    'Los cambios se aplicarán en el MICA en la siguiente toma de datos.',
    'The changes will be implemented in the MICA at the next data collection.',
    'Die Änderungen werden bei der nächsten Datenerhebung in die MICA aufgenommen.'
  ],
  downloadStatisticsInfo: [
    'Esta funcionalidad sirve para descargar los estadísticos (máximos, mínimos, medias) de un rango de tiempo (diarios, semanales, mensuales) de los parámetros de un dispositivo MICA.',
    'This functionality is used to download the statistics (maximum, minimum, average) of a time range (daily, weekly, monthly) of the parameters of a MICA device.',
    'Mit dieser Funktion können Sie die Statistiken (Maximum, Minimum, Durchschnitt) eines Zeitraums (täglich, wöchentlich, monatlich) der Parameter eines MICA-Geräts herunterladen.'
  ],
  applyToAllDevices: [
    'Aplicar a todos los dispositivos',
    'Apply to all devices',
    'Auf alle Geräte anwenden'
  ],
  applyToAllDevicesInfo: [
    'Seleccionando esta opción aplicarás los cambios a todos los MICAs asociados a esta cuenta.',
    'Selecting this option will apply the changes to all MICAs associated with this account.',
    'Wenn Sie diese Option wählen, werden die Änderungen auf alle mit diesem Konto verbundenen MICAs angewendet.'
  ],
  warning: ['Aviso', 'Warning', 'Warnung'],
  inactivity: ['Inactividad', 'Inactivity', 'Inaktivität'],
  onlyOneDeviceInactivity: [
    'Cada dispositivo solo puede tener una alerta de inactividad.',
    'Each device can only have one inactivity alert.',
    'Jedes Gerät kann nur eine Inaktivitätswarnung haben.'
  ],
  error: ['Error', 'Error', 'Fehler'],
  selectEmails: ['Seleccionar correos', 'Select emails', 'Emails auswählen'],

  deleteSystemConfirmationText: {
    p1: [
      '¿Estas seguro de que quieres quitar el dispositivo',
      'Are you sure you want to remove the',
      'Sind Sie sicher, dass Sie das Gerät'
    ],
    p2: ['de esta cuenta ?', 'device from this account?', 'aus diesem Konto entfernen möchten?']
  },
  deleteDataConfirmationText: [
    '¿Estas seguro de que quieres borrar los datos?',
    'You are going to delete the data. Are you sure about it?',
    'Sie werden die Daten löschen. Bist du dir sicher?'
  ],
  deleteSystemFromGroupConfirmationText: {
    p1: [
      '¿Estás seguro de que quieres quitar el dispositivo',
      'Are you sure you want to remove the',
      'Sind Sie sicher, dass Sie das Gerät'
    ],
    p2: ['del grupo', 'device from the', 'aus der Gruppe'],
    p3: ['?', 'group?', 'entfernen müssen?']
  },
  deleteGroupConfirmationText: {
    p1: [
      '¿Estás seguro de que quieres borrar el grupo',
      'Are you sure you want to delete the',
      'Sind Sie sicher, dass Sie die Gruppe'
    ],
    p2: ['?', ' group?', ' löschen wollen?']
  },
  deleteLogoConfirmationText: [
    '¿Estás seguro de que quieres eliminar el logo?',
    'You are going to delete this logo. Are you sure about it?',
    'Sie werden dieses Logo löschen. Sind Sie sich dessen sicher?'
  ],
  deleteScreenConfirmationText: [
    '¿Estás seguro de que quieres borrar la pantalla?',
    'You are going to delete this screen. Are you sure about it?'
  ],
  deleteAlarmConfirmationText: [
    '¿Estás seguro de que quieres borrar la alarma?',
    'You are going to delete this alarm. Are you sure about it?',
    'Sie werden diesen Alarm löschen. Sind Sie sich dessen sicher?'
  ],
  deleteAlertRecipientConfirmationText: [
    '¿Estas seguro de que quieres eliminar este correo de la lista de recipientes de alertas?',
    'You are going to remove this email from the alert recipients list. Are you sure about it?',
    'Sie werden diese E-Mail aus der Liste der Alarmempfänger entfernen. Sind Sie sicher, dass dies der Fall ist?'
  ],

  // Login page
  loginPage: {
    needAnAccount: ['¿Necesitas una cuenta?', 'Need an account?', 'Benötigen Sie einen Account?'],
    termsAndConditions1: [
      'Al iniciar sesión, aceptas la',
      'When you login, you accept the',
      'Wenn Sie sich anmelden, akzeptieren Sie die'
    ],
    termsAndConditions2: [' y los', ' and the', ' und das'],
    termsAndConditions3: [' de inBiot.', ' of inBiot.', ' von inBiot.']
  },

  helpOptions: {
    howMyInbiotWorks: [
      '¿Cómo funciona My Inbiot?',
      'How My Inbiot works?',
      'Wie funktioniert mein Inbiot?'
    ],
    contactUs: ['Contacta con nosotros', 'Contact us', 'Kontaktiere uns']
  },

  // Sign up page
  signUpPage: {
    alreadyHaveAnAccount: [
      '¿Ya tienes una cuenta?',
      'Already have an account?',
      'Haben Sie bereits einen Account?'
    ]
  },

  newGroupForm: {
    selectDevices: ['Seleccionar dispositivos:', 'Select devices:', 'Geräte auswählen:']
  },

  editGroupForm: {
    selectDevices: ['Seleccionar dispositivos para añadir', 'Select devices to add'],
    groupDevices: ['Dispositivos en el grupo', 'Group devices']
  },

  confirmUserInfoPage: {
    header: [
      'Verificar dirección de correo electrónico',
      'Verify e-mail address',
      'Email Adresse bestätigen'
    ],
    subHeader: [
      'Te hemos enviado un mail a ',
      'An email has been sent to ',
      'Eine email wurde gesendet an '
    ],
    mailInstructions: [
      'Pulsa el botón "Confirmar correo electrónico" y podrás acceder a My inBiot.',
      'Press the "Verify email address" button and you could access to My inBiot.',
      'Drücken Sie die Schaltfläche "E-Mail bestätigen" und Sie können auf Mein inBiot zugreifen.'
    ],
    noMailReceived: [
      'Si no has recibido ningún correo: ',
      'If you have not received any email: ',
      'Falls Sie keine E-Mail erhalten haben: '
    ],
    resendCode: [
      'Volver a enviar correo de verificación',
      'Resend verification email',
      'Bestätigungsmail erneut senden'
    ],
    nothingReceived: [
      'Si sigues sin recibir nada, prueba a: ',
      'If you still do not receive anything, try: ',
      'Wenn Sie immer noch nichts erhalten, versuchen Sie Folgendes: '
    ],
    backToSignUp: [
      'Volver a crear cuenta',
      'Go back to sign up',
      'Gehen Sie zurück, um sich anzumelden'
    ]
  },

  // Confirm Sign Up page
  confirmUserPage: {
    header: [
      'Dirección de correo verificada con éxito.',
      'E-mail account has been successfully verified.',
      'E-Mail-Konto wurde erfolgreich verifiziert'
    ],
    subHeader: [
      '¡Todo listo! Ya puedes acceder a My inBiot.',
      'All ready! You can now access My inBiot.',
      'Alles bereit! Sie können jetzt auf Mein inBiot zugreifen.'
    ],
    goToLogin: ['Acceder a My inBiot', 'Go to login', 'Gehe zu Login']
  },

  // Confirm Alarm Recipient page
  confirmRecipientPage: {
    header: [
      'Dirección de correo verificada con éxito.',
      'E-mail account has been successfully verified.',
      'E-Mail-Konto wurde erfolgreich verifiziert'
    ],
    subHeader: [
      '¡Todo listo! Ya puedes empezar a recibir alarmas en dicha dirección de correo.',
      'All ready! You can now start receiving alarms at this email address.',
      'Alles bereit! Sie können nun Alarme über diese E-Mail-Adresse empfangen.'
    ],
    goToLogin: ['Acceder a My inBiot', 'Go to login', 'Gehe zu Login']
  },

  confirmCollaboratorPage: {
    header: [
      'Invitación aceptada con éxito.',
      'Invitation successfully accepted.',
      'Einladung erfolgreich angenommen.'
    ],
    subHeader: [
      '¡Todo listo! Ya puedes comenzar a monitorizar la calidad de aire interior en los dispositivos a los que te han brindado acceso.',
      'All set! You can now start monitoring indoor air quality on the devices you have been given access to.',
      'Alles bereit! Sie können nun mit der Überwachung der Raumluftqualität auf den Geräten beginnen, zu denen Sie Zugang erhalten haben.'
    ],
    goToLogin: ['Acceder a My inBiot', 'Go to login', 'Gehe zu Login']
  },

  acceptPolicyandTerms: {
    header: [
      'He leído y acepto la',
      'I have read and accept the',
      'Ich habe die AGB gelesen und stimme diesen zu.'
    ],
    policy: ['Política de Privacidad', 'Privacy Policy', 'Datenschutzrichtlinien'],
    terms: ['Términos de Uso', 'Terms of Use', 'Nutzungsbedingungen'],
    cookies: ['Cookies', 'Cookies', 'Cookies'],
    adding: ['y', 'and', 'und']
  },
  manageCookiesPage: {
    header: ['Gestión de cookies', 'Cookie management', 'Cookie-Verwaltung'],
    subHeader: [
      'En My inBiot utilizamos cookies propias y de terceros para mejorar tu experiencia de usuario y ofrecerte los mejores servicios.',
      'At My inBiot we use our own and third-party cookies to improve your user experience and offer you the best services.',
      'Bei My inBiot verwenden wir eigene und Cookies von Drittanbietern, um Ihr Benutzererlebnis zu verbessern und Ihnen die besten Dienste anzubieten.'
    ],
    description: [
      'Puedes aceptar todas las cookies pulsando el botón "Aceptar todas las cookies" o rechazar su uso pulsando el botón "Rechazar cookies".',
      'You can accept all cookies by clicking the "Accept all cookies" button or reject their use by clicking the "Reject cookies" button.',
      'Sie können alle Cookies akzeptieren, indem Sie auf die Schaltfläche "Alle Cookies akzeptieren" klicken oder ihre Verwendung ablehnen, indem Sie auf die Schaltfläche "Cookies ablehnen" klicken.'
    ],
    acceptAll: ['Aceptar todas las cookies', 'Accept all cookies', 'Alle Cookies akzeptieren'],
    reject: ['Rechazar cookies', 'Reject cookies', 'Cookies ablehnen']
  },

  // Forgotten password page
  forgottenPasswordPage: {
    header: ['Contraseña olvidada', 'Forgot password', 'Passwort vergessen'],
    subHeader: [
      'Escribe tu dirección de correo electrónico y te enviaremos instrucciones para restablecer la contraseña.',
      'Please enter your email address and we will send you the instructions to restore the password.',
      'Bitte geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen die Anweisungen zum Wiederherstellen des Passworts'
    ],
    submitButton: ['Mándame un link', 'Send me a link', 'Sende mir einen Link'],
    restore: ['Restablecer', 'Restore', 'Wiederherstellen'],
    returnTo: ['Volver a ', 'Return to ', 'Zurücksenden an ']
  },

  // Recover password page
  recoverPasswordPage: {
    header: ['Recuperar contraseña', 'Recover password', 'Passwort wiederherstellen'],
    subHeader: [
      'Se ha enviado un enlace a tu correo para proceder al cambio de contraseña.',
      'A changing password link has been sent to your mail.',
      'Ein Link zum Ändern des Passworts wurde an Ihre E-Mail gesendet.'
    ],
    submitButton: ['Restablecer contraseña', 'Restore password', 'Passwort wiederherstellen']
  },

  homePage: {
    editGroup: ['Editar grupo', 'Edit group', 'Gruppe bearbeiten'],
    deleteGroup: ['Eliminar grupo', 'Delete group', 'Gruppe löschen']
  },

  disabledSystem: [
    'El sensor ha sido desactivado.',
    'This system is disabled.',
    'Dieses System ist deaktiviert.'
  ],

  downloadDataForm: {
    selectDevices: ['Seleccionar dispositivos', 'Select devices', 'Geräte auswählen'],
    disponible: ['(Disponible: ', '(Available: ', '(Verfügbar: '],
    days: [' días)', ' days)', ' tage)'],
    selectParameters: [
      'Seleccionar parámetros a mostrar',
      'Select parameters to display',
      'Wählen Sie die anzuzeigenden Parameter aus'
    ],
    selectValues: ['Valor para la alerta', 'Value for the alert', 'Wert für Warnung'],
    selectAll: ['Seleccionar todos', 'Select all', 'Alle auswählen'],
    selectDateInterval: [
      'Seleccionar intervalo de fechas',
      'Select date range',
      'Select date range',
      'Datumsbereich auswählen'
    ],
    getMoreRange: [
      '¿Cómo accedo a un mayor rango de datos?',
      'How do I access a wider range of data?',
      'Wie erhalte ich Zugang zu einer breiteren Palette von Daten?'
    ],
    downloadFormat: ['Seleccionar formato', 'Select format', 'Format auswählen'],
    downloadExtension: ['Extensión de archivo:', 'File extension: ', 'Dateierweiterung:'],
    statisticsInterval: [
      'Intervalo de estadísticos',
      'Statistics interval',
      'Statistisches Intervall'
    ],
    downloadOrder: ['Orden datos:', 'Data order:', 'Bestellung von Daten:'],
    olderDataFirst: ['Más antiguos primero', 'Older first', 'Ältere zuerst'],
    latestDataFirst: ['Más recientes primero', 'Latest first', 'Neueste zuerst'],
    dataGrouping: ['Agrupación datos', 'Data grouping', 'Gruppierung der Daten'],
    originalData: ['Datos originales', 'Original data', 'Ursprüngliche Daten'],
    grouping10: ['Medias 10min', 'Average 10min', 'Durchschnittlich 10min'],
    grouping15: ['Medias 15min', 'Average 15min', 'Durchschnittlich 15min'],
    grouping30: ['Medias 30min', 'Average 30min', 'Durchschnittlich 30min'],
    grouping1: ['Medias 1h', 'Average 1h', 'Strümpfe 1h']
  },
  profileForm: {
    micaLiteMainIndicator: [
      'Indicador principal MICA Lite',
      'Main indicator MICA Lite',
      'Hauptindikator MICA Lite'
    ],

    micaMainIndicator: ['Indicador principal MICA', 'Main indicator MICA', 'Hauptindikator MICA'],
    micaPlusMainIndicator: [
      'Indicador principal MICA Plus',
      'Main indicator MICA Plus',
      'Hauptindikator MICA Plus'
    ],
    micaDeskMainIndicator: [
      'Indicador principal MICA Desk',
      'Main indicator MICA Desk',
      'Hauptindikator MICA Desk'
    ],
    micaWellMainIndicator: [
      'Indicador principal MICA WELL',
      'Main indicator MICA WELL',
      'Hauptindikator MICA WELL'
    ]
  },
  uploadFileModal: {
    title: ['Configuración logotipo', 'Logo configuration', 'Logokonfiguration'],
    currentLogo: ['Logotipo actual', 'Current logo', 'aktuelles Logo'],
    noLogo: [
      'Actualmente no hay ningún archivo subido',
      'There are currently no files uploaded',
      'Es sind derzeit keine Dateien hochgeladen'
    ],
    deleteLogoConfirmationText: [
      '¿Estás seguro de que quieres eliminar el logo?',
      'You are going to delete this logo. Are you sure about it?',
      'Sie werden dieses Logo löschen. Sind Sie sich dessen sicher?'
    ],
    uploadFile: ['Subir archivo', 'Upload file', 'Datei hochladen'],
    deleteFile: ['Eliminar', 'Delete', 'Löschen'],
    selectFile: [
      'Selecciona el fichero que desea cargar',
      'Select the file to be uploaded',
      'Wählen Sie die hochzuladende Datei'
    ],
    upload: ['Subir', 'Upload', 'Hochladen'],
    saveChanges: ['Guardar cambios', 'Save changes', 'Änderungen speichern'],
    cancel: ['Cancelar', 'Cancel', 'Abbrechen']
  },
  fullScreenPage: {
    link: ['Enlace', 'Link', 'Link'],
    knowIaq: [
      'Accede desde </br>tu smartphone',
      'View on your </br> smartphone',
      'Ansicht auf Ihrem </br> Smartphone'
    ],
    Excellent: ['Excelente', 'Excellent', 'Ausgezeichnete'],
    Good: ['Bueno', 'Good', 'Gute'],
    Moderate: ['Moderado', 'Moderate', 'Mäßige'],
    Regular: ['Regular', 'Regular', 'Regelmäßige'],
    Inadequate: ['Inadecuado', 'Inadequate', 'Unzureichende'],
    Poor: ['Malo', 'Poor', 'Schlechte'],
    savedShareables: ['Compartibles guardados', 'Saved shareables', 'Saved shareables'],
    searchShareable: ['Buscar compartible', 'Search shareable', 'Suche gemeinsam nutzbar'],
    uploadLogo: ['Logotipo', 'Logo', 'Logo'],
    newShareable: ['Nuevo compartible', 'New shareable', 'Neue Teilung'],
    createShareable: ['Crear compartible', 'Create shareable', 'Gemeinsam nutzbar machen'],
    editShareable: ['Editar compartible', 'Edit shareable', 'Edit shareable'],
    saveChanges: ['Guardar cambios', 'Save changes', 'Save changes'],
    deleteSharable: ['Borrar compartible', 'Delete shareable', 'Delete shareable'],
    qrDownload: ['Descargar QR', 'Download QR', 'Download QR'],
    downloadQRCertificate: [
      'Descargar certificado QR',
      'Download QR certificate',
      'Download QR certificate'
    ],
    downloadQR: ['Descargar código QR', 'Download QR code', 'Download QR code'],
    share: ['Compartir', 'Share', 'Share'],
    shareDeviceLink: ['Copiar enlace a MICA', 'Copy link to device', 'Copy link to device'],
    shareFullscreenLink: [
      'Copiar enlace a pantalla completa',
      'Copy link to fullscreen',
      'Copy link to fullscreen'
    ],
    veryGoodIAQ: [
      'Muy buena Calidad de Aire Interior',
      'Very good Indoor Air Quality',
      'Very good Indoor Air Quality'
    ],
    veryBadIAQ: [
      'Muy mala Calidad de Aire Interior',
      'Very bad Indoor Air Quality',
      'Very bad Indoor Air Quality'
    ],
    normalIAQ: [
      'Calidad de Aire Interior normal',
      'Regular Indoor Air Quality',
      'Regular Indoor Air Quality'
    ],
    veryLowProbability: [
      'Probabilidad de propagación de virus muy baja',
      'Very low virus propagation probability',
      'Very low virus propagation probability'
    ],
    lowProbability: [
      'Probabilidad de propagación de virus baja',
      'Low virus propagation probability',
      'Low virus propagation probability'
    ],
    mediumProbability: [
      'Probabilidad de propagación de virus media',
      'Medium virus propagation probability',
      'Medium virus propagation probability'
    ],
    highProbability: [
      'Probabilidad de propagación de virus alta',
      'High virus propagation probability',
      'High virus propagation probability'
    ],
    lowVentilation: [
      'No se requiere ventilar',
      'No ventilation required',
      'Keine Belüftung erforderlich'
    ],
    mediumVentilation: ['Se recomienda ventilar', 'Ventilation recommended', 'Belüftung empfohlen'],
    highVentilation: [
      'Es necesario ventilar',
      'Ventilation is required',
      'Belüftung ist erforderlich'
    ],
    micaLiteHeading: [
      'Probabilidad de propagación de virus',
      'Virus propagation probability',
      'Virus propagation probability'
    ],
    micaMiniHeading: ['Necesidad de ventilación', 'Ventilation need', 'Ventilation need'],
    micaHeading: ['Calidad de aire interior', 'Indoor Air Quality', 'Indoor Air Quality'],
    smartphoneText: [
      'Visualiza estas mediciones y mucho más en tu smartphone',
      'View this data and more information in your smartphone',
      'View this data and more information in your smartphone'
    ],
    discoverMore: ['Descubre más en: ', 'Discover more in: ', 'Discover more in: '],
    outdoorWeather: ['Tiempo exterior', 'Outdoor Weather', 'Outdoor Weather']
  },
  listDays: [
    'Indique en que horario quiere recibir los correos',
    'Indicate when you want to receive the emails',
    'Geben Sie an, wann Sie die Mails erhalten möchten'
  ],
  lastUpdate: ['Última actualización', 'Last update', 'Last update'],

  co2CalibrationModal: {
    title: [
      'Autocalibración del sensor de CO₂',
      'CO₂ sensor self-calibration',
      'CO₂-Sensor Selbst-Kalibrierung'
    ],
    subtitle: [
      'La duración del ciclo de autocalibración varía dependiendo del tipo de ventilación utilizado en el espacio donde se encuentra el dispositivo MICA.',
      'The duration of the self-calibration cycle varies depending on the type of ventilation used in the space where the MICA device is located.',
      'Die Dauer des Selbstkalibrierungszyklus hängt von der Art der Belüftung des Raums ab, in dem sich die MICA-Einrichtung befindet.'
    ],
    info48: [
      'Ciclo de calibración de 48 h: recomendado para espacios ventilados manualmente, donde las concentraciones de CO2 del aire fresco se alcanzan al menos una vez cada 48 horas.',
      '48h calibration cycle: recommended for manually ventilated spaces, where the fresh air CO2 concentrations are achieved at least once every 48 hours.',
      '48-Stunden-Kalibrierungszyklus: empfohlen für manuell belüftete Räume, in denen die CO2-Konzentration der Frischluft mindestens einmal alle 48 Stunden gemessen wird.'
    ],
    info24: [
      'Ciclo de calibración de 24h: recomendado para espacios ventilados mecánicamente o con sistemas HVAC, donde las concentraciones de CO2 en aire fresco se alcanzan diariamente.',
      '24h calibration cycle: recommended for mechanically ventilated spaces or spaces with HVAC systems, where the fresh air CO2 concentrations are achieved daily.',
      '24-Stunden-Kalibrierungszyklus: empfohlen für mechanisch belüftete Räume oder Räume mit HVAC-Systemen, in denen die CO2-Konzentration der Frischluft täglich erreicht wird.'
    ],
    info7: [
      'Ciclo de calibración de 7 días: recomendado para espacios mal ventilados en los que puede ser difícil alcanzar concentraciones óptimas de CO2 en el aire fresco.',
      '7 days calibration cycle: recommended for poorly ventilated spaces where optimal fresh air CO2 concentrations may be difficult to achieve.',
      '7-Tage-Kalibrierungszyklus: empfohlen für schlecht belüftete Räume, in denen eine optimale CO2-Konzentration der Frischluft schwer zu erreichen ist.'
    ],
    infoOff: [
      'Desactivar calibración: no se recomienda desactivar la calibración, a menos que sea necesario para realizar pruebas en entornos extraordinarios, como laboratorios.',
      'Disable calibration: it is not recommended to disable calibration, unless it is required for testing in extraordinary environments such as laboratories.',
      'Kalibrierung deaktivieren: Es wird nicht empfohlen, die Kalibrierung zu deaktivieren, es sei denn, sie ist für Tests in außergewöhnlichen Umgebungen wie z. B. in Labors erforderlich. '
    ],
    calibration48h: [
      'Ciclo de calibración de 48 h',
      '48h calibration cycle',
      '48-Stunden-Kalibrierungszyklus'
    ],
    calibration24h: [
      'Ciclo de calibración de 24 h',
      '24h calibration cycle',
      '24-Stunden-Kalibrierungszyklus'
    ],
    calibration7d: [
      'Ciclo de calibración de 7 días',
      '7 days calibration cycle',
      '7-Tage-Kalibrierungszyklus'
    ],
    calibrationOff: ['Desactivar calibración', 'Disable calibration', 'Kalibrierung deaktivieren'],
    calibration15: [
      'Ciclo de calibración de 15 días',
      '15 days calibration cycle',
      '15-Tage-Kalibrierungszyklus'
    ],

    info15: [
      'Ciclo de calibración de 15 días: recomendado para espacios sin ventilación.',
      '15 days calibration cycle: recommended for unventilated spaces.',
      '7-tägiger Kalibrierungszyklus: für unbelüftete Räume empfohlen.'
    ],
    note: [
      'Nota: para asegurar un funcionamiento óptimo del sensor, trate de alcanzar el nivel de aire fresco exterior (400 ppm de CO₂) durante el ciclo de autocalibración, al menos en una ocasión.',
      'Note: To ensure optimal sensor performance, try to reach the outside fresh air level (400 ppm CO₂) during the self-calibration cycle at least once.',
      'Hinweis: Um eine optimale Sensorleistung zu gewährleisten, versuchen Sie, während des Selbstkalibrierungszyklus mindestens einmal den Außenluftpegel (400 ppm CO₂) zu erreichen.'
    ]
  },

  addAlertModal: {
    selectAll: ['Seleccionar todos', 'Select all', 'Alle auswählen'],
    selectParameters: [
      'Selecciona el parámetro para la alerta',
      'Select the parameter for the alert',
      'Wählen Sie den Parameter für die Meldung'
    ],
    indicators: ['Indicadores', 'Indicators', 'Indikatoren'],
    parameters: ['Parámetros', 'Parameters', 'Parameter'],
    rangeCo2Error: [
      'Debes introducir un valor superior a 400',
      'You must enter a value greater than 400',
      'Sie müssen einen Wert größer als 400 eingeben'
    ],
    rangeError: [
      'Debes introducir un valor superior a 0',
      'You must enter a value greater than 0',
      'Sie müssen einen Wert größer als 0 eingeben'
    ]
  },

  addInactivityAlertModal: {
    navDevices: ['1. Dispositivos', '1. Devices', '1. Geräte'],
    navValues: ['2. Valores', '2. Values', '2. Werte'],
    navEmails: ['3. Correos', '3. E-Mails', '3. E-Mails']
  },

  addParameterAlertModal: {
    navDevices: ['3. Dispositivos', '3. Devices', '3. Geräte'],
    navParams: ['1. Parámetros', '1. Parameter', '1. Parameter'],
    navValues: ['2. Valores', '2. Values', '2. Werte'],
    navEmails: ['4. Configuración', '4. Settings', '4. Einstellungen']
  },
  predetermined: ['Predeterminado', 'Predetermined', 'Vorauswahl'],
  Personalized: ['Personalizado', 'Personalized', 'Personalisiert'],
  alertValue: ['Valor para la alerta', 'Alert value', 'Wert für die Warnung'],
  addErrorAlertModal: {
    navDevices: ['1. Dispositivos', '1. Devices', '1. Geräte'],
    navValues: ['2. Valores', '2. Values', '2. Werte'],
    navEmails: ['3. Correos', '3. E-Mails', '3. E-Mails']
  },

  editErrorAlertModal: {
    navValues: ['1. Valores', '1. Values', '1. Werte'],
    navEmails: ['2. Correos', '2. E-Mails', '2. E-Mails']
  },

  editInactivityAlertModal: {
    navValues: ['1. Valores', '1. Values', '1. Werte'],
    navEmails: ['2. Correos', '2. E-Mails', '2. E-Mails']
  },

  editParameterAlertModal: {
    navParams: ['1. Parámetros', '1. Parameter', '1. Parameter'],
    navValues: ['2. Valores', '2. Values', '2. Werte'],
    navEmails: ['3. Correos', '3. E-Mails', '3. E-Mails']
  },

  addFullScreenModal: {
    newShareable: ['Nuevo compartible', 'New shareable', 'Neue Teilung'],
    navDevices: ['1. Dispositivos', '1. Devices', '1. Geräte'],
    navIndicators: ['2. Indicadores', '2. Indicators', '2. Indikatoren'],
    navParams: ['3. Parámetros', '3. Parameters', '3. Parameter'],
    navPrivacy: ['4. Privacidad', '4. Privacy', '4. Privatsphäre'],
    screenViewName: ['Nombrar la pantalla', 'Screen View name', 'Benennen Sie den Bildschirm'],
    selectDevice: ['Seleccionar dispositivo: ', 'Select device: ', 'Gerät auswählen: '],
    selectIndicator: [
      'Selecciona que indicador quieres mostrar: ',
      'Select which indicator you want to display: ',
      'Wählen Sie aus, welchen Indikator Sie anzeigen möchten:'
    ],
    noIndicator: ['Sin indicador', 'No indicator', 'Kein Indikator'],
    selectParameters: [
      'Seleccionar parámetros a mostrar',
      'Select parameters to display',
      'Wählen Sie die anzuzeigenden Parameter aus'
    ],
    selectAll: ['Seleccionar todos', 'Select all', 'Alle auswählen'],
    infoLabelRadio: [
      'Acceso público al MICA',
      'Public access to MICA',
      'Öffentlicher Zugang zu MICA'
    ],
    infoPublic: [
      'Si desactivas esta opción el link compartible a tu dispositivo MICA quedará inutilizado y nadie podrá acceder a él.' +
        'Tampoco se mostrará el código QR en la Pantalla Completa. Puedes editarlo más tarde en editar compartible. ',
      'If you disable this option, the shareable link to your MICA device will be unused and no one will be able to access it. ' +
        'The QR code will also not be displayed on the Full Screen. You can edit it later in edit shareable.',
      'Wenn Sie diese Option deaktivieren, wird der gemeinsame Link zu Ihrem MICA-Gerät nicht genutzt und niemand kann darauf zugreifen.' +
        'Der QR-Code wird auch nicht im Vollbildmodus angezeigt. Sie können es später in Edit Shareable bearbeiten.'
    ],
    infoShowLogo: ['Mostrar logotipo personal', 'Show personal logo', 'Persönliches Logo anzeigen'],
    howToShowLogo: [
      '¿Cómo muestro mi logotipo en el compartible?',
      'How do I show my logo in the shareable?',
      'Wie zeige ich mein Logo in der Freigabe?'
    ],
    createShareable: ['Crear compartible', 'Create shareable', 'Gemeinsam nutzbar machen']
  },

  editFullScreenModal: {
    editShareable: ['Editar compartible', 'Edit shareable', 'Edit shareable'],
    navGeneral: ['1. General', '1. Overview', '1. Überblick'],
    navParams: ['2. Parámetros', '2. Parameters', '2. Parameter'],
    navPrivacy: ['3. Privacidad', '3. Privacy', '3. Privatsphäre'],
    screenViewName: ['Nombrar la pantalla', 'Screen View name', 'Benennen Sie den Bildschirm'],
    selectIndicator: [
      'Selecciona que indicador quieres mostrar: ',
      'Select which indicator you want to display: ',
      'Wählen Sie aus, welchen Indikator Sie anzeigen möchten:'
    ],
    selectParameters: [
      'Seleccionar parámetros a mostrar',
      'Select parameters to display',
      'Wählen Sie die anzuzeigenden Parameter aus'
    ],
    selectAll: ['Seleccionar todos', 'Select all', 'Alle auswählen'],
    infoLabelRadio: [
      'Acceso público al MICA',
      'Public access to MICA',
      'Öffentlicher Zugang zu MICA'
    ],
    infoPublic: [
      'Si desactivas esta opción el link compartible a tu dispositivo MICA quedará inutilizado y nadie podrá acceder a él.' +
        'Tampoco se mostrará el código QR en la Pantalla Completa. Puedes editarlo más tarde en editar compartible. ',
      'If you disable this option, the shareable link to your MICA device will be unused and no one will be able to access it. ' +
        'The QR code will also not be displayed on the Full Screen. You can edit it later in edit shareable.',
      'Wenn Sie diese Option deaktivieren, wird der gemeinsame Link zu Ihrem MICA-Gerät nicht genutzt und niemand kann darauf zugreifen.' +
        'Der QR-Code wird auch nicht im Vollbildmodus angezeigt. Sie können es später in Edit Shareable bearbeiten.'
    ],
    infoShowLogo: ['Mostrar logotipo personal', 'Show personal logo', 'Persönliches Logo anzeigen'],
    howToShowLogo: [
      '¿Cómo muestro mi logotipo en el compartible?',
      'How do I show my logo in the shareable?',
      'Wie zeige ich mein Logo in der Freigabe?'
    ],
    saveChanges: ['Guardar cambios', 'Save changes', 'Änderungen speichern']
  },

  outdoorWeather: {
    clearSky: ['Cielo despejado', 'Clear sky', 'Klarer himmel'],
    fewClouds: ['Pocas nubes', 'Few clouds', 'Wenige wolken'],
    scatteredClouds: ['Nubes dispersas', 'Scattered Clouds', 'Vereinzelte wolken'],
    brokenClouds: ['Nubes rotas', 'Broken clouds', 'Zerrissene wolken'],
    showerRain: ['Lluvias y chubascos', 'Shower rain', 'Dusche regen'],
    lightRain: ['Lluvia ligera', 'Light rain', 'Leichter regen'],
    rain: ['Lluvia', 'Rain', 'Regen'],
    thunderstorm: ['Tormenta eléctrica', 'Thunderstorm', 'Gewittersturm'],
    snow: ['Nieve', 'Snow', 'Schnee'],
    mist: ['Niebla', 'Mist', 'Nebel']
  },
  reportCalibration: {
    reportCoverPageTitle: ['¿En qué consisten?', 'What is it?', 'Was ist das?'],
    reportCoverPage: [
      'El "Estado de Calibración de Dispositivos" es un documento que permite verificar el estado de calibración de los sensores. ​Incluye una breve descripción de los ajustes realizados para garantizar la fiabilidad de los datos, así como una lista con los dispositivos y su estado de calibración, verificado en el momento de la descarga.​',
      'The "Device Calibration Status" is a document that allows verifying the calibration status of sensors. It includes a brief description of the adjustments made to ensure data reliability, as well as a list of devices and their calibration status, verified at the time of download.',
      'Der Kalibrierungsstatusbericht ist ein Dokument, mit dem der Kalibrierungsstatus von Sensoren überprüft werden kann. Er enthält eine kurze Beschreibung der Anpassungen, die vorgenommen wurden, um die Zuverlässigkeit der Daten zu gewährleisten, sowie eine Liste der Geräte und ihres Kalibrierungsstatus, der zum Zeitpunkt des Downloads überprüft wurde.'
    ],
    reportSecondPageTitle: [
      'Informe de Estado de Calibración',
      'Calibration Status Report',
      'Kalibrierungsstatusbericht'
    ],
    reportSecondPage: [
      'Este apartado incorpora una breve descripción de los parámetros, su calibración de fábrica y de los ajustes llevados a cabo para la corrección de posibles derivas en los sensores.',
      'This section includes a brief description of the parameters, their factory calibration, and the adjustments made to correct possible sensor drifts.',
      'Dieser Abschnitt enthält eine kurze Beschreibung der Parameter, ihrer Werkskalibrierung und der Anpassungen, die vorgenommen wurden, um mögliche Abweichungen der Sensoren zu korrigieren.'
    ],
    reportThirdPageTitle: ['Resumen de dispositivos', 'Devices Summary', 'Geräte Zusammenfassung'],
    reportThirdPage: [
      'En esta sección, encontrarás una lista de los dispositivos incluidos en el informe, junto con la fecha de fabricación, la fecha de validez de la vida útil, la fecha de última calibración y el estado de calibración. Además, se incluirá una lista separada y más detallada de los dispositivos que no cumplen con los requisitos de calibración, para permitir al usuario identificarlos fácilmente y tomar medidas para corregirlos.',
      'In this section, you will find a list of the devices included in the report, along with their manufacturing date, validity of the lifespan, last calibration date, and calibration status. Additionally, a separate and more detailed list of devices that do not meet calibration requirements will be provided, allowing the user to easily identify them and take corrective actions.',
      'In diesem Abschnitt finden Sie eine Liste der in den Bericht aufgenommenen Geräte mit ihrem Herstellungsdatum, der Gültigkeit der Lebensdauer, dem Datum der letzten Kalibrierung und dem Kalibrierungsstatus. Darüber hinaus wird eine separate und detailliertere Liste von Geräten bereitgestellt, die die Kalibrierungsanforderungen nicht erfüllen, so dass der Benutzer diese leicht identifizieren und Korrekturmaßnahmen ergreifen kann.'
    ],
    reportFourthPageTitle: [
      'Especificación de Parámetros',
      'Parameter Specifications',
      'Parameter Spezifikation '
    ],
    reportFourthPage: [
      'En este apartado se incluye un resumen de las características técnicas de cada sensor, así como su vida útil, que define el tiempo durante el cual se garantiza la fiabilidad y precisión del funcionamiento del sensor en condiciones normales de uso.',
      "This section includes a summary of the technical specifications of each sensor, as well as its lifespan, which defines the period during which the sensor's reliability and accuracy are guaranteed under normal operating conditions.",
      'Dieser Abschnitt enthält eine Zusammenfassung der technischen Spezifikationen jedes Sensors sowie seine Lebensdauer, die den Zeitraum angibt, in dem die Zuverlässigkeit und Genauigkeit des Sensors unter normalen Betriebsbedingungen gewährleistet sind.'
    ]
  },
  weatherSetting: ['CONFIGURAR', 'SETTINGS', 'EINSTELLUNGEN'],
  reportPage: {
    report: ['Informes Inteligentes', 'Smart Report', 'Intelligente Berichterstattung'],
    reportCoverPageTitle: ['¿Qué es?', 'What is it?', 'Was ist das?'],
    reportCoverPage: [
      'Los informes inteligentes son reportes que pueden descargarse de forma periódica para conocer de forma precisa y visual el estado y evolución de la calidad del aire interior. Los parámetros mostrados en cada informe dependerán del tipo de MICA y los parámetros monitorizados en cada caso. Son informes que facilitan la interpretación de los datos durante períodos de monitorización específicos y permiten identificar de forma clara las áreas de mejora para tomar medidas eficaces.',
      'Smart reports are reports that can be downloaded on a regular basis in order to know in a precise and visual way the status and evolution of the indoor air quality. The parameters shown in each report will depend on the type of MICA and the parameters monitored in each case. These reports facilitate the interpretation of the data during specific monitoring periods and allow to clearly identify areas for improvement in order to take effective measures.',
      'Intelligente Berichte sind Berichte, die regelmäßig heruntergeladen werden können, um den Status und die Entwicklung der Luftqualität in Innenräumen präzise und visuell darzustellen. Die in jedem Bericht angezeigten Parameter hängen von der Art der MICA und den jeweils überwachten Parametern ab. Diese Berichte erleichtern die Interpretation der Daten während bestimmter Überwachungszeiträume und ermöglichen es, Bereiche mit Verbesserungsbedarf klar zu identifizieren, um wirksame Maßnahmen zu ergreifen.'
    ],
    reportSecondPageTitle: ['Resumen', 'Summary', 'Samenvatting'],
    reportSecondPage: [
      'Convertir los datos en información implica definir muy bien qué interesa evaluar de cada parámetro. Por ello, la información se muestra de forma gráfica para cada parámetro y cada uno de los indicadores de calidad del aire, confort, necesidad de ventilación o propagación de virus disponibles en My inBiot.',
      'Converting data into information implies defining very well what is to be evaluated for each parameter. Therefore, the information is displayed graphically for each parameter and each of the indicators of air quality, comfort, ventilation requirements or virus propagation available in My inBiot.',
      'Het omzetten van gegevens in informatie houdt in dat zeer goed moet worden gedefinieerd wat voor elke parameter moet worden geëvalueerd. Daarom wordt de informatie voor elke parameter en elk van de in My inBiot beschikbare indicatoren voor luchtkwaliteit, comfort, ventilatiebehoeften of virusverspreiding grafisch weergegeven.'
    ],
    reportThirdPageTitle: ['Página ejemplo', 'Example page', 'Beispielseite'],
    reportThirdPage: [
      '¿Quieres valorar la información que ofrecen los Informes Inteligentes? Aquí tienes una muestra del tipo de información que se muestra sobre cada parámetro. Una página completa con información sobre los rangos alcanzados durante todo el periodo de ocupación. Y una gráfica de evaluación durante todo el periodo de monitorización.',
      'Do you want to evaluate the information offered by the Smart reports? Here is a sample of the type of information displayed for each parameter. A complete page with information on the ranges achieved during the entire occupancy period. And an evaluation graph during the entire monitoring period.',
      'Wilt u de informatie van de Smart reports evalueren? Hier volgt een voorbeeld van het type informatie dat voor elke parameter wordt weergegeven. Een volledige pagina met informatie over de bereikte bereiken gedurende de gehele bezettingsperiode. En een evaluatiegrafiek voor de gehele bewakingsperiode.'
    ],
    newReport: ['Nuevo Informe Inteligente', 'New Smart Report ', 'Neuer intelligenter Bericht']
  },
  iaqReports: ['Calidad Aire Interior', 'Indoor Air Quality', 'Luftqualität in Innenräumen'],
  calibrationReports: ['Estado de Calibración', 'Calibration Status', 'Status der Kalibrierung'],
  reportCalibrationModal: {
    regularReport: ['Reporte común', 'Regular report', 'Regelmäßiger Bericht'],
    wellReport: ['Reporte WELL', 'WELL Report', 'WELL-Bericht'],
    regularCalibration: [
      'Reporte de Estado de Caibracion común',
      'Regular Calibration Status Report',
      'Regelmäßiger Kalibrierungsstatusbericht'
    ],
    wellCalibrationText: [
      'Si elige esta opción, los dispositivos solo se considerarán calibrados si la calibración se realiza de acuerdo con los requisitos del WELL Performance Verification Guidebook.',
      'If you select this option, the devices will only be considered calibrated if calibration is performed according to WELL Performance Verification Guidebook requirements.​',
      'Wenn Sie diese Option wählen, gelten die Geräte nur dann als kalibriert, wenn die Kalibrierung gemäß den Anforderungen des WELL Performance Verification Guidebook durchgeführt wird.'
    ],
    regularCalibrationText: [
      'Si elige esta opción, los dispositivos están calibrados si se encuentran dentro de su vida útil. Durante este período, se espera que el sensor opere de manera confiable y precisa bajo condiciones normales.',
      'If you select this option, the devices are calibrated if they are within their lifespan. During this period, the sensor is expected to operate reliably and accurately under normal conditions.​',
      'Wenn Sie diese Option wählen, werden die Geräte kalibriert, wenn sie sich innerhalb ihrer Lebensdauer befinden. Während dieses Zeitraums wird erwartet, dass der Sensor unter normalen Bedingungen zuverlässig und genau arbeitet.'
    ],
    wellCalibration: [
      'Reporte de Estado de Caibracion WELL',
      'WELL Calibration Status Report',
      'WELL-Kalibrierungsstatusbericht'
    ],
    reportType: ['Seleccionar el tipo de informe', 'Select report type', 'Berichtstyp auswählen'],
    selectDevices: [
      'Seleccionar dispositivos (Max. 50)',
      'Select devices (Max. 50)',
      'Geräte auswählen (Max. 50)'
    ],
    selectedDevice: ['Dispositivos Seleccionados', 'Selected Devices', 'Ausgewählte Geräte'],
    deviceNumber: [
      'Los informes de estado de calibración solo se pueden generar para un máximo de 50 dispositivos. Si deseas saber el estado de calibración de mas dispositivos, deberás generar más de un informe.',
      'Calibration Status Reports can only be generated for a maximum of 50 devices. If you want to check the calibration status of more devices, you will have to generate multiple reports.',
      'Kalibrierungsstatusberichte können nur für maximal 50 Geräte erstellt werden. Wenn Sie den Kalibrierungsstatus von mehr Geräten überprüfen möchten, müssen Sie mehrere Berichte erstellen.'
    ],
    newReport: [
      'Nuevo Informe de Estado de Calibración',
      'New Calibration Status Report',
      'Neuer Kalibrierungsstatusbericht'
    ],
    navDevices: ['1. Dispositivos', '1. Devices', '1. Geräte'],
    navDownload: ['2. Descargar', '2. Download', '2. Herunterladen']
  },
  addReportModal: {
    rangeTimeDisponible: [
      'Rango de tiempo disponible',
      'Available time range',
      'Verfügbare Zeitspanne'
    ],
    rangeTimeInfo: [
      'El número de dispositivos seleccionado afectará al rango de tiempo disponible analizado en el reporte. Para ampliar el rango de tiempo seleccione menos dispositivos.',
      'The number of devices selected will affect the available time range analyzed in the report. To extend the time range select fewer devices.',
      'Die Anzahl der ausgewählten Geräte wirkt sich auf die im Bericht analysierte verfügbare Zeitspanne aus. Um den Zeitbereich zu erweitern, wählen Sie weniger Geräte aus.'
    ],

    selectedDevice: ['Dispositivos seleccionados', 'Selected devices', 'Ausgewählte Geräte'],
    deviceNumber: [
      'El número de dispositivos seleccionado afectará al rango de tiempo disponible analizado en el reporte.',
      'The number of devices selected will affect the range of available time analyzed in the report.',
      'Die Anzahl der ausgewählten Geräte wirkt sich auf den Bereich der verfügbaren Zeit aus, der im Bericht analysiert wird.'
    ],
    navDevices: ['1. Dispositivos', '1. Devices', '1. Geräte'],
    navParams: ['2. Parámetros', '2. Parameters', '2. Parameter'],
    navInterval: ['3. Intervalo', '3. Interval', '2. Intervall'],
    navOccupation: ['4. Ocupación', '4. Occupancy', '4. Bezetting'],
    next: ['Siguiente', 'Next', 'Nächste'],
    back: ['Atrás', 'Back', 'Zurück'],
    cancel: ['Cancelar', 'Cancel', 'Stornieren'],
    location: ['Ubicación', 'Location', 'Locatie'],
    newReport: [
      'Nuevo Informe de Calidad de Aire',
      'New Air Quality Report',
      'Neuer Bericht zur Luftqualität'
    ],
    reportName: [
      'Nombre del informe inteligente',
      'Smart Report Name',
      'Intelligenter Bericht Name'
    ],
    selectAll: ['Seleccionar todos', 'Select all', 'Alle auswählen'],
    selectParameters: [
      'Selecciona los parámetros que quieres visualizar en el informe:',
      'Select the parameters you want to display in the report:',
      'Selecteer de parameters die u in het rapport wilt weergeven:'
    ],
    selectDateInterval: [
      'Seleccionar intervalo de fechas',
      'Select date range',
      'Datumsbereich auswählen'
    ],
    startDate: ['Fecha de inicio', 'Start date', 'Startdatum'],
    endDate: ['Fecha final', 'End date', 'Endtermin'],
    occupationInterval: ['Intervalo de ocupación ', 'Occupation interval ', 'Bezetting interval'],
    occupationDay: ['Días de ocupación', 'Occupancy days', 'Bezettingsdagen'],
    occupationSchedule: ['Horario de ocupación', 'Occupancy schedule', 'Uren van bezetting'],
    firstOccupationHours: ['Franja 1', 'Time frame 1', 'Freistaat 1'],
    secondOccupationHours: ['Franja 2', 'Time frame 2', 'Freistaat 2'],
    monday: ['Lu', 'Mon', 'Mo'],
    tuesday: ['Ma', 'Tue', 'Di'],
    wednesday: ['Mi', 'Wed', 'Mi'],
    thursday: ['Ju', 'Thu', 'Do'],
    friday: ['Vi', 'Fri', 'Fr'],
    saturday: ['Sa', 'Sat', 'Sa'],
    sunday: ['Do', 'Sun', 'So'],
    downloadReport: ['Descargar informe', 'Download report', 'rapport downloaden']
  },
  off: ['OFF', 'OFF', 'OFF'],
  selectLevel: ['Seleccionar Nivel', 'Select Level', 'Niveau auswählen'],
  alertsPage: {
    devicesName: ['devices name', 'nombre de los dispositivos', 'Name der Geräte'],
    emailreceiver: [
      'Indique quienes recibirán estas alertas',
      'Indicate who will receive these alerts',
      'Geben Sie an, wer diese Warnmeldungen erhalten soll'
    ],
    alarmsName: ['Nombre de la alarma', 'Alarm name', 'Alarm-Name'],
    savedAlerts: ['Alertas guardadas', 'Saved alerts', 'Gespeicherte Warnungen'],
    newAlert: ['Nueva alerta', 'New alert', 'Neue Warnungen'],
    newFem: ['Nueva', 'New', 'Neue'],
    alert: ['Alerta', 'Alert', 'Alarm'],
    searchAlerts: ['Buscar alerta', 'Search alert', 'Suchmeldung'],
    editAlert: ['Editar alerta', 'Edit alert', 'Alarm bearbeiten'],
    deleteAlert: ['Borrar alerta', 'Delete alert', 'Alarm löschen'],
    alarmName: ['Nombrar la alarma', 'Alert name', 'Alarmname'],
    inactivityAlarms: ['Alertas de inactividad', 'Inactivity alerts', 'Inaktivitätswarnungen'],
    parameterAlarms: ['Alertas de parametros', 'Parameters alerts', 'Parameterwarnungen'],
    deviceOrGroupName: ['Nombre del dispositivo', 'Device Name', 'Gerätename'],
    addEmail: ['Añadir nuevo correo', 'Add new email', 'Neue email hinzufügen'],
    searchEmail: ['Buscar correo', 'Search email', 'Suche email']
  },
  information: ['Información', 'Information', 'Informationen'],
  manageUser: ['Gestión usuarios', 'Users management', 'Benutzerverwaltung'],
  MyAccesses: ['Mis accesos', 'My Accesses', 'Mein Zugang'],
  controlAccess: [
    'Controle el acceso de otros usuarios al panel de control de sus dispositivos MICA.',
    "Control other users' access to the control panel of your MICA devices.",
    'Kontrollieren Sie den Zugriff anderer Benutzer auf das Bedienfeld Ihrer MICA-Geräte.'
  ],
  controlCollaboratorAccess: [
    'Controle las cuentas a las que tiene acceso como colaborador.',
    'Control the accounts to which you have access as a collaborator.',
    'Kontrolle der Konten, auf die Sie als Mitarbeiter Zugriff haben.'
  ],
  myRole: ['Mi rol: ', 'My role: ', 'Meine Rolle: '],
  existingAccesses: ['Accesos existentes', 'Existing accesses', 'Bestehende Zugänge'],
  existingUsers: ['Usuarios existentes', 'Existing users', 'Bestehende Nutzer'],
  addUsers: ['Añadir', 'Add', 'Hinzufügen'],
  addUserCondition: [
    'Al añadir un usuario nuevo, se creará una cuenta en My inBiot automáticamente (si no está creada). La información de inicio de sesión le llegará al correo al usuario.',
    'When adding a new user, a My inBiot account will be created automatically. The login information will be emailed to the user.',
    ''
  ],
  devices: ['DISPOSITIVOS', 'DEVICES', 'GERÄTE'],
  device: ['DISPOSITIVO', 'DEVICE', 'GERÄTE'],
  data: ['DATOS', 'DATA', 'DATEN'],
  addNewUser: ['Añadir nuevo usuario', 'Add new user', 'Neuen Benutzer hinzufügen'],
  editUser: ['Editar usuario', 'Edit user', 'Benutzer bearbeiten'],
  deleteUser: ['Eliminar usuario', 'Delete user', 'Benutzer löschen'],
  certificationsPage: {
    resetCertification: ['Certificación RESET', 'RESET Certification', 'RESET-Zertifizierung'],
    version41: ['Versión 4.1', 'Version 4.1', 'Version 4.1'],
    version5: ['Versión 5', 'Version 5', 'Version 5'],
    titleMore: ['Más Información', 'More Information', 'Weitere Informationen'],
    certificationMain: [
      'Conoce nuestro servicio de cumplimiento de diferentes certificaciones ambientales a partir de nuestras soluciones de monitorización de la calidad del aire interior.',
      'Learn about our service for compliance with different environmental certifications from our indoor air quality monitoring solutions.',
      'Informieren Sie sich über unseren Service zur Einhaltung verschiedener Umweltzertifizierungen auf der Grundlage unserer Lösungen zur Überwachung der Innenraumluftqualität.'
    ],
    Seleccionar: ['Seleccionar dispositivo', 'Select device', 'Gerät auswählen'],
    edit: ['Editar', 'Edit', 'bearbeiten'],
    temperatureInfo: [
      'Para cumplir con la certificación RESET la temperatura  debe ser monitorizada diariamente.',
      'To comply with RESET certification the temperature must be monitored daily.',
      'Um die RESET-Zertifizierung zu erhalten, muss die Temperatur täglich überwacht werden.'
    ],
    humidityInfo: [
      'Para cumplir con la certificación RESET la humedad relativa  debe ser monitorizada diariamente.',
      'To comply with RESET certification the relative humidity must be monitored daily.',
      'Um die RESET-Zertifizierung zu erfüllen, muss die relative Luftfeuchtigkeit täglich überwacht werden.'
    ],
    pm25Info: [
      'Para cumplir con la certificación RESET la media diaria de PM 2.5  durante el horario de ocupación debe ser menor a 35 µg/m³ (< 12 µg/m³ para High Performance).',
      'To comply with RESET certification the daily average PM 2.5 during the hours of occupancy must be less than 35 µg/m³ (< 12 µg/m³ for High Performance).',
      'Um die RESET-Zertifizierung zu erhalten, muss der Tagesmittelwert von PM 2,5 während der Belegungszeiten unter 35 µg/m³ (< 12 µg/m³ für High Performance) liegen.'
    ],
    pm25InfoShell: [
      'Para cumplir con la certificación RESET la media diaria de PM 2.5  durante el horario de ocupación debe ser menor o igual a 12 µg/m³ (o Reduccón ≥ 75% ).',
      'To comply with RESET certification the daily average PM 2.5 during the hours of occupancy must be less than or equal to 12 µg/m³ (or Reduction ≥ 75% ).',
      'Um die RESET-Zertifizierung zu erhalten, muss der Tagesmittelwert von PM 2,5 während der Belegungszeiten kleiner oder gleich 12 µg/m³ (oder Ermäßigung ≥ 75% ) sein.'
    ],
    co2Info: [
      'Para cumplir con la certificación RESET la media diaria de CO₂  durante el horario de ocupación debe ser menor a 1000 ppm (< 600 ppm para High Performance).',
      'To comply with RESET certification the daily average CO₂ during the hours of occupancy must be less than 1000 ppm (< 600 ppm for High Performance).',
      'Um die RESET-Zertifizierung zu erhalten, muss der Tagesdurchschnitt an CO₂ während der Belegungszeiten unter 1000 ppm (< 600 ppm für High Performance) liegen.'
    ],
    co2InfoShell: [
      'Para cumplir con la certificación RESET la media diaria de CO₂ durante el horario de ocupación debe ser menor a 800 ppm.',
      'To comply with RESET certification, the daily average of CO₂ during the hours of occupancy must be less than 800 ppm.',
      'Um die RESET-Zertifizierung zu erhalten, muss der Tagesdurchschnitt des CO₂ während der Belegungszeiten unter 800 ppm liegen.'
    ],
    vocsInfo: [
      'Para cumplir con la certificación RESET la media diaria de TVOC  durante el horario de ocupación debe ser menor a 500 µg/m³ (< 400 µg/m³ para High Performance).',
      'To comply with RESET certification the daily average TVOC during the hours of occupancy must be less than 500 µg/m³ (< 400 µg/m³ for High Performance).',
      'Um die RESET-Zertifizierung zu erhalten, muss der Tagesdurchschnitt der TVOC während der Belegungszeiten unter 500 µg/m³ (< 400 µg/m³ für High Performance) liegen.'
    ],
    vocsInfoShell: [
      'Para cumplir con la certificación RESET la media diaria de TVOC  durante el horario de ocupación debe ser menor a 400 µg/m³.',
      'To comply with RESET certification, the daily average TVOC during the hours of occupancy must be less than 400 µg/m³.',
      'Um die RESET-Zertifizierung zu erhalten, muss der Tagesmittelwert von TVOC während der Belegungszeiten unter 400 µg/m³ liegen.'
    ],
    noData: [
      'No se encontraron datos, compruebe que el dispositivo está conectado.',
      'No data found, check if the device is connected.',
      'Keine Daten gefunden, überprüfen Sie, ob das Gerät angeschlossen ist.'
    ],
    micaLiteVocs: [
      'Los dispositivos MICA Lite no incluyen sensor de TVOC.',
      'MICA Lite devices do not include a TVOC sensor.',
      'Die MICA Lite-Geräte enthalten keinen TVOC-Sensor.'
    ],
    resetComercial: ['Commercial Interiors', 'Commercial Interiors', 'Commercial Interiors'],
    selectDevice: [
      'Seleccione un dispositivo e indique el horario de ocupación para ver información en tiempo real',
      'Select a device and enter the hours of occupancy to view real-time information',
      'Wählen Sie ein Gerät aus und geben Sie die Belegungszeiten ein, um Echtzeitinformationen anzuzeigen'
    ],
    secondBand: ['Franja horaria 2 ', 'Time band 2 ', 'Zeitfenster 2 '],
    change: ['APLICAR', 'APPLY', 'APPLY'],
    occupancyBand: ['Franja de ocupación', 'Occupancy band', 'Belegungsband'],
    firstBand: ['Franja horaria 1', 'Time band 1', 'Zeitfenster 1'],

    occupancyBandInfo: [
      'Período de tiempo de la semana en que el espacio se encuentra en uso (ocupado). Si la ocupación responde a una jornada partida o intermitente, utilice la franja horaria 2 para recoger con mayor precisión el horario de ocupación',
      'Time period of the week when the space is in use (occupied). If occupancy is on a split or intermittent basis, use time slot 2 to more accurately capture the hours of occupancy.',
      'Zeitraum in der Woche, in dem der Raum genutzt wird (belegt ist). Bei geteilter oder intermittierender Belegung ist Zeitfenster 2 zu verwenden, um die Belegungszeiten genauer zu erfassen.'
    ],

    monday: ['Lu', 'Mon', 'Mo'],
    tuesday: ['Ma', 'Tue', 'Di'],
    wednesday: ['Mi', 'Wed', 'Mi'],
    thursday: ['Ju', 'Thu', 'Do'],
    friday: ['Vi', 'Fri', 'Fr'],
    saturday: ['Sa', 'Sat', 'Sa'],
    sunday: ['Do', 'Sun', 'So'],
    occupancyDays: ['Días de ocupación', 'Occupancy days', 'Belegungstage'],
    start: ['Inicio', 'Start', 'Startseite'],
    end: ['Fin', 'End', 'Ende'],
    dateOcuppancy: ['Fechas de ocupación', 'Occupancy dates', 'Daten der Beschäftigung'],
    device: ['Dispositivo', 'Device', 'Gerät'],
    occupancyTitle: ['Media diaria', 'Daily average', 'Täglicher Durchschnitt'],
    occupancyInfo: [
      'Estos valores hacen referencia a la media diaria durante ocupación de los últimos 30 días naturales.',
      'These values refer to the daily average during occupancy for the last 30 calendar days.',
      'Diese Werte beziehen sich auf den Tagesdurchschnitt während der Belegung in den letzten 30 Kalendertagen.'
    ],
    occupancyInfo1: [
      'Para cumplir con la certificación RESET hay que garantizar que la media diaria de las medidas se encuentran dentro del rango permitido durante el horario de ocupación del espacio.',
      'To comply with RESET certification, it must be ensured that the daily average of the measurements are within the permitted range during the hours of occupancy of the space.',
      'Um die RESET-Zertifizierung zu erhalten, muss sichergestellt werden, dass der Tagesdurchschnitt der Messungen während der Belegungszeiten des Raumes innerhalb des zulässigen Bereichs liegt.'
    ],
    resetTitleImageHeader1: [
      'Parámetros y requisitos para proyectos RESET “Commercial Interiors”',
      'Parameters and requirements for RESET "Commercial Interiors" projects',
      'Parameter und Anforderungen für RESET "Commercial Interiors"-Projekte'
    ],
    RealTimeTitle: [
      'Media diaria (últimos 30 días)',
      'Daily average (last 30 days)',
      'Tagesdurchschnitt (letzte 30 Tage)'
    ],
    realTime: ['Tiempo Real', 'Real Time', 'Echtzeit'],
    ResetInformationIAQ: [
      'El algoritmo de análisis de datos de RESET compila las medias diarias calculadas a partir de las horas de ocupación y las compara con​ los límites de calidad del aire interior de los objetivos de calidad del aire interior de RESET™ Air for Commercial Interiors o RESET™ Air Core &Shell. Los datos son enviados vía API con la RESET Assessment Cloud.​',
      "RESET's data analysis algorithm compiles daily averages calculated from occupancy hours and compares them to the indoor air quality limits of the RESET™ Air for Commercial Interiors or RESET™ Air Core &Shell indoor air quality objectives. The data is sent via API with the RESET Assessment Cloud.",
      'Der Datenanalysealgorithmus von RESET stellt Tagesmittelwerte zusammen, die aus Belegungsstunden berechnet werden, und vergleicht sie mit den Innenraumluftqualitätsgrenzwerten der RESET™ Air for Commercial Interiors oder RESET™ Air Core &Shell Innenraumluftqualitätsziele. Die Daten werden über API an die RESET Assessment Cloud gesendet.'
    ],
    ResetInformationIAQ1: [
      'Aquí puedes revisar los requisitos de RESET en cuanto a monitorización de la calidad del aire interior.',
      "You can review RESET's indoor air quality monitoring requirements here.",
      'Hier können Sie die Anforderungen von RESET für die Überwachung der Innenraumluftqualität überprüfen.'
    ],
    visitSite: ['VISITAR SITIO', 'VISIT SITE', 'SITE BESUCHEN'],
    micaResetInformation: [
      'MICA cuenta con la acreditación RESET de Grado B y cumple con todos los requisitos establecidos.',
      'MICA has RESET Grade B accreditation and complies with all established requirements.',
      'Die MICA ist nach RESET akkreditiert und erfüllt alle festgelegten Anforderungen.'
    ],
    micaResetInformation1: [
      'Revisa aquí los requisitos específicos solicitados por RESET para monitores de calidad del aire interior',
      'Check here the specific requirements requested by RESET for indoor air quality monitors.',
      'Prüfen Sie hier die von RESET geforderten spezifischen Anforderungen für die Überwachung der Luftqualität in Innenräumen.'
    ],
    dataResetTitle: ['Dispositivos MICA', 'MICA devices', 'MICA-Geräte'],
    dataResetTitle2: ['Monitorización IAQ', 'IAQ monitoring', 'IAQ-Überwachung'],
    certifications: ['Certificaciones', 'Certifications', 'Zertifizierungen'],
    information: ['Información', 'Information', 'Informationen'],
    reports: ['Reportes', 'Reports', 'Berichte'],
    justification: ['Justificación', 'Justification', 'Rechtfertigung'],
    wellStandard: ['WELL Standard', 'WELL Standard', 'WELL Standard'],

    wellPerformanceRating: [
      'WELL Performance Rating',
      'WELL Performance Rating',
      'WELL Performance Rating'
    ],
    reset: ['RESET', 'RESET', 'RESET'],
    leed: ['LEED', 'LEED', 'LEED'],
    leedBdTitle: [
      'Parámetros y requisitos para proyectos LEED BD+C ID+C ',
      'Parameters and Requirements for LEED BD+C ID+C Projects',
      'Parameter und Anforderungen für LEED BD+C ID+C-Projekte'
    ],
    leedV4omTitle: [
      'Parámetros y requisitos para proyectos O+M',
      'Parameters and requirements for O+M projects',
      'Parameter und Anforderungen für O+M-Projekte'
    ],
    leedtextV4OM: [
      'Dentro de la categoría de Calidad de Ambiente Interior (EQ) de LEED, la monitorización de la calidad del aire puede ayudar a obtener puntos en distintos apartados.',
      "Within LEED's Indoor Environmental Quality (EQ) category, air quality monitoring can help score points under several different headings.",
      'Im Rahmen der LEED-Kategorie Innenraumqualität (EQ) kann die Überwachung der Luftqualität dazu beitragen, in verschiedenen Bereichen Punkte zu erzielen.'
    ],
    leedV5omTitle: [
      'Parámetros y requisitos para proyectos LEED O+M (Operations and Maintenance)',
      'Parameters and requirements for LEED O+M (Operations and Maintenance) projects',
      'Parameter und Anforderungen für LEED O+M (Betrieb und Instandhaltung) Projekte'
    ],
    leedtextV5OM: [
      'Las soluciones de inBiot ayudan a obtener puntos para la certificación LEED en proyectos O+M, a través del crédito de rendimiento de calidad de aire interior (Indoor Air Quality Performance) de la categoría Calidad de Ambiente Interior (EQ), y más en concreto, a través de la opción de medición de aire interior (Option 1. Measure Indoor Air (1 – 10 points)). De esta manera, los proyectos con soluciones inBiot pueden sumar hasta 10 puntos adicionales. La monitorización puede dar hasta 6 puntos. En caso de que se cumplan los umbrales establecidos, los puntos obtenidos pueden llegar a ser hasta 10. El periodo de muestreo debe ser como mucho de 15 minutos para el CO₂ y de 1 hora para el resto de parámetros. ',
      'inBiot solutions help to earn points towards LEED certification for O+M projects through the Indoor Air Quality Performance credit in the Indoor Environment Quality (EQ) category, and more specifically through the Measure Indoor Air option (Option 1. Measure Indoor Air (1 - 10 points)). In this way, projects with inBiot solutions can score up to 10 additional points. Monitoring can score up to 6 points. In case the established thresholds are met, the points obtained can be up to 10 points. The sampling period should be at most 15 minutes for CO₂ and 1 hour for all other parameters.',
      'Die Lösungen von inBiot helfen dabei, LEED-Zertifizierungspunkte für O+M-Projekte durch den Indoor Air Quality Performance Credit in der Kategorie Indoor Environment Quality (EQ) zu erhalten, und zwar durch die Option Measure Indoor Air (Option 1. Measure Indoor Air (1 - 10 points)). Auf diese Weise können Projekte mit inBiot-Lösungen bis zu 10 zusätzliche Punkte erzielen. Das Monitoring kann bis zu 6 Punkte erreichen. Werden die festgelegten Schwellenwerte eingehalten, können bis zu 10 Punkte erreicht werden. Die Dauer der Probenahme sollte für CO₂ höchstens 15 Minuten und für alle anderen Parameter höchstens 1 Stunde betragen.'
    ],
    leedV5FootNote: [
      '* Los puntos para cada parámetro pueden obtenerse por monitorización y/o por cumplir los umbrales.',
      '* Points can be earned for each parameter by monitoring and/or meeting thresholds.',
      '* Punkte für jeden Parameter können durch Überwachung und/oder Einhaltung von Schwellenwerten erreicht werden.'
    ],
    wellStandardInformationText1: [
      'Es una certificación centrada en la salud y confort de las personas usuarias, basada en el rendimiento del edificio y no tanto en la prescripción de soluciones específicas.',
      'It is a certification focused on the health and comfort of the users, based on the performance of the building and not so much on the prescription of specific solutions.',
      'Es handelt sich um eine Zertifizierung, bei der die Gesundheit und der Komfort der Nutzer im Mittelpunkt stehen und die sich auf die Leistung des Gebäudes und nicht so sehr auf die Vorgabe spezifischer Lösungen stützt.'
    ],
    wellStandardInformationText2: [
      'Trabaja desde 10 conceptos clave que aportan conocimiento y métricas suficientes bajo un trabajo de certificación holístico e integral - aire, agua, alimentación, iluminación, movimiento, confort térmico, sonido, materiales, mente y comunidad.',
      'It works from 10 key concepts that provide sufficient knowledge and metrics under a holistic and integrated certification work - air, water, food, lighting, movement, thermal comfort, sound, materials, mind and community.',
      'Es geht von 10 Schlüsselkonzepten aus, die im Rahmen einer ganzheitlichen und integrierten Zertifizierungsarbeit ausreichende Kenntnisse und Messgrößen liefern - Luft, Wasser, Lebensmittel, Beleuchtung, Bewegung, thermischer Komfort, Schall, Materialien, Geist und Gemeinschaft.'
    ],
    wellStandardInformationText3: [
      'De cada concepto, algunos requerimientos son obligatorios (precondition) y otros permiten sumar puntos voluntarios adicionales ("optimization"), con un mínimo de 40 puntos para alcanzar la certificación.',
      'For each concept, some requirements are mandatory (precondition) and others allow for additional voluntary points ("optimisation"), with a minimum of 40 points to achieve certification.',
      'Für jedes Konzept sind einige Anforderungen obligatorisch (precondition) und andere erlauben zusätzliche freiwillige Punkte ("optimization"), wobei mindestens 40 Punkte erreicht werden müssen, um eine Zertifizierung zu erhalten.'
    ],
    wellStandardInformationText4: [
      'Debido al enorme impacto de la calidad del aire en nuestra salud, el aire figura en primer lugar entre los conceptos clave que WELL pretende mejorar. WELL promueve la adecuada calidad del aire interior fomentado la verificación de niveles adecuados, la monitorización y diversos métodos de mejora: la eliminación y reducción de las fuentes de contaminantes, la intervención en los comportamientos que empeoran la calidad del aire y un énfasis especial en la implantación de sistemas de control de la calidad del aire interior.',
      'Because of the enormous impact of air quality on our health, air is at the top of the list of key concepts that WELL aims to improve. WELL promotes adequate indoor air quality by encouraging the verification of adequate levels, monitoring and various methods of improvement: the elimination and reduction of pollutant sources, intervention in behaviours that worsen air quality and a special emphasis on the implementation of indoor air quality control systems.',
      'Aufgrund der enormen Auswirkungen der Luftqualität auf unsere Gesundheit steht die Luft ganz oben auf der Liste der Schlüsselkonzepte, die mit WELL verbessert werden sollen. WELL fördert eine angemessene Luftqualität in Innenräumen, indem es die Überprüfung angemessener Werte, die Überwachung und verschiedene Methoden zur Verbesserung fördert: die Beseitigung und Verringerung von Schadstoffquellen, das Eingreifen in Verhaltensweisen, die die Luftqualität verschlechtern, und einen besonderen Schwerpunkt auf die Implementierung von Systemen zur Kontrolle der Luftqualität in Innenräumen.'
    ],
    wellStandardImageHeader1: [
      'Las soluciones de inBiot están diseñadas para ayudar a garantizar los requisitos obligatorios A01, A03 y T01, así como a ganar puntos adicionales a través de A05, A06, A08, T06 y T07 para alcanzar una mejor puntuación global en la certificación WELL.',
      "inBiot's solutions are designed to help ensure mandatory requirements A01, A03 and T01, as well as gain additional points through A05, A06, A08, T06 and T07 to achieve a better overall score in WELL certification.",
      'Die Lösungen von inBiot sind so konzipiert, dass sie dazu beitragen, die obligatorischen Anforderungen A01, A03 und T01 zu erfüllen sowie zusätzliche Punkte durch A05, A06, A08, T06 und T07 zu erzielen, um eine bessere Gesamtpunktzahl bei der WELL-Zertifizierung zu erreichen.'
    ],
    wellStandardImageHeader2: [
      'La precisión, rango y tecnología de los sensores de MICA también satisface los requisitos de WELL – recogidos en la siguiente tabla y basados en el Performance Verification Guidebook:',
      'The accuracy, range and technology of MICA sensors also meet WELL requirements - listed in the table below and based on the Performance Verification Guidebook:',
      'Die Genauigkeit, die Reichweite und die Technologie der MICA-Sensoren erfüllen auch die WELL-Anforderungen, die in der nachstehenden Tabelle aufgeführt sind und auf dem Leitfaden für die Leistungsüberprüfung basieren:'
    ],
    wellPerformanceRatingInformationText1: [
      'Es una certificación basada en la certificación WELL que se caracteriza por la utilización de los datos del comportamiento del edificio y de la experiencia de los usuarios para ayudar a mejorar el ambiente interior de los edificios desde el enfoque de su salud.​​',
      'It is a certification based on the WELL certification that is characterized by the use of building performance data and user experience to help improve the indoor environment of buildings from a health perspective.',
      'Es handelt sich um eine Zertifizierung auf der Grundlage der WELL-Zertifizierung, die sich durch die Verwendung von Gebäudeleistungsdaten und Nutzererfahrungen auszeichnet, um das Innenraumklima von Gebäuden unter gesundheitlichen Gesichtspunkten zu verbessern.'
    ],
    wellPerformanceRatingInformationText2: [
      'Es posible alcanzar esta certificación como proceso independiente o como un hito en el camino hacia la Certificación WELL v2.​',
      'It is possible to achieve this certification as a stand-alone process or as a milestone on the way to WELL v2 Certification.',
      'Es ist möglich, diese Zertifizierung als eigenständigen Prozess oder als Meilenstein auf dem Weg zur WELL v2-Zertifizierung zu erreichen.'
    ],
    wellPerformanceRatingInformationText3: [
      'WELL PERFORMANCE RATING ofrece una solución eficaz para controlar o alcanzar umbrales de rendimiento específicos relacionados con la calidad ambiental interior (IEQ) en los conceptos de calidad del aire interior, gestión de la calidad del agua, mediciones de iluminación, condiciones térmicas, rendimiento acústico, control ambiental y experiencia de los ocupantes.​La certificación incluye 33 features agrupadas en estas 7 categorías, siendo la de Calidad del aire la que más contiene, con un total de 9 features. Todos los proyectos deben cumplir con un mínimo de 21 features para conseguir la calificación WELL Performance Rating​.',
      'WELL PERFORMANCE RATING offers an effective solution for monitoring or achieving specific performance thresholds related to indoor environmental quality (IEQ) in the concepts of indoor air quality, water quality management, lighting measurements, thermal conditions, acoustic performance, environmental control and occupant experience. The certification includes 33 features grouped into these 7 categories, with Air Quality containing the most, with a total of 9 features. All projects must comply with a minimum of 21 features to achieve the WELL Performance Rating.',
      'Das WELL PERFORMANCE RATING bietet eine wirksame Lösung zur Kontrolle oder zum Erreichen bestimmter Leistungsschwellen in Bezug auf die Umweltqualität in Innenräumen (IEQ) in den Bereichen Luftqualität in Innenräumen, Wasserqualität, Beleuchtungsmessungen, thermische Bedingungen, akustische Leistung, Umweltkontrolle und Erfahrung der Bewohner. Die Zertifizierung umfasst 33 Merkmale, die in diese 7 Kategorien eingeteilt sind, wobei die Luftqualität mit insgesamt 9 Merkmalen die meisten Merkmale enthält. Alle Projekte müssen mindestens 21 Merkmale erfüllen, um das WELL Performance Rating zu erhalten.'
    ],
    wellPerformanceRatingImageHeader1: [
      'Los dispositivos MICA pueden ayudar a cumplir hasta 12 de los 21 puntos requeridos para la certificación de Calificación de Rendimiento WELL, incluyendo 8 puntos en la categoría de "Calidad del Aire Interior", 3 puntos adicionales en la categoría de "Monitoreo Ambiental" y 1 punto en "Condiciones Térmicas".',
      'MICA devices can help meet up to 12 of the 21 points required for WELL Performance Rating certification, including 8 points in the "Indoor Air Quality" category, 3 additional points in the "Environmental Monitoring" category and 1 point in "Thermal Conditions".',
      'MICA-Geräte können dazu beitragen, bis zu 12 der 21 Punkte zu erreichen, die für eine WELL Performance Rating-Zertifizierung erforderlich sind, darunter 8 Punkte in der Kategorie "Raumluftqualität", 3 zusätzliche Punkte in der Kategorie "Umweltüberwachung" und 1 Punkt in der Kategorie "Thermische Bedingungen".'
    ],
    leedImageHeader1: [
      'Dentro de la categoría de Calidad de Ambiente Interior (EQ) de LEED, la monitorización de la calidad del aire puede ayudar a obtener puntos en distintos apartados​.',
      "Within LEED's Indoor Environmental Quality (EQ) category, air quality monitoring can help score points under several different headings.",
      'Im Rahmen der LEED-Kategorie Innenraumqualität (EQ) kann die Überwachung der Luftqualität dazu beitragen, in verschiedenen Bereichen Punkte zu erzielen.'
    ],
    resetInformationText1: [
      'RESET es un estándar de certificación de edificios basado en la monitorización continua y en el rendimiento de los edificios. Es uno de los estándares de construcción más completos, con 5 certificaciones parciales modulares y permitiendo su justificación parcial. Estas certificaciones parciales se clasifican en criterios de “embodied standard” (Embodied Carbon, embodied circularity, embodied health), que incorporan datos asociados a los procesos de construcción a lo largo del ciclo de vida de un entorno construido, y los “operational data” (RESET Air, RESET Water, RESET Energy & RESET Waste), que se basan en los datos asociados a la monitorización continua del entorno construido.​',
      'RESET is a building certification standard based on continuous monitoring and performance of buildings. It is one of the most comprehensive building standards, with 5 modular partial certifications and allowing for partial justification. These partial certifications are classified into "embodied standard" criteria (Embodied Carbon, embodied circularity, embodied health), which incorporate data associated with construction processes throughout the life cycle of a built environment, and "operational data" (RESET Air, RESET Water, RESET Energy & RESET Waste), which are based on data associated with continuous monitoring of the built environment.',
      'RESET ist ein Gebäudezertifizierungsstandard, der auf der kontinuierlichen Überwachung und Leistung von Gebäuden basiert. Es handelt sich um einen der umfassendsten Gebäudestandards, der 5 modulare Teilzertifizierungen umfasst und eine teilweise Rechtfertigung zulässt. Diese Teilzertifizierungen sind in Kriterien des "verkörperten Standards" (verkörperter Kohlenstoff, verkörperte Kreislaufwirtschaft, verkörperte Gesundheit) unterteilt, die Daten im Zusammenhang mit Bauprozessen während des gesamten Lebenszyklus einer gebauten Umgebung umfassen, und in "Betriebsdaten" (RESET Luft, RESET Wasser, RESET Energie & RESET Abfall), die auf Daten im Zusammenhang mit der kontinuierlichen Überwachung der gebauten Umgebung basieren.'
    ],
    resetInformationText2: [
      'RESET Air es la certificación específica para evaluar el impacto de la calidad del aire interior en la salud, productividad y sostenibilidad en espacios interiores.',
      'RESET Air is the specific certification for assessing the impact of indoor air quality on health, productivity and sustainability in indoor spaces. ',
      'RESET Air ist die spezifische Zertifizierung für die Bewertung der Auswirkungen der Luftqualität in Innenräumen auf Gesundheit, Produktivität und Nachhaltigkeit in Innenräumen.'
    ],
    resetInformationText3: [
      'Para cumplir los requisitos de RESET, los proyectos necesitan un monitor acreditado que monitorice en continuo CO₂, PM2.5 y TVOC, además de temperatura y humedad relativa, y transmita eficazmente esa información a la nube de RESET para su análisis. Los monitores también deben mostrar fácilmente la información, se tienen que poder instalar en pared y contar con una fuente de alimentación eléctrica continua.',
      'To meet RESET requirements, projects need an accredited monitor that continuously monitors CO₂, PM2.5 and TVOC, as well as temperature and relative humidity, and efficiently transmits that information to the RESET cloud for analysis. The monitors must also display information easily, be wall-mountable and have a continuous power supply.',
      'Um die RESET-Anforderungen zu erfüllen, benötigen die Projekte ein zugelassenes Überwachungsgerät, das CO₂, PM2,5 und TVOC sowie Temperatur und relative Luftfeuchtigkeit kontinuierlich überwacht und diese Informationen zur Analyse effizient an die RESET-Cloud überträgt. Die Monitore müssen außerdem die Informationen einfach anzeigen, an der Wand montiert werden können und über eine kontinuierliche Stromversorgung verfügen.'
    ],
    LeedInformationText1: [
      'LEED (Leadership in Energy and Environmental Design) se destaca como el sistema de certificación de edificios sostenibles más ampliamente reconocido a nivel mundial. Los edificios certificados por LEED se consideran edificios saludables y energéticamente eficientes, por lo que reducen su impacto ambiental y dan lugar a beneficios a nivel social. Como la gran mayoría de certificaciones en este ámbito, ofrece un enfoque holístico que considera la sostenibilidad del edificio como el resultado de muchos elementos clave, entre los que se encuentra el Indoor Enviromental Quality (IEQ).',
      "LEED (Leadership in Energy and Environmental Design) is the world's most widely recognised sustainable building certification system. LEED-certified buildings are considered to be healthy and energy-efficient, thereby reducing their environmental impact and providing social benefits. Like the vast majority of certifications in this field, it offers a holistic approach that considers building sustainability as the result of many key elements, including Indoor Environmental Quality (IEQ).",
      'LEED (Leadership in Energy and Environmental Design) ist das weltweit anerkannteste Zertifizierungssystem für nachhaltige Gebäude. LEED-zertifizierte Gebäude werden als gesunde und energieeffiziente Gebäude angesehen, die die Umweltbelastung reduzieren und soziale Vorteile bieten. Wie die meisten Zertifizierungen in diesem Bereich bietet es einen ganzheitlichen Ansatz, der die Nachhaltigkeit von Gebäuden als Ergebnis vieler Schlüsselelemente betrachtet, einschließlich der Umweltqualität in Innenräumen (IEQ).'
    ],
    LeedInformationText2: [
      'La versión actual de LEED es la v4.1, que se distingue de la anterior por sus mejoras en los estándares de eficiencia energética, conservación del agua, selección del lugar, selección de materiales, iluminación y reducción de residuos.',
      'The current version of LEED is v4.1, which differs from the previous version with improvements in energy efficiency standards, water savings, site selection, materials selection, lighting and waste reduction.',
      'Die aktuelle LEED Version 4.1 unterscheidet sich von der Vorgängerversion durch Verbesserungen in den Bereichen Energieeffizienzstandards, Wassereinsparung, Standortwahl, Materialauswahl, Beleuchtung und Abfallreduzierung.'
    ],
    LeedInformationText3: [
      'Los proyectos de certificación LEED se distinguen en función del tipo de edificio y la fase de la edificación, incluyendo nuevas construcciones y Core & Shell (Building Design and Construction; BD+C), nuevas construcciones y diseño de interiores (Interior Design and Construction; ID+C) y proyectos en edificios ya construidos (Building Operations and Maintenance; O+M). Esta variedad de posibilidades se refleja en sistemas de certificación específicos para cada tipo de proyecto, tal y como se expone más adelante. En todos los procesos, la certificación se basa en un sistema de puntos. El mínimo de puntos necesarios para la obtención del certificado son 40, aunque se pueden obtener distintos niveles mejorados de la certificación: Silver (>50), Gold (>60) y Platinum (>80).',
      'LEED certification projects are differentiated by building type and phase, including new construction and core & shell (Building Design and Construction; BD+C), new construction and interior design (Interior Design and Construction; ID+C), and existing building projects (Building Operations and Maintenance; O+M). This diversity is reflected in specific certification schemes for each type of project, as described below. In all cases, certification is based on a points system. The minimum number of points required for certification is 40, although different levels of enhanced certification are available: Silver (>50), Gold (>60) and Platinum (>80).',
      'LEED-Zertifizierungsprojekte werden nach Gebäudetyp und Bauphase unterschieden, einschließlich Neubauten und Rohbau (Building Design and Construction, BD+C), Neubauten und Innenausbau (Interior Design and Construction, ID+C) und Projekte in bestehenden Gebäuden (Building Operations and Maintenance, O+M). Diese Vielfalt spiegelt sich in spezifischen Zertifizierungssystemen für jede Projektart wider, die im Folgenden beschrieben werden. Bei allen Verfahren basiert die Zertifizierung auf einem Punktesystem. Die Mindestpunktzahl für eine Zertifizierung liegt bei 40 Punkten, wobei es verschiedene Stufen der Zertifizierung gibt: Silber (>50), Gold (>60) und Platin (>80).'
    ],
    resetImageHeader1: [
      'RESET™ Air es una norma de construcción basada en el rendimiento de los edificios. Para que un proyecto obtenga la certificación RESET™ Air para interiores comerciales, los parámetros de calidad del aire interior, controlados mediante monitorización continua y calculados en una media diaria en función de las horas de ocupación, deben mantenerse dentro de los límites que se indican a continuación.',
      'RESET™ Air is a performance-based building standard. For a project to achieve RESET™ Air certification for commercial interiors, indoor air quality parameters, controlled by continuous monitoring and calculated on a daily average based on occupancy hours, must be maintained within the limits listed below.',
      'RESET™ Air ist ein leistungsbezogener Gebäudestandard. Damit ein Projekt die RESET™ Air-Zertifizierung für gewerbliche Innenräume erhält, müssen die Innenraumluftqualitätsparameter, die durch kontinuierliche Überwachung kontrolliert und im Tagesdurchschnitt auf der Grundlage der Belegungsstunden berechnet werden, innerhalb der unten angegebenen Grenzwerte liegen.'
    ],
    resetImageParagraph1: [
      'Los objetivos “Standard Performance” son obligatorios y todos los proyectos deben cumplir los límites indicados, mientras que los objetivos “High Performance” se indican como referencia para proyectos que persiguen objetivos de calidad del aire interior más rigurosos y/o para proyectos situados en regiones donde los niveles de calidad del aire exterior suelen mantenerse dentro de los límites recomendados para la salud.',
      'The "Standard Performance" targets are mandatory and all projects must meet the indicated limits, while the "High Performance" targets are indicated as a reference for projects pursuing more stringent indoor air quality objectives and/or for projects located in regions where outdoor air quality levels are generally maintained within recommended health limits.',
      'Die "Standard Performance"-Ziele sind obligatorisch und alle Projekte müssen die angegebenen Grenzwerte einhalten, während die "High Performance"-Ziele als Referenz für Projekte angegeben sind, die strengere Innenraumluftqualitätsziele verfolgen und/oder für Projekte in Regionen, in denen die Außenluftqualität im Allgemeinen innerhalb der empfohlenen Gesundheitsgrenzwerte gehalten wird.'
    ],
    resetCoreShellTitle: [
      'Parámetros y requisitos para proyectos RESET “Core & Shell”​',
      'Parameters and requirements for RESET "Core & Shell" projects',
      'Parameter und Anforderungen für RESET "Core & Shell" Projekte'
    ],
    resetCoreShellParagraaph: [
      'RESET™ Air for Core & Shell es una norma de construcción basada en el rendimiento de los edificios. Para que un proyecto obtenga esta certificación, los parámetros de calidad del aire interior, controlados mediante monitorización continua, deben mantenerse dentro de los límites que se indican a continuación. Las medias diarias se calculan a partir de las horas de ocupación y se comparan con los límites internacionales de calidad del aire interior.​',
      'RESET™ Air for Core & Shell is a performance-based building standard. For a project to achieve this certification, indoor air quality parameters, controlled by continuous monitoring, must be maintained within the limits listed below. Daily averages are calculated from occupancy hours and compared to international indoor air quality limits.',
      'RESET™ Air for Core & Shell ist ein leistungsbezogener Gebäudestandard. Damit ein Projekt diese Zertifizierung erhält, müssen die Innenraumluftqualitätsparameter, die durch kontinuierliche Überwachung kontrolliert werden, innerhalb der unten aufgeführten Grenzwerte gehalten werden. Die täglichen Durchschnittswerte werden anhand der Belegungszeiten berechnet und mit den internationalen Grenzwerten für die Innenraumluftqualität verglichen.'
    ],
    resetCoreShellParagraph1: [
      '* Cuando PM2.5 en el exterior es ≤ 48 µg/m³, los niveles en el interior no pueden ser mayores que 12 µg/m³.',
      '* When outdoor PM2.5 is ≤ 48 µg/m³, indoor levels may not exceed 12 µg/m³.',
      '* Wenn die PM2,5-Werte im Freien ≤ 48 µg/m³ sind, dürfen die Werte in Innenräumen nicht höher als 12 µg/m³ sein.'
    ],
    resetCoreShellParagraph2: [
      '* Cuando PM2.5 en el exterior es > 48 µg/m³, los sistemas de filtración de aire deben remover al menos 75% del PM2.5 como mínimo.',
      '* When outdoor PM2.5 is > 48 µg/m³, air filtration systems must remove at least 75% of PM2.5 as a minimum.',
      '* Wenn die PM2,5-Werte im Freien > 48 µg/m³ sind, müssen Luftfiltersysteme mindestens 75 % der PM2,5-Werte entfernen.'
    ],
    resetSensorsTitle: [
      'Requisitos de los sensores',
      'Sensor requirements ',
      'Anforderungen an die Sensoren'
    ],
    resetSensorsParagraph: [
      'Los monitores acreditados RESET™ Air se definen y clasifican por grado y tipo. El grado del monitor define el rendimiento, la precisión y la capacidad de notificación de datos de un dispositivo de monitorización. El tipo de monitor define el propósito y marco de aplicación.La precisión y resolución de los sensores de MICA satisface los requisitos RESET Grado B - proporcionan datos sobre la calidad del aire interior combinando rendimiento, fiabilidad y coste.',
      'RESET™ Air accredited monitors are defined and classified by grade and type. The monitor grade defines the performance, accuracy and data reporting capability of a monitoring device. Monitor type defines the purpose and application framework.The accuracy and resolution of MICA sensors meet RESET Grade B requirements - they provide indoor air quality data combining performance, reliability and cost.',
      'RESET™ Air akkreditierte Überwachungsgeräte werden nach Klasse und Typ definiert und klassifiziert. Die Messgeräteklasse definiert die Leistung, die Genauigkeit und die Fähigkeit zur Datenmeldung eines Messgeräts. Der Monitortyp definiert den Zweck und den Anwendungsrahmen. Die Genauigkeit und Auflösung der MICA-Sensoren erfüllen die Anforderungen der RESET-Klasse B - sie liefern Daten zur Luftqualität in Innenräumen und kombinieren Leistung, Zuverlässigkeit und Kosten.'
    ],

    resetImageHeader2: ['TABLAS', 'TABLES', 'TABELLEN'],
    resetImageHeader3: [
      'La precisión y la resolución de los sensores también satisfacen los requisitos RESET Grado B:',
      'The accuracy and resolution of the sensors also meet RESET Grade B requirements:',
      'Die Genauigkeit und Auflösung der Sensoren entsprechen ebenfalls den Anforderungen von RESET Grade B:'
    ],
    dataReportTitle: ['Informe de datos', 'Data report', 'Datenbericht'],
    dataReportInformation: {
      p1: [
        'Descarga el informe de datos de calidad del aire interior requerido por la certificación WELL para enviar al IWBI para su análisis anual, para la recertificación de su edificio. Exporta aquí los datos directamente en el formato requerido por WELL y completa la',
        'Download the IAQ data report required by WELL Certification to send to IWBI for annual analysis, for the recertification of your building. Export here the data directly in the format required by WELL and complete the Data Report',
        'Laden Sie hier den für die WELL-Zertifizierung erforderlichen Datenbericht zur Innenraumluftqualität herunter, den Sie zur jährlichen Analyse an das IWBI für die Rezertifizierung Ihres Gebäudes senden können. Exportieren Sie hier die Daten direkt in dem von WELL geforderten Format und füllen Sie die'
      ],
      template: ['Plantilla', 'Template', 'Vorlage'],
      p2: [
        'del informe de datos (pestaña “Monitor_Data_DEVICE-ID - COPY”).',
        '(“Monitor_Data_DEVICE-ID - COPY” tab).',
        'für den Datenbericht aus (Registerkarte “Monitor_Data_DEVICE-ID - COPY”).'
      ]
    },
    calibrationCertificateTitle: [
      'Certificado de Calibración',
      'Certificate of Calibration',
      'Kalibrierungszertifikat'
    ],
    calibrationCertificateInformation: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    ],
    vocsCompliance: [
      'Cumplimiento sensor TVOC',
      'TVOC sensor compliance',
      'Übereinstimmung mit TVOC-Sensoren'
    ],
    visit: ['VISITAR', 'VISIT', 'BESUCHEN'],
    vocsComplianceInformation: [
      'Visita aquí la explicación del cumplimiento de los requisitos de precisión de WELL para el sensor de TVOC de MICA.',
      "Visit this link for an explanation of how MICA's TVOC sensor meets WELL accuracy requirements.",
      'Hier finden Sie eine Erklärung, wie der MICA TVOC-Sensor die Genauigkeitsanforderungen von WELL erfüllt.'
    ],
    staticDataTitle: [
      'Justificación de Sensores',
      'Sensor Justification',
      'Rechtfertigung von Sensoren'
    ],
    staticDataInformation: {
      p1: [
        'Descarga la justificación de los sensores de MICA para el envío periódico al IWBI y completa la',
        'Download here the MICA sensor justification for periodic submission to the IWBI and complete the Data Report',
        'Laden Sie die MICA-Sensorbegründung für die regelmäßige Übermittlung an das IWBI herunter und füllen Sie die'
      ],
      template: ['Plantilla', 'Template', 'Vorlage'],
      p2: [
        'del informe de datos (pestaña “Header_Monitor_Master”).',
        '(‘Header_Monitor_Master’ tab).',
        'für den Datenbericht aus (Registerkarte „Header_Monitor_Master“).'
      ]
    },
    download: ['DESCARGAR', 'DOWNLOAD', 'HERUNTERLADEN']
  },

  downloadWellDataReportModal: {
    downloadReport: [
      'Descargar informe de datos',
      'Download data report',
      'Datenbericht herunterladen'
    ],
    navDevices: ['1. Dispositivo', '1. Device', '1. Gerät'],
    navDates: ['2. Fechas', '2. Dates', '2. Daten'],
    selectDateInterval: [
      'Seleccionar intervalo de fechas',
      'Select date interval',
      'Datumsbereich auswählen'
    ]
  },

  downloadWellStaticDataModal: {
    downloadStatic: [
      'Descargar justificación de sensores',
      'Download sensors justification',
      'Download der Sensorbegründung'
    ],
    navDevices: ['Seleccionar dispositivo', 'Select  Device', 'Gerät auswählen']
  },

  // Popups de información
  infoModal: {
    moreInfo: ['Ampliar información', 'More information', 'Mehr Informationen']
  },
  // System page
  systemPage: {
    countryHelper: [
      'El país y código postal se utilizan para medir el tiempo exterior.',
      'The country and zip code are used to track the outside weather data.',
      'Das Land und die Postleitzahl werden verwendet, um die Außenwetterdaten zu verfolgen'
    ],
    typeBuilding: ['Tipo de edificio', 'Building type', 'Gebäudetyp'],
    antiquityBuilding: ['Antigüedad del edificio', 'Building age', 'Baualter'],
    ventilationBuilding: ['Tipo de ventilación', 'Type of ventilation', 'Art der Belüftung'],
    airTightnessLevelBuilding: [
      'Nivel de hermeticidad',
      'Building airtightness level',
      'Luftdichtheitsgrad des Gebäudes'
    ],
    occupancyBuilding: ['Ocupación', 'Occupancy classification', 'Belegungsklassifizierung'],
    typeOfUseBuilding: ['Tipo de uso', 'Type of use', 'Nutzungsart'],
    typeOfSystem: ['Tipo de dispositivo', 'Type of device', 'Gerätetyp']
  },
  supportModal: {
    title: ['¿Cómo podemos ayudarte?', 'How can we help you?', 'Wie können wir Ihnen helfen?'],
    subtitle: [
      'Rellena el formulario para ofrecerte la mejor solución.',
      'Fill in the form in order to offer you the best solution.',
      'Füllen Sie das Formular aus, damit wir Ihnen die beste Lösung anbieten können.'
    ],
    supportDescription: [
      'Descripción de la consulta',
      'Description of the occurrence',
      'Beschreibung der Konsultation'
    ],
    sendOccurrence: ['Enviar consulta', 'Send occurrence', 'Anfrage senden'],
    sensorIdInfo: [
      'Selecciona el ID si tu MICA está vinculado a tu cuenta o consíguelo en el proceso de configuración.',
      'Select the ID if your MICA is linked to your account or get it in the configuration process.',
      'Wählen Sie die ID aus, wenn Ihre MICA mit Ihrem Konto verknüpft ist, oder erhalten Sie sie während des Konfigurationsprozesses.'
    ]
  },
  selectedDevices: ['Dispositivos seleccionados: ', 'Selected devices: ', 'Ausgewählte Geräte: '],
  selectFifty: [
    'Seleccionar dispositivos (Max. 40)',
    'Select devices (Max. 40)',
    'Geräte auswählen (max. 40)'
  ],
  // Snackbar
  snackbar: {
    successTransfer: [
      'MICA transferida con éxito',
      'MICA successfully transferred',
      'MICA erfolgreich übertragen'
    ],
    whoops: [
      'Oops, algo ha ido mal. Por favor inténtelo otra vez.',
      'Whoops, something went wrong. Please try again or refresh the page.',
      'Hoppla, da ist etwas schief gelaufen. Bitte versuchen Sie es erneut oder aktualisieren Sie die Seite.'
    ],
    expiredToken: [
      'Tu token ha caducado. Inicie sesión otra vez.',
      'Your token has expired. Please login again.',
      'Ihr Token ist abgelaufen. Bitte melden Sie sich erneut an.'
    ],
    confirmationCodeSent: [
      'Se ha enviado un correo de confirmación a tu cuenta.',
      'A confirmation mail has been sent to your account',
      'Eine Bestätigungs-E-Mail wurde an Ihr Konto gesendet'
    ],
    confirmSignUpSuccess: [
      'Cuenta verificada con éxito. Inicie sesión.',
      "You have verified successfully your account. Now it's time to login.",
      'Sie haben Ihr Konto erfolgreich verifiziert. Jetzt ist es Zeit sich anzumelden.'
    ],
    confirmSignUpCollaboratorSuccess: [
      'Cuenta creada con éxito. Inicie sesión.',
      "You have successfully created your account. Now it's time to login.",
      'Sie haben Ihr Konto erfolgreich erstellt. Jetzt ist es Zeit, sich einzuloggen.'
    ],
    resendConfirmationMailSuccess: [
      'Se ha reenviado el correo de confirmación.',
      'Confirmation mail has been sent successfully.',
      'Bestätigungsmail wurde erfolgreich versendet.'
    ],
    resendConfirmationMailFailure: [
      'Ha habido un problema enviando el correo de confirmación.',
      'There was a problem when resending confirmation mail. ',
      'Beim erneuten Senden der Bestätigungsmail ist ein Problem aufgetreten.'
    ],
    addedSuccessful: ['Añadido con éxito', 'Successfully added', 'Erfolgreich hinzugefügt'],
    savedSuccessful: ['Guardado con éxito', 'Successfully saved', 'Erfolgreich gespeichert'],
    deleteSuccessful: ['Eliminado con éxito', 'Successfully deleted', 'Erfolgreich gelöscht'],
    createGroupSuccessful: ['Grupo creado con éxito.', 'Successfully created group.'],
    updateGroupSuccessful: ['Grupo actualizado con éxito.', 'Successfully updated group.'],
    deleteGroupSuccessful: ['Grupo borrado con éxito.', 'Successfully deleted group.'],
    deleteFromGroupSuccessful: ['Eliminado de grupo con éxito', 'Successfully deleted from group'],
    createScreenSuccessful: ['Pantalla creada con éxito', 'Successfully created screen'],
    updateScreenSuccessful: ['Pantalla editada con éxito', 'Successfully updated screen'],
    deleteScreenSuccessful: ['Pantalla borrada con éxito', 'Successfully deleted screen'],
    createAlarmSuccessful: [
      'Alarma creada con éxito',
      'Successfully created alarm',
      'Erfolgreich erstellter Alarm'
    ],
    updateAlarmSuccessful: [
      'Alarma actualizada con éxito',
      'Successfully updated alarm',
      'Erfolgreich aktualisierter Alarm'
    ],
    deleteAlarmSuccessful: [
      'Alarma eliminada con éxito',
      'Successfully deleted alarm',
      'Erfolgreich gelöschter Alarm'
    ],
    alarmActivatedSuccessful: [
      'Alarma reactivada con éxito',
      'Alarm successfully reactivated',
      'Alarm erfolgreich reaktiviert'
    ],
    alarmDesactivatedSuccessful: [
      'Alarma desactivada con éxito',
      'Alarm successfully deactivated',
      'Alarm erfolgreich abgeschaltet'
    ],
    forgottenPasswordSuccess: [
      'Se ha solicitado un cambio de contraseña. Por favor consulte su correo electronico.',
      'Reset password requested. Please check your email.',
      'Passwort zurücksetzen angefordert. Bitte überprüfen Sie Ihre E-Mail.'
    ],
    recoverPasswordSuccess: [
      'La contraseña se ha cambiado con éxito.',
      'Succesfully resetted password.',
      'Passwort erfolgreich zurückgesetzt.'
    ],
    logoUploadedSuccessful: [
      'Logo subido con éxito',
      'Logo uploaded successfully',
      'Logo erfolgreich hochgeladen'
    ],
    logoDeletedSuccessful: [
      'Logo eliminado con éxito',
      'Logo deleted successfully',
      'Logo erfolgreich gelöscht'
    ],
    sharableLinkCopiedToClipboard: [
      'El link se ha copiado en el portapapeles.',
      'Shareable link is copied to clipboard.',
      'Der teilbare Link wird in die Zwischenablage kopiert'
    ],
    zipCodeNotAvailable: [
      'Lo sentimos... No disponemos de datos de tiempo exterior para el código postal introducido',
      'Sorry... We do not have weather data for the zip code inserted',
      'Entschuldigung... Wir haben keine Wetterdaten für die eingegebene Postleitzahl'
    ],
    ScreenNameAlreadyExists: [
      'Ya existe un compartible con este nombre para este dispositivo. Inténtelo con otro nombre.',
      'There is already a shareable with this name for this device. Please try it with another name.',
      'There is already a shareable with this name for this device. Please try it with another name.'
    ],
    occurrenceSentSuccessful: [
      'Incidencia enviada con éxito. El equipo de soporte se pondrá en contacto contigo lo antes posible.',
      'Occurrence submitted successfully. The support team will contact you as soon as possible.',
      'Vorfall erfolgreich übermittelt. Das Support-Team wird sich so schnell wie möglich mit Ihnen in Verbindung setzen.'
    ],
    cookiesRejectedSuccessfully: [
      'Cookies rechazadas con éxito',
      'Cookies rejected successfully',
      'Cookies erfolgreich abgelehnt'
    ],
    cookiesAcceptedSuccessfully: [
      'Cookies aceptadas con éxito',
      'Cookies accepted successfully',
      'Cookies erfolgreich akzeptiert'
    ]
  },

  // Submit errors
  submitErrors: {
    locationNotFound: ['Ubicación no encontrada', 'Location not found', 'Standort nicht gefunden'],
    userExist: [
      'Este usuario ya tiene una cuenta.',
      'This user has already an account.',
      'Dieser Benutzer hat bereits ein Konto.'
    ],
    collaborativeUserNotValid: [
      'Cuenta no válida. No se puede dar acceso al usuario.',
      'Invalid account. The user cannot be granted access.',
      'Ungültiges Konto. Dem Benutzer kann kein Zugang gewährt werden.'
    ],
    NoSensors: [
      'Algún sensor no ha registrado datos en el intervalo seleccionado. Inténtelo de nuevo modificando el rango de fechas o contacte con soporte.',
      'Some sensor has not recorded data in the selected range. Try again by modifying the date range or contact support.',
      'Een sensor heeft geen gegevens geregistreerd in het geselecteerde bereik. Probeer het opnieuw door het datumbereik te wijzigen of neem contact op met ondersteuning.'
    ],
    Nodata: [
      'No existen datos en el intervalo de tiempo seleccionado Modifique las fechas seleccionadas e inténtelo de nuevo.',
      'No data in the selected time interval Modify the selected dates and try again.',
      'Er zijn geen gegevens in het geselecteerde tijdvak Wijzig de geselecteerde data en probeer het opnieuw.'
    ],
    unknown: [
      'Error desconocido. Por favor inténtelo más tarde.',
      'Unknown error. Please try again later.',
      'Unbekannter Fehler. Bitte versuchen Sie es später erneut.'
    ],
    invalidLogin: [
      'Correo electrónico o contraseña erróneos.',
      'Wrong email or password.',
      'Falsche Email oder Passwort.'
    ],
    checkEmail: [
      'Por favor, comprueba el correo asociado a tu cuenta (incluso carpeta Spam), ya que ha habido un cambio en la plataforma.',
      'Please check the email associated with your account (including the Spam folder), as there has been a change in the platform.',
      'Bitte überprüfen Sie die mit Ihrem Konto verknüpfte E-Mail (einschließlich des Spam-Ordners), da es eine Änderung der Plattform gegeben hat.'
    ],
    userNotConfirmed: [
      'El usuario no ha sido confirmado.',
      'User has not been confirmed yet.',
      'Benutzer wurde noch nicht bestätigt.'
    ],
    emailInUse: [
      'La dirección de correo electrónico que ha introducido esta en uso',
      'Email address already in use.',
      'E-Mail-Adresse wird schon verwendet.'
    ],
    invalidPassword: ['Contraseña incorrecta', 'Incorrect password', 'Falsches Passwort'],
    systemNotFound: [
      'No hemos encontrado un sensor que se corresponda al sensor ID introducido. Asegúrese de que el sensor ID es correcto.',
      'We were unable to find a sensor with the submitted sensor id. Please make sure the sensor id is correct.',
      'Wir konnten keinen Sensor mit der übermittelten Sensor-ID finden. Bitte stellen Sie sicher, dass die Sensor-ID korrekt ist.'
    ],
    systemAlreadyInUse: [
      'El sensor que está intentando añadir pertenece a otro usuario.',
      "The sensor you're trying to add is already used by another user.",
      'Der Sensor, den Sie hinzufügen möchten, wird bereits von einem anderen Benutzer verwendet.'
    ],
    limitSystemsUser: [
      'Se ha alcanzado el límite de dispositivos contratados en su cuenta Business. Si desea aumentarlo, por favor contacte con info@inbiot.es',
      'The limit of contracted devices in your Business account has been reached. If you wish to increase it, please contact info@inbiot.es.',
      'Die Höchstzahl der vertraglich gebundenen Geräte in Ihrem Geschäftskonto wurde erreicht. Wenn Sie den Betrag erhöhen möchten, wenden Sie sich bitte an info@inbiot.es'
    ],
    systemAlreadyInOtherAlarm: [
      'Este dispositivo ya tiene una alarma configurada.',
      'This sensor has already a configured alert.',
      'Dieser Sensor hat bereits einen konfigurierten Alarm.'
    ],
    unknownEmail: [
      'Dirección de correo electrónico desconocida.',
      'Unknown email address.',
      'Unbekannte E-Mail-Adresse.'
    ],
    incorrectOldPassword: [
      'La contraseña antigua es incorrecta',
      'Old password is incorrect',
      'das alte Passwort ist falsch'
    ],
    passwordLimitExceeded: [
      'Has superado el límite de intentos. Inténtelo en un tiempo.',
      'You have exceeded the attemps limit. Try it again in some time.',
      'Sie haben das Versuchslimit überschritten. Versuchen Sie es nach einiger Zeit noch einmal.'
    ],
    channelNotFound: [
      'No hemos podido encontrar el sensor.',
      'We were not able to find this channel.',
      'Wir konnten diesen Kanal nicht finden.'
    ],
    channelForbidden: [
      'Este sensor no es público.',
      'This is not a public channel.',
      'Dies ist kein öffentlicher Kanal.'
    ],
    expiredVerificationCode: [
      'El código de verificación del correo ha expirado. Solicite un nuevo código.',
      'The specified e-mail verification code has expired. Request another code.'
    ],
    userAlreadyConfirmed: [
      'El usuario ya ha sido confirmado.',
      'This user has already been confirmed.',
      'Dieser Benutzer wurde bereits bestätigt.'
    ],
    recipientAlreadyConfirmed: [
      'El email ya ha sido confirmado.',
      'This email has already been confirmed.',
      'Diese E-Mail wurde bereits bestätigt.'
    ],
    recipientNotFound: [
      'El email ya ha sido confirmado.',
      'The email could not be successfully confirmed.',
      'Die E-Mail konnte nicht erfolgreich bestätigt werden.'
    ]
  },
  none: ['Ninguno', 'None', 'Keiner'],

  // Validation
  validation: {
    required: ['Campo requerido', 'Required', 'Erforderlich'],
    timeRange: ['Cambiar intervalo de tiempo', 'Change time range ', 'Tijdsinterval wijzigen'],
    anyField: [
      'Debe seleccionar al menos un día',
      'You should choose at least one day',
      'U moet ten minste één dag kiezen'
    ],
    anyFieldRequired: [
      'Debes seleccionar al menos un tipo de dato',
      'You should choose at least one data type',
      'Sie sollten mindestens einen Datentyp auswählen'
    ],
    tooManyParams: [
      'El número máximo de parámetros es 8',
      'The maximum number of parameters is 8',
      'Die maximale Anzahl von Parametern beträgt 8'
    ],
    anyEmailRequired: [
      'Debes seleccionar al menos un correo.',
      'You should choose at least one e-mail.',
      'Sie sollten mindestens eine E-Mail wählen.'
    ],
    rangeValuesRequired: [
      'Debes introducir valores para el rango de inactividad',
      'You must enter values for the inactivity range',
      'Sie müssen Werte für den Inaktivitätsbereich eingeben'
    ],
    rangeValuesInvalid: [
      'Debes introducir un valor válido.',
      'You must enter a valid value.',
      'Sie müssen einen gültigen Wert eingeben.'
    ],
    anyDeviceRequired: [
      'Debes seleccionar al menos un dispositivo',
      'You should choose at least one device',
      'Sie sollten mindestens ein Gerät auswählen'
    ],
    deviceRequired: [
      'Debes seleccionar un dispositivo',
      'You should choose one device',
      'Sie sollten sich für ein Gerät entscheiden'
    ],
    userRequired: [
      'Debes seleccionar un usuario',
      'You should choose one user',
      'Sie sollten einen Benutzer auswählen'
    ],
    contactEmailRequired: [
      'Debes facilitar un correo de contacto.',
      'You must provide a contact email address.',
      'Sie müssen eine Kontakt-E-Mail-Adresse angeben.'
    ],
    descriptionRequired: [
      'Por favor, describe tu consulta.',
      'Please describe your query.',
      'Bitte beschreiben Sie Ihre Anfrage.'
    ],
    timeIntervalAllowed: [
      'Tu plan actual solo permite un mínimo de 10 minutos.',
      'Your current plan only allows a minimum of 10 minutes.',
      'Ihr aktueller Plan erlaubt nur ein Minimum von 10 Minuten.'
    ],
    timeIntervalCellularInfo: [
      'Los dispositivos con conectividad celular tienen un mayor procesamiento y su tiempo entre datos mínimo es de 10 minutos.',
      'Devices with cellular connectivity have higher processing and have a minimum periodicity interval of 10 minutes.',
      'Geräte mit cellular-Konnektivität haben eine höhere Verarbeitungsgeschwindigkeit und eine minimale Datenübertragungszeit von 10 Minuten.'
    ],
    timeIntervalMinimum: [
      'El tiempo entre datos mínimo es 1 minuto.',
      'The minimum periodicity interval is 1 minute.',
      'Das Mindestintervall für die Periodizität beträgt 1 Minute.'
    ],
    timeIntervalUndefined: [
      'Especifica un intervalo de tiempo valido',
      'Specify a valid time interval',
      'Gibt ein gültiges Zeitintervall an'
    ],
    timeIntervalMaximum: [
      'El tiempo entre datos máximo son 60 minutos.',
      'Maximum time between data is 60 minutes.',
      'Der maximale Zeitraum zwischen den Daten beträgt 60 Minuten.'
    ],
    inValidEmail: ['Correo electrónico no válido', 'Invalid email', 'Ungültige E-Mail'],
    inValidPassword: [
      'La contraseña necesita al menos 6 caracteres',
      'Password must have at least 6 characters',
      'Passwort muss mindestens 6 Zeichen haben'
    ],
    invalidRepeatPassword: [
      'Las contraseñas deben coincidir.',
      'Passwords should match.',
      'Passwörter sollten übereinstimmen.'
    ],
    invalidFormat: ['Formato inválido.', 'Invalid format.', 'Ungültiges Format'],
    invalidDateRange: [
      'Rango de fechas inválido',
      'Invalid date range',
      'Ungültiger Datumsbereich'
    ],

    exceededDateRange: [
      'El periodo seleccionado excede el límite permitido según los parámetros elegidos. Ajusta el rango de fechas o modifica los parámetros de descarga.',
      'The selected period exceeds the allowed limit according to the chosen parameters. Adjust the date range or modify the download parameters.',
      'Der ausgewählte Zeitraum überschreitet das zulässige Limit gemäß den gewählten Parametern. Passen Sie den Datumsbereich an oder ändern Sie die Download-Parameter.'
    ],
    invalidDateInterval: [
      'El intervalo de descarga no puede superar el límite indicado anteriormente.',
      'The download interval may not exceed the limit shown above.',
      'Das Download-Intervall darf das oben angegebene Limit nicht überschreiten.'
    ]
  },
  noSystemsText: [
    'Añade un dispositivo para empezar',
    'To start add a device',
    'Um zu beginnen, fügen Sie ein Gerät hinzu'
  ],
  sortOptions: {
    groupsAZ: ['Grupos de la A-Z', 'Groups from A-Z', 'Gruppen von A-Z'],
    groupsZA: ['Grupos de la Z-A', 'Groups from Z-A', 'Gruppen von Z-A'],
    devicesAZ: ['Dispositivos de la A-Z', 'Devices from A-Z', 'A-Z Geräte'],
    devicesZA: ['Dispositivos de la Z-A', 'Devices from Z-A', 'Z-A Geräte'],
    withMoreMicas: ['Grupos más grandes primero', 'Bigger groups first', 'Größere Gruppen zuerst'],
    withLessMicas: [
      'Grupos más pequeños primero',
      'Smaller groups first',
      'Kleinere Gruppen zuerst'
    ],
    lastAdded: [
      'Dispositivos más recientes primero',
      'Latest devices first',
      'Neueste Geräte zuerst'
    ],
    firstAdded: [
      'Dispositivos más antiguos primero',
      'Older devices first',
      'Ältere Geräte zuerst'
    ],
    worstMeasuresFirst: [
      'Dispositivos más críticos primero',
      'More critical devices first',
      'Die wichtigsten Geräte zuerst'
    ],
    bestMeasuresFirst: [
      'Dispositivos menos críticos primero',
      'Less critical devices first',
      'Weniger kritische Geräte zuerst'
    ]
  },
  sortMenuOptions: {
    groupsByName: ['Grupos por nombre', 'Groups by name', 'Gruppen nach Namen'],
    groupsBySize: ['Grupos por tamaño', 'Groups by size', 'Gruppen nach Größe'],
    devicesByName: ['Dispositivos por nombre', 'Devices by name', 'Geräte nach Namen'],
    devicesByAddDate: [
      'Dispositivos por antigüedad',
      'Devices by date added',
      'Geräte nach Hinzufügedatum'
    ],
    devicesByState: ['Dispositivos por estado', 'Devices by status', 'Geräte nach Status']
  },
  // Data types
  temperature: ['Temperatura', 'Temperature', 'Temperatur'],
  tempLow: [
    'Temperatura (límite inferior)',
    'Temperature (lower limit)',
    'Temperatur (unterer Grenzwert)'
  ],
  tempHigh: [
    'Temperatura (límite superior)',
    'Temperature (upper limit)',
    'Temperatur (oberer Grenzwert)'
  ],
  relativeHumidity: ['Humedad relativa', 'Relative humidity', 'Relative Luftfeuchtigkeit'],
  humidity: ['Humedad', 'Humidity', 'Luftfeuchtigkeit'],
  rHumidity: ['Humedad R.', 'R.Humidity', 'R.Luftfeuchtigkeit'],
  humLow: [
    'Humedad (límite inferior)',
    'Humidity (lower limit)',
    'Luftfeuchtigkeit (unterer Grenzwert)'
  ],
  humHigh: [
    'Humedad (límite superior)',
    'Humidity (upper limit)',
    'Luftfeuchtigkeit (oberer Grenzwert)'
  ],
  co2: ['CO₂', 'CO₂', 'CO₂'],
  formaldehyde: ['Formaldehído', 'Formaldehyde', 'Formaldehyd'],
  vocs: ['TVOC', 'TVOC', 'TVOC'],
  vocsIndex: ['TVOC', 'TVOC', 'TVOC'],
  pm25: ['PM2.5', 'PM2.5', 'PM2.5'],
  pm10: ['PM10', 'PM10', 'PM10'],
  pm4: ['PM4.0', 'PM4.0', 'PM4.0'],
  pm1: ['PM1.0', 'PM1.0', 'PM1.0'],
  radon: ['Radón', 'Radon', 'Radon'],
  weather: ['Tiempo exterior', 'Weather', 'Wetter'],
  outdoorTemperature: ['Temperatura exterior', 'Outdoor temperature', 'Außentemperatur'],
  outdoorHumidity: ['Humedad exterior', 'Outdoor humidity', 'Außenluftfeuchtigkeit'],
  baseStationInfo: [
    'Localización de la estación base',
    'Base station location',
    'Base station location'
  ],
  co: ['CO', 'CO', 'CO'],
  o3: ['O₃', 'O₃', 'O₃'],
  covid19: ['Indicador virus', 'Virus indicator', 'Virenindikator'],
  no: ['NO', 'NO', 'NO'],
  nox: ['NOₓ', 'NOₓ', 'NOₓ'],
  no2: ['NO₂', 'NO₂', 'NO₂'],
  iaq: ['IAQ', 'IAQ', 'IAQ'],
  ethanol: ['Etanol', 'Ethanol', 'Ethanol'],
  iaqMonitoring: ['Monitorización IAQ', 'IAQ Monitoring', 'IAQ Monitoring'],
  radiantTemperature: ['Temperatura Radiante', 'Radiant Temperature', 'Strahlende Temperatur'],
  lux: ['Iluminancia', 'Illuminance', 'Beleuchtungsstärke'],
  dB: ['Nivel de ruido', 'Noise level', 'Geräuschpegel'],
  capacity: ['Aforo actual', 'Current capacity', 'Derzeitige Kapazität'],
  pressure: ['Presión', 'Pressure', 'Luftdruck'],
  csv: ['CSV', 'CSV', 'CSV'],
  xlsx: ['XLSX', 'XLSX', 'XLSX'],
  preheating: ['Preheating', 'Preheating', 'Preheating'],
  // Graph range units
  graphRangeUnits: {
    hours: ['Horas', 'Hours', 'Stunden'],
    days: ['Días', 'Days', 'Tage'],
    weeks: ['Semanas', 'Weeks', 'Wochen']
  },
  beacon: {
    responses: ['Respuestas instantaneas', 'Instant responses', 'Sofortige Antworten'],
    header: {
      title: [
        'El asistente está siendo bloqueado',
        'The beacon is being blocked',
        'Das Hilfe-Plugin wird blockiert'
      ],
      subtitle: [
        'Esta funcionalidad a veces es bloqueada cuando se utilizan extensiones de bloqueo de JavaScript (Adblock, NoScript, uBlock).',
        'This functionality is sometimes blocked from loading when JavaScript blocking extensions (Adblock, NoScript, uBlock) are in use.',
        'Diese Funktion wird manchmal blockiert, wenn Erweiterungen zum Blockieren von JavaScript (Adblock, NoScript, uBlock) verwendet werden.'
      ]
    },
    body: {
      title: ['Desbloquear el asistente', 'Unblocking beacon', 'Freigabe von Assistenten'],
      subtitle: [
        'Hay varias opciones para restaurar el acceso al asistente de ayuda:',
        'There are several options for restoring access to the beacon:',
        'Es gibt mehrere Möglichkeiten, den Zugriff auf den Hilfe-Assistenten wiederherzustellen:'
      ],
      list: {
        p1: [
          'Desactiva la extensión de bloqueo de anuncios en las páginas específicas donde se utiliza el asistente.',
          'Disable the ad-blocking extension on the specific pages where the help assistant is in use.',
          'Deaktiviert die Werbeblocker-Erweiterung auf den spezifischen Seiten, auf denen der Assistent verwendet wird.'
        ],
        p2: [
          'Añade el dominio myinbiot.com a cualquier lista de permitidos dentro de la extensión o plugin de bloqueo de scripts.',
          'Add myinbiot.com domain to any allow lists within the script-blocking extension or plugin.',
          'Fügen Sie die Domain myinbiot.com in die Liste der zulässigen Erweiterungen oder Skriptblocker-Plugins ein.'
        ]
      },
      footer: [
        'Una vez que hayas restaurado el acceso al asistente, recarga la página.',
        'Once you’ve restored access to the beacon, reload the page.',
        'Sobald Sie den Zugriff auf den Assistenten wiederhergestellt haben, laden Sie die Seite erneut.'
      ]
    }
  },
  temperatureInfoN: [
    '<h4> Temperatura </h4>' +
      '<br/>Los niveles recomendados de temperatura para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 20 - 23ºC </li>' +
      '<li>Amarillo/Alerta: 18 - 20ºC / 24 - 26ºC </li>' +
      '<li>Rojo/Alarma: < 18 y > 26ºC </li>' +
      '</ul>',
    '<h4> Temperature </h4>' +
      '<br/>Recommended temperature levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 20 - 23ºC </li>' +
      '<li>Yellow/Warning: 18 - 20ºC / 24 - 26ºC </li>' +
      '<li>Red/Alarm: < 18 y > 26ºC </li>' +
      '</ul>'
  ],
  tempLowInfoN: [
    '<h4> Temperatura </h4>' +
      '<br/>Los niveles recomendados de temperatura para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 20 - 23ºC </li>' +
      '<li>Amarillo/Alerta: 18 - 20ºC / 24 - 26ºC </li>' +
      '<li>Rojo/Alarma: < 18 y > 26ºC </li>' +
      '</ul>',
    '<h4> Temperature </h4>' +
      '<br/>Recommended temperature levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 20 - 23ºC </li>' +
      '<li>Yellow/Warning: 18 - 20ºC / 24 - 26ºC </li>' +
      '<li>Red/Alarm: < 18 y > 26ºC </li>' +
      '</ul>'
  ],
  tempHighInfoN: [
    '<h4> Temperatura </h4>' +
      '<br/>Los niveles recomendados de temperatura para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 20 - 23ºC </li>' +
      '<li>Amarillo/Alerta: 18 - 20ºC / 24 - 26ºC </li>' +
      '<li>Rojo/Alarma: < 18 y > 26ºC </li>' +
      '</ul>',
    '<h4> Temperature </h4>' +
      '<br/>Recommended temperature levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 20 - 23ºC </li>' +
      '<li>Yellow/Warning: 18 - 20ºC / 24 - 26ºC </li>' +
      '<li>Red/Alarm: < 18 y > 26ºC </li>' +
      '</ul>'
  ],
  tempHighInfoNFahrenheit: [
    '<h4> Temperatura </h4>' +
      '<br/>Los niveles recomendados de temperatura para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 68 - 73.4ºF </li>' +
      '<li>Amarillo/Alerta: 64.4 - 68ºF / 75.2 - 78.8ºF </li>' +
      '<li>Rojo/Alarma: < 64.4 y > 78.8ºF </li>' +
      '</ul>',
    '<h4> Temperature </h4>' +
      '<br/>Recommended temperature levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 68 - 73.4ºF </li>' +
      '<li>Yellow/Warning: 64.4 - 68ºF / 75.2 - 78.8ºF </li>' +
      '<li>Red/Alarm: < 64.4 and > 78.8ºF </li>' +
      '</ul>'
  ],
  tempHighInfoNKelvin: [
    '<h4> Temperatura </h4>' +
      '<br/>Los niveles recomendados de temperatura para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 293 - 296ºK </li>' +
      '<li>Amarillo/Alerta: 291 - 293ºK / 297 - 299ºF </li>' +
      '<li>Rojo/Alarma: < 291 y > 299ºK </li>' +
      '</ul>',
    '<h4> Temperature </h4>' +
      '<br/>Recommended temperature levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 293 - 296ºK </li>' +
      '<li>Yellow/Warning: 291 - 293ºK / 297 - 299ºF </li>' +
      '<li>Red/Alarm: < 291 amd >299ºK </li>' +
      '</ul>'
  ],
  tempLowInfoNFahrenheit: [
    '<h4> Temperatura </h4>' +
      '<br/>Los niveles recomendados de temperatura para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 68 - 73.4ºF </li>' +
      '<li>Amarillo/Alerta: 64.4 - 68ºF / 75.2 - 78.8ºF </li>' +
      '<li>Rojo/Alarma: < 64.4 y > 78.8ºF </li>' +
      '</ul>',
    '<h4> Temperature </h4>' +
      '<br/>Recommended temperature levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 68 - 73.4ºF </li>' +
      '<li>Yellow/Warning: 64.4 - 68ºF / 75.2 - 78.8ºF </li>' +
      '<li>Red/Alarm: < 64.4 and > 78.8ºF </li>' +
      '</ul>'
  ],
  tempLowInfoNKelvin: [
    '<h4> Temperatura </h4>' +
      '<br/>Los niveles recomendados de temperatura para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 293 - 296ºK </li>' +
      '<li>Amarillo/Alerta: 291 - 293ºK / 297 - 299ºF </li>' +
      '<li>Rojo/Alarma: < 291 y > 299ºK </li>' +
      '</ul>',
    '<h4> Temperature </h4>' +
      '<br/>Recommended temperature levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 293 - 296ºK </li>' +
      '<li>Yellow/Warning: 291 - 293ºK / 297 - 299ºF </li>' +
      '<li>Red/Alarm: < 291 amd >299ºK </li>' +
      '</ul>'
  ],
  humidityInfoN: [
    '<h4> Humedad relativa </h4>' +
      '<br/>Los niveles recomendados de humedad relativa para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 40 – 60 %</li>' +
      '<li>Amarillo/Alerta: 30 – 40% / 60 – 70%  </li>' +
      '<li>Rojo/Alarma: < 30 % y > 70% </li>' +
      '</ul>',
    '<h4> Relative humidity </h4>' +
      '<br/>Recommended relative humidity levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 40 – 60 %</li>' +
      '<li>Yellow/Warning: 30 – 40% / 60 – 70% </li>' +
      '<li>Red/Alarm: < 30 % y > 70% </li>' +
      '</ul>'
  ],
  humLowInfoN: [
    '<h4> Humedad relativa </h4>' +
      '<br/>Los niveles recomendados de humedad relativa para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 40 – 60 %</li>' +
      '<li>Amarillo/Alerta: 30 – 40% / 60 – 70%  </li>' +
      '<li>Rojo/Alarma: < 30 % y > 70% </li>' +
      '</ul>',
    '<h4> Relative humidity </h4>' +
      '<br/>Recommended relative humidity levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 40 – 60 %</li>' +
      '<li>Yellow/Warning: 30 – 40% / 60 – 70% </li>' +
      '<li>Red/Alarm: < 30 % y > 70% </li>' +
      '</ul>'
  ],
  humHighInfoN: [
    '<h4> Humedad relativa </h4>' +
      '<br/>Los niveles recomendados de humedad relativa para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 40 – 60 %</li>' +
      '<li>Amarillo/Alerta: 30 – 40% / 60 – 70%  </li>' +
      '<li>Rojo/Alarma: < 30 % y > 70% </li>' +
      '</ul>',
    '<h4> Relative humidity </h4>' +
      '<br/>Recommended relative humidity levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 40 – 60 %</li>' +
      '<li>Yellow/Warning: 30 – 40% / 60 – 70% </li>' +
      '<li>Red/Alarm: < 30 % y > 70% </li>' +
      '</ul>'
  ],

  co2InfoN: [
    '<h4> CO₂ </h4>' +
      '<br/>Los niveles recomendados de CO₂ para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 800 ppm</li>' +
      '<li>Amarillo/Alerta: 800 – 1500 ppm </li>' +
      '<li>Rojo/Alarma: > 1500 ppm </li>' +
      '</ul>',
    '<h4> CO₂ </h4>' +
      '<br/>Recommended CO₂ levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 800 ppm</li>' +
      '<li>Yellow/Warning: 800 – 1500 ppm </li>' +
      '<li>Red/Alarm: > 1500 ppm </li>' +
      '</ul>'
  ],
  formaldehydeInfoN: [
    '<h4> Formaldehído </h4>' +
      '<br/>Los niveles recomendados de formaldehído para un espacio saludable son:<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 70 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 70 – 120 µg/m³.</li>' +
      '<li>Rojo/Alarma: > 120 µg/m³.</li>' +
      '</ul>',
    '<h4> Formaldehyde </h4>' +
      '<br/>Recommended formaldehyde levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 70 µg/m³ </li>' +
      '<li>Yellow/Warning: 70 – 120 µg/m³.</li>' +
      '<li>Red/Alarm: > 120 µg/m³.</li>' +
      '</ul>'
  ],
  formaldehydeInfoNFormaldehyde: [
    '<h4> Formaldehído </h4>' +
      '<br/>Los niveles recomendados de formaldehído para un espacio saludable son:<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 56 ppb </li>' +
      '<li>Amarillo/Alerta: 56 – 96 ppb.</li>' +
      '<li>Rojo/Alarma: > 96 ppb.</li>' +
      '</ul>',
    '<h4> Formaldehyde </h4>' +
      '<br/>Recommended formaldehyde levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 56 ppb </li>' +
      '<li>Yellow/Warning: 56 – 96 ppb.</li>' +
      '<li>Red/Alarm: > 96 ppb.</li>' +
      '</ul>'
  ],
  o3InfoN: [
    '<h4> O₃ </h4>' +
      '<br/>Los niveles recomendados de O₃ para un espacio saludable son:<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 51 ppb </li>' +
      '<li>Amarillo/Alerta: 51 – 96 ppb.</li>' +
      '<li>Rojo/Alarma: > 96 ppb.</li>' +
      '</ul>',
    '<h4> O₃ </h4>' +
      '<br/>Recommended O₃ levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 51 ppb </li>' +
      '<li>Yellow/Warning: 51 – 122 ppb.</li>' +
      '<li>Red/Alarm: > 122 ppb.</li>' +
      '</ul>'
  ],
  o3InfoNO3: [
    '<h4> O₃ </h4>' +
      '<br/>Los niveles recomendados de O₃ para un espacio saludable son:<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 100 ug/m3 </li>' +
      '<li>Amarillo/Alerta: 100 – 240 ug/m3.</li>' +
      '<li>Rojo/Alarma: > 240 ug/m3.</li>' +
      '</ul>',
    '<h4> O₃ </h4>' +
      '<br/>Recommended O₃ levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 100 ug/m3 </li>' +
      '<li>Yellow/Warning: 100 – 240 ug/m3.</li>' +
      '<li>Red/Alarm: > 240 ug/m3.</li>' +
      '</ul>'
  ],

  coInfoN: [
    '<h4> CO </h4>' +
      '<br/>Los niveles recomendados de CO para un espacio saludable son:<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 8.6 ppm </li>' +
      '<li>Amarillo/Alerta: 8.6 – 25 ppm.</li>' +
      '<li>Rojo/Alarma: > 25 ppm.</li>' +
      '</ul>',
    '<h4> CO </h4>' +
      '<br/>Recommended CO levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 8.6 ppm </li>' +
      '<li>Yellow/Warning: 8.6 – 25 ppm.</li>' +
      '<li>Red/Alarm: > 25 ppm.</li>' +
      '</ul>'
  ],
  coInfoNCO: [
    '<h4> CO </h4>' +
      '<br/>Los niveles recomendados de CO para un espacio saludable son:<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 10 ug/m3 </li>' +
      '<li>Amarillo/Alerta: 10 – 29 ug/m3.</li>' +
      '<li>Rojo/Alarma: > 29 ug/m3.</li>' +
      '</ul>',
    '<h4> CO </h4>' +
      '<br/>Recommended CO levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 10 ug/m3 </li>' +
      '<li>Yellow/Warning: 10 – 29 ug/m3.</li>' +
      '<li>Red/Alarm: > 29 ug/m3.</li>' +
      '</ul>'
  ],
  tvocIndexInformationTitle: [
    '¿Por qué recomendamos utilizar el Índice de TVOC?',
    'Why do we recommend using the TVOC Index?',
    'Warum empfehlen wir die Verwendung des TVOC-Index?'
  ],
  tvocIndexInformationText: [
    'El Índice de TVOC es la herramienta óptima para monitorizar la evolución de los TVOC en el aire interior. Se recomienda esta opción por defecto en la visualización de datos en My inBiot.',
    'The TVOC Index is the optimal tool to monitor the evolution of TVOCs in indoor air. This option is recommended as default in the data display in My inBiot.',
    'Der TVOC-Index ist das optimale Instrument zur Überwachung der Entwicklung von TVOCs in der Innenraumluft. Diese Option wird als Standard für die Datenanzeige in My inBiot empfohlen.'
  ],
  vocsInfoN: [
    '<h4> TVOCs </h4>' +
      '<br/>Los niveles recomendados de TVOCs para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 220 ppb </li>' +
      '<li>Amarillo/Alerta: 220 - 660 ppb </li>' +
      '<li>Rojo/Alarma: > 660 ppb </li>' +
      '</ul>',
    '<h4> TVOCs </h4>' +
      '<br/>Recommended TVOC levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 220 ppb </li>' +
      '<li>Yellow/Warning: 220 - 660 ppb </li>' +
      '<li>Red/Alarm: > 660 ppb </li>' +
      '</ul>'
  ],
  vocsInfoNVocsWell: [
    '<h4> TVOCs (WELL)</h4>' +
      '<br/>Los niveles recomendados de TVOCs para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 990 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 990 - 2970 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 2970 µg/m³ </li>' +
      '</ul>',
    '<h4> TVOCs (WELL)</h4>' +
      '<br/>Recommended TVOC levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 990 µg/m³ </li>' +
      '<li>Yellow/Warning: 990 - 2970 µg/m³ </li>' +
      '<li>Red/Alarm: > 2970 µg/m³ </li>' +
      '</ul>'
  ],
  vocsInfoNVocsReset: [
    '<h4> TVOCs (RESET)</h4>' +
      '<br/>Los niveles recomendados de TVOCs para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 506 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 506 - 1518 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 1518 µg/m³ </li>' +
      '</ul>',
    '<h4> TVOCs (RESET)</h4>' +
      '<br/>Recommended TVOC levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 506 µg/m³ </li>' +
      '<li>Yellow/Warning: 506 - 1518 µg/m³ </li>' +
      '<li>Red/Alarm: > 1518 µg/m³ </li>' +
      '</ul>'
  ],
  vocsIndexInfoN: [
    '<h4>TVOC </h4>' +
      '<br/>Los niveles recomendados de TVOC para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 200 </li>' +
      '<li>Amarillo/Alerta: 200 - 400 </li>' +
      '<li>Rojo/Alarma: > 400 </li>' +
      '</ul>',
    '<h4> TVOCs  </h4>' +
      '<br/>Recommended TVOC levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 200 </li>' +
      '<li>Yellow/Warning: 200 - 400 </li>' +
      '<li>Red/Alarm: > 400 </li>' +
      '</ul>'
  ],
  pm1InfoN: [
    '<h4> PM1.0 </h4>' +
      '<br/>Los niveles recomendados de partículas en suspensión para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 12 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 12 - 35 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 35 µg/m³ </li>' +
      '</ul>',
    '<h4> PM1 </h4>' +
      '<br/>Recommended PM levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 12 µg/m³ </li>' +
      '<li>Yellow/Warning: 12 - 35 µg/m³ </li>' +
      '<li>Red/Alarm: > 35 µg/m³ </li>' +
      '</ul>'
  ],
  pm4InfoN: [
    '<h4> PM4.0 </h4>' +
      '<br/>Los niveles recomendados de partículas en suspensión para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 12 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 12 - 35 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 35 µg/m³ </li>' +
      '</ul>',
    '<h4> PM4 </h4>' +
      '<br/>Recommended PM levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 12 µg/m³ </li>' +
      '<li>Yellow/Warning: 12 - 35 µg/m³ </li>' +
      '<li>Red/Alarm: > 35 µg/m³ </li>' +
      '</ul>'
  ],
  pm25InfoN: [
    '<h4> PM2.5 </h4>' +
      '<br/>Los niveles recomendados de partículas en suspensión para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 12 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 12 - 35 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 35 µg/m³ </li>' +
      '</ul>',
    '<h4> PM2.5 </h4>' +
      '<br/>Recommended PM levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 12 µg/m³ </li>' +
      '<li>Yellow/Warning: 12 - 35 µg/m³ </li>' +
      '<li>Red/Alarm: > 35 µg/m³ </li>' +
      '</ul>'
  ],
  automatic: ['Automático', 'Automatic', 'Automatisch'],
  manual: ['Manual', 'Manual', 'Handbuch'],
  pm10InfoN: [
    '<h4> PM10 </h4>' +
      '<br/>Los niveles recomendados de partículas en suspensión para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 50 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 50 - 100 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 100 µg/m³ </li>' +
      '</ul>',
    '<h4> PM10 </h4>' +
      '<br/>Recommended PM levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 50 µg/m³ </li>' +
      '<li>Yellow/Warning: 50 - 100 µg/m³ </li>' +
      '<li>Red/Alarm: > 100 µg/m³ </li>' +
      '</ul>'
  ],
  covid19InfoN: [
    '<h4> Resistencia a virus </h4>' +
      '<br/>Los niveles recomendados del indicador de resistencia a virus para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 71 - 100</li>' +
      '<li>Amarillo/Alerta: 41 - 70 </li>' +
      '<li>Rojo/Alarma: 0 - 40</li>' +
      '</ul>',
    '<h4> Virus Indicator </h4>' +
      '<br/>The recommended virus resistance indicator levels for a healthy space are :<br/>' +
      '<ul>' +
      '<li>Green/Safe: 71 - 100</li>' +
      '<li>Yellow/Warning: 41 - 70</li>' +
      '<li>Red/Alarm: 0 - 40</li>' +
      '</ul>'
  ],
  ventilationIndicatorInfoN: [
    '<h4> Eficacia de ventilación </h4>' +
      '<br/>Los niveles recomendados del indicador de eficacia de ventilación para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 71 - 100</li>' +
      '<li>Amarillo/Alerta: 41 - 70 </li>' +
      '<li>Rojo/Alarma: 0 - 40</li>' +
      '</ul>',
    '<h4> Ventilation Efficiency </h4>' +
      '<br/>The recommended values of the ventilation efficiency indicator for a healthy room are :<br/>' +
      '<ul>' +
      '<li>Green/Safe: 71 - 100</li>' +
      '<li>Yellow/Warning: 41 - 70</li>' +
      '<li>Red/Alarm: 0 - 40</li>' +
      '</ul>'
  ],
  iaqInfoN: [
    '<h4> Calidad del Aire Interior </h4>' +
      '<br/>Los niveles recomendados de indicador Calidad del Aire Interior para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 71 - 100</li>' +
      '<li>Amarillo/Alerta: 41 - 70</li>' +
      '<li>Rojo/Alarma: 0 - 40</li>' +
      '</ul>',
    '<h4> Indoor Air Quality </h4>' +
      '<br/>Recommended Indoor Air Quality indicator levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 71 - 100</li>' +
      '<li>Yellow/Warning: 41 - 70</li>' +
      '<li>Red/Alarm: 0 - 40</li>' +
      '</ul>'
  ],
  thermalIndicatorInfoN: [
    '<h4> Indicador térmico </h4>' +
      '<br/>Los niveles recomendados de indicador térmico para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 71 - 100</li>' +
      '<li>Amarillo/Alerta: 41 - 70</li>' +
      '<li>Rojo/Alarma: 0 - 40</li>' +
      '</ul>',
    '<h4> Thermal indicatorr </h4>' +
      '<br/>Recommended Thermal indicator levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 71 - 100</li>' +
      '<li>Yellow/Warning: 41 - 70</li>' +
      '<li>Red/Alarm: 0 - 40</li>' +
      '</ul>'
  ],
  no2InfoN: [
    '<h4> NO₂ </h4>' +
      '<br/>Los niveles recomendados de NO₂ para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 21 ppb </li>' +
      '<li>Amarillo/Alerta: 21 - 105 ppb </li>' +
      '<li>Rojo/Alarma: > 105 ppb </li>' +
      '</ul>',
    '<h4> NO₂ </h4>' +
      '<br/>Recommended NO₂  levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 21 ppb </li>' +
      '<li>Yellow/Warning: 21 - 105 ppb </li>' +
      '<li>Red/Alarm: > 105 ppb </li>' +
      '</ul>'
  ],
  no2InfoNUg: [
    '<h4> NO₂ </h4>' +
      '<br/>Los niveles recomendados de NO₂ para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 40 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 40 - 198 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 198 µg/m³ </li>' +
      '</ul>',
    '<h4> NO₂ </h4>' +
      '<br/>Recommended NO₂  levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 40 µg/m³ </li>' +
      '<li>Yellow/Warning: 40 - 198 µg/m³ </li>' +
      '<li>Red/Alarm: > 198 µg/m³ </li>' +
      '</ul>'
  ],

  inactivityInfoN: [
    '<h4> Inactividad </h4>' +
      '<br/>Indica cuanto tiempo de inactividad debe transcurrir para que se genere la notificación.<br/>',
    '<h4> Inactivity </h4>' +
      '<br/>Indicates how much time of inactivity must pass before the notification is generated.<br/>'
  ],

  errorInfoN: [
    '<h4> Error </h4>' +
      '<br/>Indica el parámetro del sensor que te interese para cuando este fallé se genere una notificación.<br/>',
    '<h4> Error </h4>' +
      '<br/>Indicate the parameter of the sensor that interests you so that when this fails, a notification is generated.<br/>'
  ],

  emailInfoN: [
    '<h4> Correos </h4>' +
      '<br/>Las notificaciones de las alarmas llegarán a los correos seleccionados. Los correos en gris mas claro están pendietes de verificar, estás personas no recbiran notificaciones hasta que verifiquen su correo.<br/>',
    '<h4> Emails </h4>' +
      '<br/>Alarm notifications will reach the selected emails. The emails in lighter gray are pending verification, these people will not receive notifications until they verify their email.<br/>'
  ],
  newUserInfoN: [
    '<h4> Nuevo usuario </h4>' +
      '<br/>Se creará automáticamente una cuenta en My inBiot con el correo indicado (si no existía previamente). El usuario debe aceptar la invitación en el correo electrónico para acceder a los dispositivos y completar la información de la cuenta si esta fuese nueva. <br/>',
    '<h4> New user </h4>' +
      '<br/>A My inBiot account will be automatically created with the specified email address (if it does not already exist). The user must accept the email invitation to access the devices and complete the account information if the account is new.<br/>',
    '<h4> Neuer Benutzer </h4>' +
      '<br/>Es wird automatisch ein My inBiot-Konto mit der angegebenen E-Mail-Adresse erstellt (falls es nicht bereits existiert). Der Benutzer muss die E-Mail-Einladung akzeptieren, um auf die Geräte zuzugreifen, und die Kontoinformationen vervollständigen, wenn das Konto neu ist.<br/>'
  ],
  rolesInfoN: [
    '<h4> Descripción de los roles </h4>' +
      '<br/><b>Visualización</b>: Ver los datos de los dispositivos en tiempo real y crear/editar/borrar compartibles (links, QR y pantalla completa).<br/>' +
      '<br/><b>Manager</b>: Lo mismo que el rol de visualización + editar la información y configuración de los dispositivos, utilizar la descarga de datos, descargar informes inteligentes y reportes de las certificaciones.<br/>',
    '<h4> Roles description </h4>' +
      '<br/><b>Viewer</b>: View device data in real time and create/edit/delete shareables (links, QR and full screen).<br/>' +
      '<br/><b>Manager</b>: Same as visualisation role + edit device information and configuration, use data download, download smart reports and certification reports.<br/>',
    '<h4> Beschreibung der Rollen </h4>' +
      '<br/><b>Betrachter</b>: Zeigt Gerätedaten in Echtzeit an und erstellt/bearbeitet/löscht Shareables (Links, QR und Vollbild).<br/>' +
      '<br/><b>Verwalter</b>: Gleiche Rolle wie Betrachter + Bearbeiten von Geräteinformationen und -konfiguration, Verwendung des Datendownloads, Herunterladen von Smart Reports und Zertifizierungsberichten.<br/>'
  ],

  privacyPolicy: [
    `<div>
    <p style="margin-top:0pt; margin-bottom:15pt; text-align:center; ">
      <strong><span style="font-size: 28px">POLÍTICA DE PRIVACIDAD</span></strong>
    </p>
    <p style="margin-top:0pt; margin-bottom:15pt; text-align:center; ">
      <strong><u><span style=" ">www.myinbiot.com</span></u></strong>
    </p>
    <p style="margin-top:0pt; margin-bottom:15pt; text-align:justify; ">
      <strong><span style=" ">&#xa0;</span></strong>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >INBIOT MONITORING S.L. ““INBIOT”), es titular del sitio web </span><a
        href="https://myinbiot.com" style="text-decoration:none"><u><span
            style=" color:#0563c1">https://myinbiot.com</span></u></a><span
        > (“Sitio Web”). La presente Política de Privacidad está destinada a informar a
        los clientes de INBIOT sobre cómo se recopilan, utilizan y protegen sus datos personales cuando acceden y utilizan
        los servicios ofrecidos a través del Sitio Web. Esta política está alineada con:</span>
    </p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <span >El Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27
          de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos
          personales y a la libre circulación de estos datos (“RGPD”).</span>
      </li>
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <span >La Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
          Personales y garantía de los derechos digitales (“LOPD-GDD").</span>
      </li>
      <li
        style="margin-left:28.06pt; margin-bottom:19.2pt; text-align:justify;  padding-left:7.94pt; ">
        <span >La Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
          Información y de Comercio Electrónico (“LSSI-CE").</span>
      </li>
    </ul>
    <p style="margin-top:0pt; margin-bottom:15pt; text-align:justify; ">
      <strong><span style=" ">&#xa0;</span></strong>
    </p>
    <ol type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Identidad del Responsable del Tratamiento
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >El responsable del tratamiento de los datos personales recogidos
        en</span><span >&#xa0;</span><span >el Sitio
        Web es</span><span >&#xa0;</span><span
        >INBIOT MONITORING S.L. con CIF:</span><span
        >&#xa0;</span><span >B71361596</span><span
        >&#xa0;</span><span >(en adelante,
        “Responsable del tratamiento”), inscrita en el Registro Mercantil de Navarra, al tomo 1922, folio 1, hoja
        NA-38.214. La dirección es Paseo Santxiki 2, LB5, 31192 Mutilva (Navarra). El número de teléfono de contacto es
        948 335 126 y el correo electrónico </span><a href="mailto:info@inbiot.com" style="text-decoration:none">
            <u><span
            style=" color:#0563c1">&#xa0;</span></u><u><span
            style=" color:#0563c1">info@inbiot.com</span></u></a><span
        > </span>
    </p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; ">
      <span >INBIOT ha designado un Delegado de Protección de Datos: Eleva Legal
        España, SL con correo electrónico: </span><a href="mailto:dpd@eleva.legal" style="text-decoration:none"><u><span
            style=" color:#0563c1">dpd@eleva.legal</span></u></a>
    </p>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:15pt; text-align:justify; ">
      <strong><span style=" ">&#xa0;</span></strong>
    </p>
    <ol start="2" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Finalidad del tratamiento
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >Dependiendo de los productos, servicios o funcionalidad de los que quiera
        disfrutar en cada momento necesitaremos tratar unos datos u otros, que en general serán, según el caso, los
        siguientes:</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Datos identificativos: por ejemplo, tu nombre, apellidos, correo
        electrónico y país desde el que interactúas con nosotros.</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Datos sobre su preferencia en el sistema de mediciones: métrico o
        imperial.</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Datos de dispositivos: por ejemplo, tipo de dispositivo contratado
        con INBIOT.</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Información económica y transaccional: por ejemplo, información
        acerca de sus compras, pedidos, etc.</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:19.2pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Información comercial: por ejemplo, si está suscrito a nuestra
        newsletter.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >Debe tener en cuenta que, cuando le pidamos que proporcione sus datos
        personales para darle acceso a alguna funcionalidad o servicio del Sitio Web, le indicaremos algunos campos como
      </span><em><span style=" ">obligatorios</span></em><span
        >, puesto que son datos que necesitamos para poder prestarle el servicio o
        darle acceso a la funcionalidad en cuestión. Por favor, tenga en cuenta que si decide no facilitarnos esos datos,
        es posible que no pueda completar su registro como usuario o que no pueda disfrutar de esos servicios o
        funcionalidades.</span>
    </p>
    <table cellspacing="0" cellpadding="0" style="width: 100%; border:0.75pt solid #000000; border-collapse:collapse">
      <tr>
        <td
          style="width:152pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >FINALIDAD</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >+ INFO</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >1.Gestionar tu registro e identificación como usuario de la
              plataforma</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >En caso de que decida registrarse como usuario en nuestra plataforma
              necesitamos tratar sus datos para identificarle como usuario de la misma y darle acceso a sus diferentes
              funcionalidades, productos y servicios que están a su disposición como usuario registrado. Puede cancelar su
              cuenta de usuario registrado contactando con nosotros a través de Atención al Cliente o bien a través de su
              cuenta, apartado Configuración, botón “Eliminar Cuenta”.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >2.Para el desarrollo, cumplimiento y ejecución del contrato de
              compraventa o de servicios que haya contratado con nosotros en la plataforma.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Esta finalidad incluye el tratamiento de sus datos para
              principalmente,</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >Contactar con usted en relación con actualizaciones o
              comunicaciones informativas relacionadas con las funcionalidades, productos o servicios contratados,
              incluyendo el envío de encuestas de calidad sobre los productos o servicios prestados.</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >Gestionar el pago de los productos y servicios que adquiera
              independientemente del medio de pago utilizado.</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >Activar los mecanismos necesarios con objeto de prevenir y
              detectar el uso indebido de la plataforma, así como potenciales fraudes contra Ud. o contra nosotros.</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >Gestionar posibles cambios y gestionar solicitudes de
              información sobre la disponibilidad de productos, reserva de productos, según dichas opciones se encuentren
              disponibles en cada momento.</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >Con fines de facturación y para poner a su disposición los
              tickets y facturas de los productos y servicios que haya contratado.</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >Garantizar el uso de otras funcionalidades o servicios en la
              medida que estén disponibles.</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:19.2pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >Para el cumplimiento de obligaciones legales que resulten de
              aplicación.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >3.Para atender las solicitudes o peticiones que realice a través de
              los canales de atención al cliente.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Sólo tratamos los datos personales que sean estrictamente necesarios
              para gestionar o resolver su solicitud o petición. Si utiliza el canal telefónico, la llamada puede ser
              grabada para atender su petición y garantizar la calidad de la misma.</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Si está disponible y decide utilizar WhatsApp como canal para
              comunicarse con Atención al Cliente, facilitaremos tu número de teléfono a Whatsapp Inc. (sociedad ubicada
              en EEUU) para comprobar que es usuario de este servicio. Le recomendamos que revise su configuración de
              privacidad y lea la política de privacidad de WhatsApp para obtener información más detallada sobre el uso
              que hace WhatsApp de los datos personales de los usuarios que utilizan sus servicios.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >4.Para finalidades de marketing.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Esta finalidad incluye el tratamiento de sus datos para,
              principalmente:</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >Personalizar los servicios que le ofrecemos y poder hacerle
              recomendaciones en función de su interacción con nosotros en la plataforma y del análisis de su perfil de
              usuario.</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >En la medida en que se suscriba a nuestra newsletter,
              trataremos sus datos personales para gestionar su suscripción.</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:19.2pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >Llevar a cabo acciones promocionales.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >5.Análisis de usabilidad y de calidad para la mejora de nuestros
              servicios.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Si accede a nuestra plataforma, le informamos de que trataremos sus
              datos para fines analíticos y estadísticos, es decir, para entender la forma en la que los usuarios
              interactúan con nuestra plataforma y con las acciones que podemos llevar a cabo y así ser capaces de
              introducir mejoras.</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Asimismo, en ocasiones realizamos acciones y encuestas de calidad
              destinadas a conocer el grado de satisfacción de nuestros clientes y usuarios y detectar aquellas áreas en
              las que podemos mejorar</span>
          </p>
        </td>
      </tr>
    </table>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <ol start="3" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Base legal para el tratamiento
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >La base legal que nos permite tratar sus datos personales también depende
        de la finalidad para la que los tratemos.</span>
    </p>
    <table cellspacing="0" cellpadding="0" style="width: 100%; border:0.75pt solid #000000; border-collapse:collapse">
      <tr>
        <td
          style="width:152pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >FINALIDAD</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >LEGITIMACIÓN</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >1.Gestionar tu registro e identificación como usuario de la
              plataforma</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >El tratamiento de sus datos es necesario para la ejecución de los
              términos que regulan el uso de la plataforma. En otras palabras, para que puedas registrarte como usuario en
              la plataforma, necesitamos tratar sus datos personales, ya que de lo contrario no podríamos gestionar su
              registro.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >2.Para el desarrollo, cumplimiento y ejecución del contrato de
              compraventa o de servicios que haya contratado con nosotros en la plataforma.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >El tratamiento de sus datos es necesario para la ejecución del
              contrato de compraventa o de prestación de servicios que nos vincule con Ud. </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >3.Para atender las solicitudes o peticiones que realice a través de
              los canales de atención al cliente.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Consideramos que tenemos un interés legítimo para atender las
              solicitudes o consultas que nos plantee a través de los diversos medios de contacto existentes. Entendemos
              que el tratamiento de estos datos resulta también beneficioso para Ud. en tanto que nos permite poder
              atenderle adecuadamente y resolver las consultas planteadas.</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Cuando nos contacte, en particular, para la gestión de incidencias
              relacionadas con su pedido o el producto/servicio adquirido, el tratamiento es necesario para le ejecución
              del contrato que nos une.</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Cuando su consulta esté relacionada con el ejercicio de los derechos
              sobre los que le informamos más abajo, o con reclamaciones relacionadas con nuestros productos o servicios,
              lo que nos legitima para tratar sus datos es el cumplimiento de obligaciones legales por nuestra
              parte.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >4.Para finalidades de marketing.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >La base legítima para tratar sus datos con finalidades de marketing
              es el consentimiento que nos presta.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >5.Análisis de usabilidad y de calidad para la mejora de nuestros
              servicios.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Consideramos que tenemos un interés legítimo para analizar la
              usabilidad de la plataforma y el grado de satisfacción del usuario ya que entendemos que el tratamiento de
              estos datos también resulta beneficioso para Ud. porque la finalidad es mejorar la experiencia del usuario y
              ofrecer un servicio de mayor calidad.</span>
          </p>
        </td>
      </tr>
    </table>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <ol start="4" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Períodos de conservación de los datos personales
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >El plazo de conservación de sus datos dependerá de las finalidades para
        las que los tratemos, según lo explicado a continuación:</span>
    </p>
    <table cellspacing="0" cellpadding="0" style="width: 100%; border:0.75pt solid #000000; border-collapse:collapse">
      <tr>
        <td
          style="width:152pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >FINALIDAD</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >PLAZO DE CONSERVACIÓN</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >1.Gestionar tu registro e identificación como usuario de la
              plataforma</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Trataremos sus datos durante el tiempo en que mantenga la condición
              de usuario registrado, es decir, hasta que decida eliminar su cuenta.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >2.Para el desarrollo, cumplimiento y ejecución del contrato de
              compraventa o de servicios que haya contratado con nosotros en la plataforma.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Trataremos sus datos durante el tiempo necesario para gestionar la
              compra de los productos o de los servicios, incluyendo posibles quejas o reclamaciones asociadas a la compra
              del producto o servicio en particular.</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >&#xa0;</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >3.Para atender las solicitudes o peticiones que realice a través de
              los canales de atención al cliente.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Trataremos sus datos durante el tiempo que sea necesario para
              atender su solicitud o petición, así como durante los tiempos establecidos en las normativas que en cada
              caso resulten de aplicación.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >4.Para finalidades de marketing.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Trataremos sus datos hasta que se dé de baja o cancele su
              suscripción a la newsletter.</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Si participa en acciones promocionales conservaremos sus datos por
              un plazo de seis (6) meses desde que finalice la acción.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >5.Análisis de usabilidad y de calidad para la mejora de nuestros
              servicios.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Trataremos sus datos puntualmente durante el tiempo en el que
              procedamos a realizar una acción o encuesta de calidad concreta o hasta que anonimicemos sus datos de
              navegación.</span>
          </p>
        </td>
      </tr>
    </table>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >Independientemente de que tratemos sus datos durante el tiempo
        estrictamente necesario para cumplir con la finalidad correspondiente, los conservaremos posteriormente
        debidamente guardados, bloqueados y protegidos durante el tiempo en que pudieran surgir responsabilidades
        derivadas del tratamiento. Una vez prescriban las posibles acciones en cada caso, procederemos a la supresión de
        los datos personales. </span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <ol start="5" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Destinatarios de los datos personales
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >Los datos personales del Usuario (no cliente) únicamente se intercambiarán
        con terceros destinatarios, para las finalidades previstas en esta Política de Privacidad, con el objetivo de
        realizar los tratamientos indicados con las finalidades descritas. </span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >Para ello, INBIOT podrá contar con proveedores de confianza que podrán
        tener acceso a datos personales, que actuarán como encargados de tratamiento y que estarán obligados
        contractualmente a cumplir con sus obligaciones legales de encargado de tratamiento, a mantener la
        confidencialidad y secreto de la información, a saber:</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Entidades financieras</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Prestadores de servicios tecnológicos y analítica.</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Proveedores y colaboradores de servicios de logística, transporte y
        entrega o sus establecimientos asociados.</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Administraciones públicas.</span>
    </p>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; ">
      <strong><span style=" ">&#xa0;</span></strong>
    </p>
    <ol start="6" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Transferencias internacionales de datos
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:15pt; text-align:justify; ">
      <span >No hay.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:15pt; text-align:justify; ">
      <strong><span style=" ">&#xa0;</span></strong>
    </p>
    <ol start="7" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Confidencialidad y seguridad de los datos personales
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >El responsable del Tratamiento se compromete a adoptar las medidas
        técnicas y organizativas necesarias, según el nivel de seguridad adecuado al riesgo de los datos recogidos, de
        forma que se garantice la seguridad de los datos de carácter personal y se evite la destrucción, pérdida o
        alteración accidental o ilícita de datos personales transmitidos, conservados o tratados de otra forma, o la
        comunicación o acceso no autorizados a dichos datos.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >El Sitio Web cuenta con un certificado SSL (Secure Socket Layer), que
        asegura que los datos personales se transmiten de forma segura y confidencial, al ser la transmisión de los datos
        entre el servidor y el Usuario, y en retroalimentación, totalmente cifrada o encriptada.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >Sin embargo, debido a que</span><span
        >&#xa0;</span><span >el responsable del
        tratamiento no puede garantizar la inexpugnabilidad de internet ni la ausencia total de hackers u otros que
        accedan de modo fraudulento a los datos personales, el Responsable del tratamiento se compromete a comunicar al
        Usuario sin dilación indebida cuando ocurra una violación de la seguridad de los datos personales que sea probable
        que entrañe un alto riesgo para los derechos y libertades de las personas físicas. Siguiendo lo establecido en el
        artículo 4 del RGPD, se entiende por violación de la seguridad de los datos personales toda violación de la
        seguridad que ocasione la destrucción, pérdida o alteración accidental o ilícita de datos personales transmitidos,
        conservados o tratados de otra forma, o la comunicación o acceso no autorizados a dichos datos.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >Los datos personales serán tratados como confidenciales por el Responsable
        del tratamiento, quien se compromete a informar de y a garantizar por medio de una obligación legal o contractual
        que dicha confidencialidad sea respetada por sus empleados, asociados, y toda persona a la cual le haga accesible
        la información.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <ol start="8" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Cookies
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >La política de cookies puede consultarse en el apartado específico de <a href="../cookies">cookies</a>. </span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <ol start="9" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Derechos derivados del tratamiento de los datos personales
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >El Usuario podrá ejercer frente al Responsable del tratamiento los
        siguientes derechos reconocidos en el RGPD y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
        Personales y garantía de los derechos digitales:</span>
    </p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <em><span style=" ">Derecho de acceso:</span></em><span
          >&#xa0;</span><span >Es el derecho del
          Usuario a obtener confirmación de si</span><span >&#xa0;</span><span
          >el Responsable del Tratamiento está tratando o no sus datos personales y, en
          caso afirmativo, obtener información sobre sus datos concretos de carácter personal y del tratamiento
          que</span><span >&#xa0;</span><span >haya
          realizado o realice, así como, entre otra, de la información disponible sobre el origen de dichos datos y los
          destinatarios de las comunicaciones realizadas o previstas de los mismos.</span>
      </li>
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <em><span style=" ">Derecho de rectificación:</span></em><span
          >&#xa0;</span><span >Es el derecho del
          Usuario a que se modifiquen sus datos personales que resulten ser inexactos o, teniendo en cuenta los fines del
          tratamiento, incompletos.</span>
      </li>
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <em><span style=" ">Derecho de supresión («el derecho al olvido»):</span></em><span
          >&#xa0;</span><span >Es el derecho del
          Usuario, siempre que la legislación vigente no establezca lo contrario, a obtener la supresión de sus datos
          personales cuando estos ya no sean necesarios para los fines para los cuales fueron recogidos o tratados; el
          Usuario haya retirado su consentimiento al tratamiento y este no cuente con otra base legal; el Usuario se
          oponga al tratamiento y no exista otro motivo legítimo para continuar con el mismo; los datos personales hayan
          sido tratados ilícitamente; los datos personales deban suprimirse en cumplimiento de una obligación legal; o los
          datos personales hayan sido obtenidos producto de una oferta directa de servicios de la sociedad de la
          información a un menor de 14 años. Además de suprimir los datos, el Responsable del tratamiento, teniendo en
          cuenta la tecnología disponible y el coste de su aplicación, deberá adoptar medidas razonables para informar a
          los responsables que estén tratando los datos personales de la solicitud del interesado de supresión de
          cualquier enlace a esos datos personales.</span>
      </li>
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <em><span style=" ">Derecho a la limitación del tratamiento:</span></em><span
          >&#xa0;</span><span >Es el derecho del
          Usuario a limitar el tratamiento de sus datos personales. El Usuario tiene derecho a obtener la limitación del
          tratamiento cuando impugne la exactitud de sus datos personales; el tratamiento sea ilícito; el Responsable del
          tratamiento ya no necesite los datos personales, pero el Usuario lo necesite para hacer reclamaciones; y cuando
          el Usuario se haya opuesto al tratamiento.</span>
      </li>
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <em><span style=" ">Derecho a la portabilidad de los datos:</span></em><span
          >&#xa0;</span><span >En caso de que el
          tratamiento se efectúe por medios automatizados, el Usuario tendrá derecho a recibir del Responsable del
          tratamiento sus datos personales en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos
          a otro responsable del tratamiento. Siempre que sea técnicamente posible, el Responsable del tratamiento
          transmitirá directamente los datos a ese otro responsable.</span>
      </li>
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <em><span style=" ">Derecho de oposición:</span></em><span
          >&#xa0;</span><span >Es el derecho del
          Usuario a que no se lleve a cabo el tratamiento de sus datos de carácter personal o se cese el tratamiento de
          los mismos. </span>
      </li>
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <em><span style=" ">Derecho a no ser a no ser objeto de una decisión basada únicamente
            en el tratamiento automatizado, incluida la elaboración de perfiles:</span></em><span
          >&#xa0;</span><span >Es el derecho del
          Usuario a no ser objeto de una decisión individualizada basada únicamente en el tratamiento automatizado de sus
          datos personales, incluida la elaboración de perfiles, existente salvo que la legislación vigente establezca lo
          contrario.</span>
      </li>
    </ul>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; ">
      <em><span style=" ">&#xa0;</span></em>
    </p>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; ">
      <em><span style=" ">&#xa0;</span></em>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >Así pues, el Usuario podrá ejercitar sus derechos dirigiéndose por escrito
        al Responsable del tratamiento a la dirección postal o al email indicado en este documento, acreditando su
        identidad, e indicando el derecho que se quiere ejercer. </span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <ol start="10" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:36pt; margin-bottom:15pt; text-align:justify;   font-weight:bold">
        Reclamaciones ante la autoridad de control
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >En caso de que el Usuario considere que existe un problema o infracción de
        la normativa vigente en la forma en la que se están tratando sus datos personales, tendrá derecho a la tutela
        judicial efectiva y a presentar una reclamación ante una autoridad de control, en particular, en el Estado en el
        que tenga su residencia habitual, lugar de trabajo o lugar de la supuesta infracción. En el caso de España, la
        autoridad de control es la Agencia Española de Protección de Datos (</span><a href="https://www.aepd.es/"
        style="text-decoration:none"><u><span
            style=" color:#0563c1">https://www.aepd.es/</span></u></a><span
        >). </span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <ol start="11" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:36pt; margin-bottom:15pt; text-align:justify;   font-weight:bold">
        Aceptación y cambios en esta política de privacidad
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >Es necesario que el Usuario haya leído y esté conforme con las condiciones
        sobre la protección de datos de carácter personal contenidas en esta Política de Privacidad, así como que acepte
        el tratamiento de sus datos personales para que el Responsable del tratamiento pueda proceder al mismo en la
        forma, durante los plazos y para las finalidades indicadas. El uso del Sitio Web implicará la aceptación de la
        Política de Privacidad de este.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >El Responsable del Tratamiento se reserva el derecho a modificar su
        Política de Privacidad, de acuerdo a su propio criterio, o motivado por un cambio legislativo, jurisprudencial o
        doctrinal de la Agencia Española de Protección de Datos. Los cambios o actualizaciones de esta Política de
        Privacidad no serán notificados de forma explícita al Usuario. Se recomienda al Usuario consultar esta página de
        forma periódica para estar al tanto de los últimos cambios o actualizaciones.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; ">
      <span >Esta Política de Privacidad fue actualizada para adaptarse al Reglamento
        (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las
        personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos
        (RGPD) y a la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos
        digitales.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
      <span >&#xa0;</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
      <span >&#xa0;</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
      <strong><span style=" ">Fecha Última actualización:</span></strong><span
        > 17 de septiembre de 2024.</span>
    </p>
    <div style="clear:both">
      <table cellspacing="0" cellpadding="0" style="border-collapse:collapse">
        <tr style="height:15pt">
          <td style="width:130.7pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
            <p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt">
              &#xa0;
            </p>
          </td>
          <td style="width:130.7pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:11pt">
              &#xa0;
            </p>
          </td>
          <td style="width:130.7pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
            <p style="margin-top:0pt; margin-bottom:0pt; text-align:right; font-size:11pt">
              &#xa0;
            </p>
          </td>
        </tr>
      </table>
      <p style="margin-top:0pt; margin-bottom:0pt; ">
        &#xa0;
      </p>
    </div>
  </div> `,
    `<div>
    <p style="margin-top:0pt; margin-bottom:15pt; text-align:center; ">
      <strong><span style="font-size: 28px">PRIVACY POLICY</span></strong>
    </p>
    <p style="margin-top:0pt; margin-bottom:15pt; text-align:center; ">
      <strong><u><span style=" ">www.myinbiot.com</span></u></strong>
    </p>
    <p style="margin-top:0pt; margin-bottom:15pt; text-align:justify; ">
      <strong><span style=" ">&#xa0;</span></strong>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >INBIOT MONITORING S.L. ("INBIOT") is the owner of the website </span><a
        href="https://myinbiot.com" style="text-decoration:none"><u><span
            style=" color:#0563c1">https://myinbiot.com</span></u></a><span
        > ("Website"). This Privacy Policy aims to inform INBIOT's customers about how their personal data is collected, used, and protected when accessing and using the services offered through the Website. This policy is aligned with:</span>
    </p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <span >Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (GDPR).</span>
      </li>
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <span >Organic Law 3/2018, of 5 December, on the Protection of Personal Data and the Guarantee of Digital Rights ("LOPD-GDD").</span>
      </li>
      <li
        style="margin-left:28.06pt; margin-bottom:19.2pt; text-align:justify;  padding-left:7.94pt; ">
        <span >Law 34/2002, of 11 July, on Information Society Services and Electronic Commerce ("LSSI-CE").</span>
      </li>
    </ul>
    <p style="margin-top:0pt; margin-bottom:15pt; text-align:justify; ">
      <strong><span style=" ">&#xa0;</span></strong>
    </p>
    <ol type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Identity of the Data Controller
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >The data controller for the personal data collected on</span><span >&#xa0;</span><span >the Website is</span><span >&#xa0;</span><span
        >INBIOT MONITORING S.L. with Tax ID Number:</span><span
        >&#xa0;</span><span >B71361596</span><span
        >&#xa0;</span><span >(hereinafter, "Data Controller"), registered in the Commercial Registry of Navarra, in volume 1922, folio 1, sheet NA-38.214. The address is Paseo Santxiki 2, LB5, 31192 Mutilva (Navarra). The contact telephone number is 948 335 126 and the email address is </span><a href="mailto:info@inbiot.com" style="text-decoration:none">
            <u><span
            style=" color:#0563c1">&#xa0;</span></u><u><span
            style=" color:#0563c1">info@inbiot.com</span></u></a><span
        > </span>
    </p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; ">
      <span >INBIOT has appointed a Data Protection Officer: Eleva Legal España, SL with email: </span><a href="mailto:dpd@eleva.legal" style="text-decoration:none"><u><span
            style=" color:#0563c1">dpd@eleva.legal</span></u></a>
    </p>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:15pt; text-align:justify; ">
      <strong><span style=" ">&#xa0;</span></strong>
    </p>
    <ol start="2" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Purpose of Processing
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >Depending on the products, services, or functionality you wish to enjoy at any given time, we may need to process certain data, which generally will be, depending on the case, as follows:</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Identification data: for example, your name, surname, email address, and country from which you interact with us.</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Data about your preference in the measurement system: metric or imperial.</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Device data: for example, the type of device contracted with INBIOT.</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Economic and transactional information: for example, information about your purchases, orders, etc.</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:19.2pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Commercial information: for example, if you are subscribed to our newsletter.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >Please note that when we ask you to provide your personal data to give you access to any feature or service of the Website, we will indicate some fields as </span><em><span style=" ">mandatory</span></em><span
        >, as they are data we need to provide the service or give you access to the functionality in question. Please note that if you choose not to provide this data, you may not be able to complete your user registration or enjoy these services or functionalities.</span>
    </p>
    <table cellspacing="0" cellpadding="0" style="width: 100%; border:0.75pt solid #000000; border-collapse:collapse">
      <tr>
        <td
          style="width:152pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >PURPOSE</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >+ INFO</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >1.Manage your registration and identification as a user of the platform</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >If you decide to register as a user on our platform, we need to process your data to identify you as a user and give you access to the different functionalities, products, and services available to you as a registered user. You can cancel your registered user account by contacting us through Customer Service or through your account, Settings section, "Delete Account" button.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >2.For the development, fulfillment, and execution of the purchase or service contract you have entered with us on the platform.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >This purpose includes processing your data for mainly,</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >Contact you regarding updates or informational communications related to the functionalities, products, or services contracted, including sending quality surveys about the products or services provided.</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >Manage the payment of products and services you purchase regardless of the payment method used.</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >Activate the necessary mechanisms to prevent and detect misuse of the platform, as well as potential fraud against you or us.</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >Manage possible changes and requests for information about the availability of products, product reservations, as such options become available from time to time.</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >For billing purposes and to provide you with tickets and invoices for the products and services you have contracted.</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >Ensure the use of other functionalities or services to the extent that they are available.</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:19.2pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >For compliance with legal obligations that apply.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >3.To respond to requests or inquiries made through customer service channels.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >We only process personal data that is strictly necessary to manage or resolve your request or inquiry. If you use the telephone channel, the call may be recorded to address your request and ensure its quality.</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >If it is available and you choose to use WhatsApp as a channel to communicate with Customer Service, we will provide your phone number to WhatsApp Inc. (a company located in the US) to verify that you are a user of this service. We recommend that you review your privacy settings and read WhatsApp's privacy policy for more detailed information on how WhatsApp uses personal data from users who use their services.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >4.For marketing purposes.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >This purpose includes processing your data mainly to:</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >Personalize the services we offer you and make recommendations based on your interaction with us on the platform and your user profile analysis.</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >To the extent you subscribe to our newsletter, we will process your personal data to manage your subscription.</span>
          </p>
          <p
            style="margin-top:0pt; margin-left:22.7pt; margin-bottom:19.2pt; text-indent:-18pt; text-align:justify; font-size:11pt">
            <span >-</span><span
              style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span><span >Carry out promotional actions.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >5.Usability and quality analysis to improve our services.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >If you access our platform, we inform you that we will process your data for analytical and statistical purposes, that is, to understand how users interact with our platform and the actions we can take and thus be able to introduce improvements.</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >Likewise, we occasionally conduct quality actions and surveys aimed at knowing the degree of satisfaction of our customers and users and detecting those areas in which we can improve</span>
          </p>
        </td>
      </tr>
    </table>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <ol start="3" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Legal Basis for Processing
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >The legal basis that allows us to process your personal data also depends on the purpose for which we process it.</span>
    </p>
    <table cellspacing="0" cellpadding="0" style="width: 100%; border:0.75pt solid #000000; border-collapse:collapse">
      <tr>
        <td
          style="width:152pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >PURPOSE</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >LEGAL BASIS</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >1.Manage your registration and identification as a user of the platform</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >The processing of your data is necessary for the execution of the terms that govern the use of the platform. In other words, to register as a user on the platform, we need to process your personal data; otherwise, we could not manage your registration.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >2.For the development, fulfillment, and execution of the purchase or service contract you have entered with us on the platform.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >The processing of your data is necessary for the execution of the purchase or service contract that binds us to you. </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >3.To respond to requests or inquiries made through customer service channels.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >We consider that we have a legitimate interest in responding to requests or inquiries you make through the various existing contact channels. We understand that the processing of this data is also beneficial to you as it allows us to adequately serve you and resolve the queries raised.</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >When you contact us, particularly for managing incidents related to your order or the product/service purchased, the processing is necessary for the execution of the contract that binds us.</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >When your inquiry is related to the exercise of the rights about which we inform you below, or with claims related to our products or services, what legitimizes us to process your data is compliance with legal obligations on our part.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >4.For marketing purposes.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >The legitimate basis for processing your data for marketing purposes is the consent you give us.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >5.Usability and quality analysis to improve our services.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >We consider that we have a legitimate interest in analyzing the platform's usability and user satisfaction level, as we understand that processing this data is also beneficial to you because the purpose is to improve the user experience and offer a higher quality service.</span>
          </p>
        </td>
      </tr>
    </table>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <ol start="4" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Retention periods for personal data
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >The retention period for your data will depend on the purposes for which we process it, as explained below:</span>
    </p>
    <table cellspacing="0" cellpadding="0" style="width: 100%; border:0.75pt solid #000000; border-collapse:collapse">
      <tr>
        <td
          style="width:152pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >PURPOSE</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >RETENTION PERIOD</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >1.Manage your registration and identification as a user of the platform</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >We will process your data for as long as you remain a registered user, i.e., until you decide to delete your account.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >2.For the development, fulfillment, and execution of the purchase or service contract you have entered with us on the platform.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >We will process your data for as long as necessary to manage the purchase of products or services, including any complaints or claims related to the purchase of the particular product or service.</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >&#xa0;</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >3.To respond to requests or inquiries made through customer service channels.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >We will process your data for as long as necessary to attend to your request or inquiry, as well as during the periods established in the regulations that may apply in each case.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >4.For marketing purposes.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >We will process your data until you unsubscribe or cancel your subscription to the newsletter.</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >If you participate in promotional actions, we will keep your data for a period of six (6) months from the end of the action.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width:152pt; border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >5.Usability and quality analysis to improve our services.</span>
          </p>
        </td>
        <td
          style="width:251.1pt; border-top-style:solid; border-top-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
          <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; font-size:11pt">
            <span >We will process your data punctually for the time it takes to carry out a specific quality action or survey or until we anonymize your browsing data.</span>
          </p>
        </td>
      </tr>
    </table>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >Regardless of whether we process your data for the time strictly necessary to fulfill the corresponding purpose, we will subsequently keep them properly stored, blocked, and protected for as long as liabilities arising from the processing may arise. Once the possible actions have expired in each case, we will proceed to delete the personal data. </span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <ol start="5" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Recipients of Personal Data
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >The User's (non-customer) personal data will only be shared with third-party recipients for the purposes set out in this Privacy Policy to carry out the processing indicated for the described purposes. </span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >For this purpose, INBIOT may have trusted providers who may have access to personal data, who will act as data processors and who will be contractually bound to comply with their legal obligations as data processors, to maintain confidentiality and secrecy of the information, as follows:</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Financial entities</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Technology and analytics service providers.</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Logistics, transport, and delivery service providers and their associated establishments.</span>
    </p>
    <p
      style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; ">
      <span >-</span><span
        style="width:14.63pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
      </span><span >Public administrations.</span>
    </p>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; ">
      <strong><span style=" ">&#xa0;</span></strong>
    </p>
    <ol start="6" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        International Data Transfers
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:15pt; text-align:justify; ">
      <span >None.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:15pt; text-align:justify; ">
      <strong><span style=" ">&#xa0;</span></strong>
    </p>
    <ol start="7" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Confidentiality and Security of Personal Data
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >The Data Controller undertakes to adopt the necessary technical and organizational measures, according to the appropriate level of security to the risk of the data collected, to guarantee the security of personal data and avoid accidental or unlawful destruction, loss, or alteration of personal data transmitted, stored, or otherwise processed, or unauthorized communication or access to such data.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >The Website has an SSL (Secure Socket Layer) certificate, which ensures that personal data is transmitted securely and confidentially, as the data transmission between the server and the User, and in feedback, is fully encrypted or encrypted.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >However, because</span><span >&#xa0;</span><span >the Data Controller cannot guarantee the invulnerability of the Internet or the total absence of hackers or others who access personal data fraudulently, the Data Controller undertakes to communicate to the User without undue delay when a breach of the security of personal data occurs that is likely to result in a high risk to the rights and freedoms of natural persons. According to Article 4 of the GDPR, a personal data breach is understood as any security breach that results in the accidental or unlawful destruction, loss, or alteration of personal data transmitted, stored, or otherwise processed, or unauthorized communication or access to such data.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >Personal data will be treated as confidential by the Data Controller, who undertakes to inform and guarantee by legal or contractual obligation that such confidentiality is respected by its employees, associates, and anyone to whom it makes the information accessible.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <ol start="8" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Cookies
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >The cookies policy can be consulted in the specific section on <a href="../cookies">cookies</a>. </span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <ol start="9" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:31.27pt; margin-bottom:15pt; text-align:justify;  padding-left:4.73pt;  font-weight:bold">
        Rights Derived from the Processing of Personal Data
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >The User may exercise the following rights recognized in the GDPR and Organic Law 3/2018, of 5 December, on the Protection of Personal Data and the Guarantee of Digital Rights:</span>
    </p>
    <ul type="disc" style="margin:0pt; padding-left:0pt">
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <em><span style=" ">Right of access:</span></em><span
          >&#xa0;</span><span >The User's right to obtain confirmation as to whether</span><span >&#xa0;</span><span
          >the Data Controller is processing their personal data or not, and, if so, to obtain information about their specific personal data and the processing that</span><span >&#xa0;</span><span >has been or is being carried out, as well as, among other things, information available on the origin of such data and the recipients of the communications made or planned for them.</span>
      </li>
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <em><span style=" ">Right to rectification:</span></em><span
          >&#xa0;</span><span >The User's right to have their personal data modified if they are inaccurate or, considering the purposes of the processing, incomplete.</span>
      </li>
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <em><span style=" ">Right to erasure ("the right to be forgotten"):</span></em><span
          >&#xa0;</span><span >The User's right, provided that current legislation does not establish otherwise, to obtain the erasure of their personal data when they are no longer necessary for the purposes for which they were collected or processed; the User has withdrawn their consent to processing and there is no other legal basis; the User objects to the processing and there is no other legitimate reason to continue; the personal data have been processed unlawfully; the personal data must be erased to comply with a legal obligation; or the personal data have been obtained as a result of an offer of information society services directly to a child under 14 years of age. In addition to erasing the data, the Data Controller, considering the technology available and the cost of its application, must take reasonable steps to inform the controllers processing the personal data of the data subject's request for the erasure of any links to such personal data.</span>
      </li>
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <em><span style=" ">Right to restriction of processing:</span></em><span
          >&#xa0;</span><span >The User's right to restrict the processing of their personal data. The User has the right to obtain the restriction of processing when they contest the accuracy of their personal data; the processing is unlawful; the Data Controller no longer needs the personal data, but the User needs them to make claims; and when the User has objected to the processing.</span>
      </li>
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <em><span style=" ">Right to data portability:</span></em><span
          >&#xa0;</span><span >If the processing is carried out by automated means, the User has the right to receive their personal data from the Data Controller in a structured, commonly used, and machine-readable format, and to transmit them to another data controller. Where technically possible, the Data Controller will transmit the data directly to that other controller.</span>
      </li>
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <em><span style=" ">Right to object:</span></em><span
          >&#xa0;</span><span >The User's right not to have their personal data processed or to cease processing them. </span>
      </li>
      <li style="margin-left:28.06pt; text-align:justify;  padding-left:7.94pt; ">
        <em><span style=" ">Right not to be subject to a decision based solely on automated processing, including profiling:</span></em><span
          >&#xa0;</span><span >The User's right not to be subject to an individualized decision based solely on automated processing of their personal data, including profiling, except where current legislation provides otherwise.</span>
      </li>
    </ul>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; ">
      <em><span style=" ">&#xa0;</span></em>
    </p>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; ">
      <em><span style=" ">&#xa0;</span></em>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >Thus, the User may exercise their rights by writing to the Data Controller at the postal address or email indicated in this document, proving their identity, and indicating the right they wish to exercise. </span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <ol start="10" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:36pt; margin-bottom:15pt; text-align:justify;   font-weight:bold">
        Complaints to the Supervisory Authority
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >If the User considers that there is a problem or infringement of current regulations in the way their personal data is being processed, they will have the right to effective judicial protection and to file a complaint with a supervisory authority, in particular in the State in which they have their habitual residence, place of work, or place of the alleged infringement. In the case of Spain, the supervisory authority is the Spanish Data Protection Agency (</span><a href="https://www.aepd.es/"
        style="text-decoration:none"><u><span
            style=" color:#0563c1">https://www.aepd.es/</span></u></a><span
        >). </span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >&#xa0;</span>
    </p>
    <ol start="11" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px">
      <li
        style="margin-left:36pt; margin-bottom:15pt; text-align:justify;   font-weight:bold">
        Acceptance and Changes to this Privacy Policy
      </li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >It is necessary for the User to have read and agree to the terms on the protection of personal data contained in this Privacy Policy, as well as to accept the processing of their personal data so that the Data Controller may proceed in the manner, during the periods, and for the purposes indicated. The use of the Website implies acceptance of its Privacy Policy.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:19.2pt; text-align:justify; ">
      <span >The Data Controller reserves the right to modify its Privacy Policy, at its discretion, or motivated by a legislative, jurisprudential, or doctrinal change by the Spanish Data Protection Agency. Changes or updates to this Privacy Policy will not be explicitly notified to the User. The User is advised to check this page periodically to stay informed of the latest changes or updates.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; ">
      <span >This Privacy Policy was updated to comply with Regulation (EU) 2016/679 of the European Parliament and Council, of 27 April 2016, on the protection of natural persons concerning the processing of personal data and the free movement of such data (GDPR) and Organic Law 3/2018, of 5 December, on the Protection of Personal Data and guarantee of digital rights.</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
      <span >&#xa0;</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
      <span >&#xa0;</span>
    </p>
    <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
      <strong><span style=" ">Last update date:</span></strong><span
        > September 17, 2024.</span>
    </p>
    <div style="clear:both">
      <table cellspacing="0" cellpadding="0" style="border-collapse:collapse">
        <tr style="height:15pt">
          <td style="width:130.7pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
            <p style="margin-top:0pt; margin-bottom:0pt; font-size:11pt">
              &#xa0;
            </p>
          </td>
          <td style="width:130.7pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
            <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:11pt">
              &#xa0;
            </p>
          </td>
          <td style="width:130.7pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
            <p style="margin-top:0pt; margin-bottom:0pt; text-align:right; font-size:11pt">
              &#xa0;
            </p>
          </td>
        </tr>
      </table>
      <p style="margin-top:0pt; margin-bottom:0pt; ">
        &#xa0;
      </p>
    </div>
  </div >`
  ],

  termsOfUse: [
    `<div>
    <div style="clear:both;">
        <p style="margin-top:0pt; margin-bottom:0pt; "><br></p>
    </div>
    <p style="margin-top:0pt; margin-bottom:15pt; text-align:center; ">
      <strong><span style="font-size: 28px">TÉRMINOS DE USO</span></strong>
    </p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  background-color:#fdfdfd;"><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#333333;">Al acceder y / o utilizar los servicios de My Inbiot (la "Plataforma") en </span><a href="http://www.myinbiot.com" style="text-decoration:none;"><u><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#0563c1;">www.myinbiot.com</span></u></a><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#333333;"> (el "Sitio Web") propiedad de INBIOT MONITORING, S.L. ("INBIOT"), usted acepta estos Términos de Uso y nuestra Política de Privacidad, ya sea en su propio nombre o en representación de la entidad que representa. Al hacerlo, usted declara y garantiza que tiene el derecho, la autoridad y la capacidad de aceptar y se compromete a cumplir con su contenido en su totalidad. El sujeto que navega por el Sitio Web y usa los servicios de la misma se define en adelante como "Usuario" y se compromete a aceptar y a respetar los presentes Términos de Uso.</span><span style="font-family:Roboto; font-size:10.5pt; color:#333333;">El Usuario, accediendo al Sitio Web o utilizando los Servicios ofrecidos, acepta comprometerse a respetar los presentes Términos de Uso. En caso de no aceptar los Términos de Uso, el sujeto no deberá usar los Servicios ofrecidos ni el Sitio Web.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT supondrá que cualquier usuario que utilice el servicio ha leído y aceptado los presentes Términos de Uso, por ello, INBIOT</span><span style="font-family:Roboto; color:#333333;"> ruega a los Usuarios comprobar periódicamente los Términos de Uso, también para controlar si existen actualizaciones o modificaciones, consultando la página Términos de Uso</span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">. Además, INBIOT se reserva el derecho de actualizar y modificar los Términos de Uso sin ningún tipo de aviso previo, estando estos disponibles siempre en el Sitio Web.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; "><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#333333;">El presente acuerdo tendrá validez a partir de la fecha en la que marque la casilla de aceptación de estos Términos de Uso, al darse de alta. Las nuevas características que pudieran añadirse a los Servicios estarán sujetas a las condiciones de uso. En caso de que el usuario continúe haciendo uso del Servicio después de cualquier modificación, supondrá su conformidad con dichas modificaciones. Se podrá consultar la versión más reciente de los Términos de Uso en </span><a href="https://myinbiot.com/terms" style="text-decoration:none;"><u><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#0563c1;">https://myinbiot.com/terms</span></u></a></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">El incumplimiento de cualquiera de los Términos de Uso podrá ser causa de anulación de la cuenta.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Si no acepta algunos de los Términos de Uso, no cree una cuenta en nuestra Plataforma.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Identificación del titular de la Plataforma</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">El titular del presente Sitio Web es:</span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt;">
        <li style="margin-top:14pt; margin-left:27.6pt; text-align:justify;  padding-left:8.4pt;  font-size:10pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;"><span style="font-family:Roboto; font-size:10.5pt;">Denominación social: </span><span style="font-family:Roboto; font-size:10.5pt;"></span><strong><span style="font-family:Roboto; font-size:10.5pt;">INBIOT MONITORING S.L.</span></strong></li>
        <li style="margin-left:27.6pt; text-align:justify;  padding-left:8.4pt;  font-size:10pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;"><span style="font-family:Roboto; font-size:10.5pt;">C.I.F.: B71361596</span></li>
        <li style="margin-left:27.6pt; text-align:justify;  padding-left:8.4pt;  font-size:10pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;"><span style="font-family:Roboto; font-size:10.5pt;">Domicilio Social: Paseo Santxiki, 2, Oficina LB5, 31192 Mutilva, Navarra (España).</span></li>
        <li style="margin-left:27.6pt; text-align:justify;  padding-left:8.4pt;  font-size:10pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;"><span style="font-family:Roboto; font-size:10.5pt;">Inscrita en el Registro Mercantil de Navarra,</span><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt;">al tomo 1922, folio 1, hoja NA-38.214</span></li>
        <li style="margin-left:27.6pt; margin-bottom:14pt; text-align:justify;  padding-left:8.4pt;  font-size:10pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;"><span style="font-family:Roboto; font-size:10.5pt;">E-mail de contacto: </span><a href="mailto:info@inbiot.es" style="text-decoration:none;"><u><span style="font-family:Roboto; font-size:10.5pt; color:#0563c1;">info@inbiot.es</span></u></a><span style="font-family:Roboto; font-size:10.5pt;"></span></li>
    </ul>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol start="2" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Condiciones de la cuenta</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:5pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">A efectos de los presentes Término de Uso, se entiende por Usuario a toda persona física o jurídica que acceda, registre o utilice los Servicios ofrecidos por INBIOT en la Plataforma.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Para acceder a los Servicios es imprescindible que el Usuario sea mayor de dieciocho (18) años.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">El Usuario deberá proporcionar un nombre legal completo, una dirección de correo electrónico, y toda la información requerida por INBIOT en el proceso de creación de la cuenta.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Es responsabilidad del Usuario proporcionar información veraz y precisa. INBIOT se reserva el derecho de eliminar cualquier cuenta si se sospecha de su falta de veracidad o incumplimiento de las normas de uso. Asimismo, e</span><span style="font-family:Roboto; color:#333333;">l Usuario es responsable de mantener actualizados, completos y correctos los datos facilitados en cada caso y de asumir cualquier daño derivado del incumplimiento de estas obligaciones.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">El Usuario reconoce y declara ser el único responsable de la veracidad y precisión de la información y de los datos personales proporcionados</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  background-color:#fdfdfd;"><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#333333;">El Usuario es responsable de mantener la privacidad de su cuenta. INBIOT no se hace responsable de cualquier daño o pérdida que pueda ser consecuencia de un error del Usuario al proteger su información de acceso. En caso de sospecha de uso no autorizado de su cuenta, el Usuario se compromete a notificar de inmediato a INBIOT a través de la dirección de correo electrónico: </span><a href="mailto:info@inbiot.es" style="text-decoration:none;"><u><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#0563c1;">info@inbiot.es</span></u></a><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Las partes aceptan equiparar jurídicamente la firma autógrafa del Usuario a la realizada mediante cualquier otro tipo de clave, código o elemento de seguridad identificativo. Sin perjuicio de lo anterior, INBIOT podrá exigir, cuando lo considere necesario, confirmación por escrito del Usuario.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol start="3" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Contrato</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Formalización del Contrato:</span></strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">La contratación de un producto y/o servicio ofrecido por INBIOT se formaliza a través de la aceptación del presupuesto previamente proporcionado por INBIOT MONITORING SL al Usuario, seguida del primer pago conforme a las condiciones especificadas en dicho presupuesto.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">La aceptación del presupuesto por parte del Usuario y la realización del primer pago constituyen la confirmación de contratar y de adherirse a los Términos de Uso establecidas por INBIOT.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Modificación de tarifas:</span></strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT se reserva el derecho de modificar las tarifas de sus productos y/o servicios de manera unilateral y en cualquier momento. Las modificaciones en las tarifas serán aplicables a partir de la fecha en que INBIOT comunique el cambio a los Usuarios mediante los medios que considere apropiados, ya sea a través de notificaciones directas, actualizaciones en su Sitio Web o cualquier otro medio de comunicación establecido.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Aceptación de Modificaciones:</span></strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">El Usuario acepta que la continuación en el uso de los productos y/o servicios después de la notificación de la modificación tarifaria implica la aceptación de las nuevas tarifas. En caso de desacuerdo con las modificaciones, el Usuario tendrá la opción de cancelar el contrato conforme a los procedimientos establecidos.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><strong><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></strong></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol start="4" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Pago y acceso</li>
    </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT cobrará periódicamente al Usuario una tarifa recurrente dependiendo del tipo de cuenta contratada.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">El servicio será cobrado cada período por adelantado y no será reembolsable. No habrá reembolso o créditos por meses parciales del servicio, ni habrá reembolso en caso de que el Usuario no utilice el servicio durante el período de tiempo en que la cuenta esté abierta. En ningún caso se harán excepciones, salvo en los supuestos que se especifican en la política de devoluciones</span><span style="font-family:Roboto; color:#333333;">establecida en la cláusula 10 de los presentes términos de</span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Uso.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">El Usuario con la creación de la cuenta, acepta que no será necesario confirmar la recepción de la aceptación del contrato cuando se haya celebrado exclusivamente mediante comunicación electrónica.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">En caso de impago o devolución del recibo, se notificará al Usuario del incumplimiento y perderá automáticamente la capacidad de acceder al servicio. Los datos se eliminarán en un plazo de treinta (30) días a partir de la fecha de incumplimiento.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol start="5" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Modificaciones de los servicios y de los planes</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT se reserva el derecho de modificar o suspender, temporal o permanentemente, el servicio y el Sitio Web en cualquier momento y por cualquier motivo si lo considera conveniente, con o sin previo aviso.</span><span style="font-family:Roboto; color:#333333;">En dicho caso, el Usuario podrá no aceptar dichas modificaciones interrumpiendo simplemente el acceso al Sitio Web y el uso de los Servicios.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt;">INBIOT se reserva el derecho de cambiar las cuotas mensuales con un aviso de quince (15) días naturales. La notificación de los cambios de cuota se publicará en su Sitio Web.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol start="6" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; background-color:#fdfdfd;">Eliminación y rescisión</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">El Usuario es responsable de la correcta eliminación de su cuenta. La cuenta puede ser eliminada en cualquier momento a través del apartado configuración menú de perfil.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Si la cuenta es eliminada todo su contenido se eliminará después de treinta (30) días. A partir de entonces, no se le cobrará.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT se reserva el derecho de impedir el uso d</span><span style="font-family:Roboto; color:#333333;">el Sitio Web</span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">a aquellos que no cumplan con las presentes condiciones y términos de uso.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt;  font-size:12pt;"><span ></span></p>
    <ol start="7" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Derecho de copia y Propiedad Intelectual</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; "><br><span style="font-family:Roboto; font-size:10.5pt; color:#333333;">Con excepción de los contenidos facilitados por los Usuarios, o aquellos relacionados con fuentes externas, toda la información y los materiales disponibles en el Sitio Web como parte del mismo y de los Servicios (incluyendo textos, gráficos, imágenes, ilustraciones, dibujos, iconos, fotografías, videoclip, audio-clip, interfaz, software, logotipos, títulos y nombres, colectivamente "propiedad intelectual") son propiedad de INBIOT, a menos que se especifique lo contrario por escrito y se acuerdo con los titulares de los derechos correspondientes. Estos elementos están sujetos a las disposiciones establecidas en los Términos de Uso.</span><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">El Sitio Web y todo lo que es materia de propiedad intelectual están protegidos por leyes sobre derechos de autor, signos distintivos y marcas vigentes en España y también por el derecho de la Unión Europea, por los tratados y por las convenciones internacionales, y por las leyes de otros países en los casos aplicables. Con excepción de aquella información de dominio público o para cuya utilización el Usuario haya sido autorizado por INBIOT el Usuario no puede utilizar, reproducir, copiar, modificar, publicar, transmitir, distribuir, ejecutar, ver, descargar, conceder en licencia, introducir en una base de datos, usar para crear obras derivadas, someter a ingeniería inversa, transferir o vender todo lo que es materia de propiedad intelectual, información, software o productos obtenidos de este Sitio Web o de los Servicios o mediante estos, total o parcialmente.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">El Usuario es responsable del contenido y de los materiales puestos a disposición al utilizar el Sitio Web y los Servicios; al poner materiales y contenidos a disposición, el Usuario declara y garantiza que estos no infringen los Términos de Uso.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">Las marcas, los nombres con dominio, los logotipos, los eslóganes que aparecen con o son relativos a los Servicios son propiedad de INBIOT o de sus socios comerciales, filiales, agentes, sociedades del grupo. El Usuario no puede copiar, publicar o utilizar dichas marcas sin la autorización preventiva escrita de expert.ai o de los terceros titulares legítimos de dichos derechos.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">El uso del Sitio Web y de los Servicios no otorga derechos de uso o de licencia de uso o de reproducción de las marcas de expert.ai o de terceros a los Usuarios.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">El usuario también acepta y se obliga a no utilizar robots, spiders y otros aparatos automatizados o procesos manuales para controlar o copiar cualquier contenido del servicio.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  background-color:#fdfdfd;"><br><span style=" font-size:12pt;"></span></p>
    <ol start="8" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Restricciones de Uso</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto;">Se concede una licencia no exclusiva para visualizar el contenido en el Sitio Web, únicamente durante el tiempo de acceso a este. A excepción de lo necesario para la revisión del material en el Sitio Web, queda prohibida la reproducción electrónica, adaptación, distribución, y ejecución. El uso comercial de cualquier contenido con fines de lucro está estrictamente prohibido. Asimismo, queda prohibida la utilización de cualquiera de nuestras marcas como metatag en otros sitios web.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto;">La autorización para utilizar el Sitio Web y los Servicios se considera revocada automáticamente en caso de infracción, total o parcial, de los Términos de Uso y de las leyes aplicables.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto;">El Usuario está facultado para visitar, ver y conservar una copia de las páginas del Sitio Web para su uso personal, con el compromiso de no duplicar, descargar, publicar, modificar ni distribuir el material del Sitio Web para fines comerciales o para cualquier otro propósito distinto al descrito en estos Términos de Uso.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto;">Además, el Usuario acepta no efectuar deep-linking en el Sitio Web con ningún propósito, salvo que se le haya otorgado una autorización específica por parte de INBIOT-</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Normas de uso generales:</span></strong></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></strong></p>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">-</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">El usuario es completamente responsable del acceso y correcto uso de la Plataforma con sujeción a la legislación vigente, así como a los principios de buena fe, la moral, buenas costumbres y orden público. Y específicamente, adquiere el compromiso de observar diligentemente los presentes Términos de Uso.</span></p>
    </div>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">-</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">El usuario acepta no revender, duplicar, reproducir o explotar cualquier parte del servicio sin el expreso consentimiento escrito de INBIOT. El usuario no puede usar el servicio para almacenar, alojar, o enviar correo electrónico dañino o no solicitado (spam).</span></p>
    </div>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:108%; font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">-</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">El usuario entiende que el servicio puede utilizarse para la transmisión de su contenido y que, durante este proceso, su contenido (incluidas facturas, avisos de pago, datos de los dispositivos y mensajes personales) puede transferirse a través de Internet.</span></p>
    </div>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:108%; font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">-</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT no da garantías respecto a la capacidad del usuario para usar el servicio, su satisfacción con el mismo, que esté disponible en todo momento, ininterrumpidamente y sin errores, la precisión de los cálculos matemáticos llevados a cabo por el servicio y la corrección de los errores del servicio.</span></p>
    </div>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:108%; font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">-</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Ni INBIOT, ni sus socios, ni sus patrocinadores son responsables de cualquier daño directo, indirecto, secundario, consiguiente, especial, ejemplar, punitivo o de cualquier otro tipo que surja o que esté relacionado de cualquier forma con el uso que el usuario haga del servicio. El usuario solo puede solucionar su insatisfacción con el servicio dejando de utilizarlo y eliminando su cuenta.</span></p>
    </div>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:108%; font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">-</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Si cualquier condición de las aquí descritas queda invalidada o no puede aplicarse, la aplicación de cualquiera de las restantes no debe en ningún caso verse afectada.</span></p>
    </div>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><br><strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Normas de uso particulares:</span></strong></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></strong></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Al usar los dispositivos y los servicios, usted acepta lo siguiente:</span></p>
    <div style="margin-top:14pt; margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; font-size:10pt; letter-spacing:0.2pt; color:#333333;">-</span><span style="width:14pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;">No comercializará los dispositivos o los servicios alquilando, vendiendo, otorgando licencias o distribuyendo los dispositivos o los servicios.</span></p>
    </div>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; font-size:10pt; letter-spacing:0.2pt; color:#333333;">-</span><span style="width:14pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;">No accederá a los servicios para crear un servicio similar o competitivo.</span></p>
    </div>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; font-size:10pt; letter-spacing:0.2pt; color:#333333;">-</span><span style="width:14pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;">No realizará ingeniería inversa, desmontará, compilará inversa ni realizará trabajos derivados de ningún componente de los dispositivos o los servicios.</span></p>
    </div>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; font-size:10pt; letter-spacing:0.2pt; color:#333333;">-</span><span style="width:14pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;">No copiará, reproducirá, distribuirá, republicará, descargará, mostrará, publicará ni transmitirá los servicios de ninguna forma ni por ningún medio.</span></p>
    </div>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; font-size:10pt; letter-spacing:0.2pt; color:#333333;">-</span><span style="width:14pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;">No utilizará los servicios para distribuir virus, gusanos u otro software que pueda dañar cualquier elemento de los servicios.</span></p>
    </div>
    <div style="margin-left:18pt; margin-bottom:14pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; font-size:10pt; letter-spacing:0.2pt; color:#333333;">-</span><span style="width:14pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;">No eliminará, alterará ni ocultará los avisos de derechos de propiedad intelectual que puedan mostrarse en relación con los dispositivos o los servicios.</span></p>
    </div>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol start="9" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Seguridad y privacidad</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT MONITORING, S.L. se encuentra debidamente inscrita en la Agencia Española de Protección de Datos, cumpliendo así la normativa vigente sobre confidencialidad y seguridad de los datos confidenciales.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Los datos personales que los usuarios introducen en</span><span style="font-family:Roboto; color:#333333;">el Sitio Web</span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">se encuentran alojados en los servidores de Amazon Web Services (AWS) ubicados en la Unión Europea. Este alojamiento asegura el cumplimiento con la normativa aplicable sobre la transferencia y almacenamiento de datos, así como los más altos niveles de seguridad y accesibilidad disponibles en el mercado. AWS garantiza medidas técnicas y organizativas adecuadas para proteger los datos personales contra accesos no autorizados, pérdidas o alteraciones.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT MONITORING S.L. tiene acceso a los datos de las cuentas con el propósito de mejorar el servicio y proporcionar un soporte adecuado. El acceso a estos datos se realiza bajo estrictas medidas de seguridad y únicamente para las finalidades relacionadas con la prestación de servicios y el mantenimiento de calidad del mismo.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Todos los empleados, colaboradores y socios de INBIOT tienen firmado un contrato de confidencialidad en cumplimiento con las normativas de privacidad, inclusive el RGPD.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Puede leer nuestra Política de Privacidad de los datos </span><a href="../policy"><span style="font-family:Roboto; letter-spacing:0.4pt;">aquí.</span></a></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol start="10" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Exclusión de Garantías y Responsabilidad</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT MONITORING S.L. no ofrece ninguna garantía sobre sus productos, ya sea explícita, implícita o legal. Esto incluye, garantías de calidad, la adecuación a un propósito específico, la propiedad, la ausencia de infracción de derechos de terceros, la satisfacción o la precisión. INBIOT no asegura que el uso del producto o servicio esté libre de errores, interrupciones o problemas en el tratamiento de datos.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt;  font-size:12pt;"><span ></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">La Plataforma proporciona información sobre mediciones interiores para la conveniencia del Usuario, tal como está y según disponibilidad. INBIOT no garantiza que esta información sea precisa, disponible o confiable. Cualquier uso de esta información, de los servicios o del producto es bajo riesgo del Usuario. INBIOT, no se hace responsable por pérdidas, daños o responsabilidades que puedan surgir del uso de esta información.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt;  font-size:12pt;"><span ></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT MONITORING S.L. no será responsable por daños de ningún tipo derivados del uso del Sitio Web o de la información, contenido, materiales, productos (incluido software / hardware) o servicios disponibles a través del sitio. Esto incluye, sin limitación, daños directos, indirectos, incidentales, punitivos y emergentes, salvo que se indique lo contrario en estos Términos de Uso.</span></p>
    <p style="margin-top:0pt; margin-bottom:8pt;"></p>
    <p style="margin-top:0pt; margin-bottom:8pt;"></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol start="11" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  background-color:#fdfdfd;"><span style="font-family:Roboto; font-size:18pt; color:#333333;">Disposiciones Generales</span></li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Con carácter general, el presente Sitio Web y las relaciones entre el INBIOT y los Usuarios, se rigen a todos los efectos por la legislación española y los Jueces y Tribunales de Pamplona.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">El Uso de los Servicios no se autoriza en las jurisdicciones que no reconocen la validez de todas las disposiciones de estos términos y condiciones, incluyéndose, sin limitaciones, este párrafo.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">No existe ninguna empresa conjunta, colaboración, relación de trabajo o de agencia entre el Usuario e INBIOT como consecuencia de los Términos de Uso o de la utilización de nuestros Servicios y del Sitio Web.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">En caso de invalidez o de no aplicabilidad por ley de alguna disposición de los Términos de Uso, incluyéndose a título meramente indicativo y no exhaustivo, las exclusiones de garantía y limitaciones de responsabilidad indicadas antes, las restantes disposiciones seguirán siendo plenamente vigentes y eficaces y la disposición no válida y/o no aplicable se considerará sustituida por la disposición válida y aplicable que, mayormente, corresponda a la ratio y al espíritu de la disposición original.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">La ejecución de los Términos de Uso por parte de INBIOT queda sujeta a las leyes y a los procedimientos vigentes y aplicables y ningún contenido de los Términos de Uso puede derogar el derecho de INBIOT a conformarse a las solicitudes o a los requisitos derivados de la aplicación de dichas leyes.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  background-color:#fdfdfd;"><span style="font-family:Roboto;"></span></p>
    <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <strong><span style=" ">Fecha Última actualización:</span></strong><span > 17 de septiembre de 2024.</span>
    </p>
    </div>`,
    `<div>
    <div style="clear:both;">
        <p style="margin-top:0pt; margin-bottom:0pt; "><br></p>
    </div>
    <p style="margin-top:0pt; margin-bottom:15pt; text-align:center; ">
      <strong><span style="font-size: 28px">TERMS OF USE</span></strong>
    </p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  background-color:#fdfdfd;"><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#333333;">By accessing and/or using the services of My Inbiot (the "Platform") at </span><a href="http://www.myinbiot.com" style="text-decoration:none;"><u><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#0563c1;">www.myinbiot.com</span></u></a><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#333333;"> (the "Website") owned by INBIOT MONITORING, S.L. ("INBIOT"), you agree to these Terms of Use and our Privacy Policy, either on your own behalf or on behalf of the entity you represent. By doing so, you represent and warrant that you have the right, authority, and capacity to accept and commit to fully complying with its content. The subject browsing the Website and using its services is hereinafter defined as "User" and agrees to accept and respect these Terms of Use.</span><span style="font-family:Roboto; font-size:10.5pt; color:#333333;">The User, by accessing the Website or using the offered Services, agrees to be bound by these Terms of Use. If you do not agree to the Terms of Use, you should not use the Services offered or the Website.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT assumes that any user using the service has read and accepted these Terms of Use, and therefore, INBIOT </span><span style="font-family:Roboto; color:#333333;">encourages Users to periodically check the Terms of Use, also to check for any updates or modifications, by consulting the Terms of Use page</span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">. Furthermore, INBIOT reserves the right to update and modify the Terms of Use without any prior notice, and these will always be available on the Website.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; "><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#333333;">This agreement shall be valid from the date on which the acceptance checkbox of these Terms of Use is marked, upon registration. Any new features that may be added to the Services will be subject to the terms of use. If the user continues to use the Service after any modification, it will constitute their agreement to such modifications. The most recent version of the Terms of Use can be consulted at </span><a href="https://myinbiot.com/terms" style="text-decoration:none;"><u><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#0563c1;">https://myinbiot.com/terms</span></u></a></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Failure to comply with any of the Terms of Use may result in the cancellation of the account.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">If you do not accept any of the Terms of Use, do not create an account on our Platform.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Identification of the Platform Holder</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">The owner of this Website is:</span></p>
    <ul type="disc" style="margin:0pt; padding-left:0pt;">
        <li style="margin-top:14pt; margin-left:27.6pt; text-align:justify;  padding-left:8.4pt;  font-size:10pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;"><span style="font-family:Roboto; font-size:10.5pt;">Company Name: </span><span style="font-family:Roboto; font-size:10.5pt;"></span><strong><span style="font-family:Roboto; font-size:10.5pt;">INBIOT MONITORING S.L.</span></strong></li>
        <li style="margin-left:27.6pt; text-align:justify;  padding-left:8.4pt;  font-size:10pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;"><span style="font-family:Roboto; font-size:10.5pt;">C.I.F.: B71361596</span></li>
        <li style="margin-left:27.6pt; text-align:justify;  padding-left:8.4pt;  font-size:10pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;"><span style="font-family:Roboto; font-size:10.5pt;">Registered Office: Paseo Santxiki, 2, Office LB5, 31192 Mutilva, Navarra (Spain).</span></li>
        <li style="margin-left:27.6pt; text-align:justify;  padding-left:8.4pt;  font-size:10pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;"><span style="font-family:Roboto; font-size:10.5pt;">Registered in the Commercial Registry of Navarra,</span><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt;">volume 1922, folio 1, sheet NA-38.214</span></li>
        <li style="margin-left:27.6pt; margin-bottom:14pt; text-align:justify;  padding-left:8.4pt;  font-size:10pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;"><span style="font-family:Roboto; font-size:10.5pt;">Contact email: </span><a href="mailto:info@inbiot.es" style="text-decoration:none;"><u><span style="font-family:Roboto; font-size:10.5pt; color:#0563c1;">info@inbiot.es</span></u></a><span style="font-family:Roboto; font-size:10.5pt;"></span></li>
    </ul>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol start="2" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Account Conditions</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">For the purposes of these Terms of Use, a User is understood to be any natural or legal person who accesses, registers, or uses the Services offered by INBIOT on the Platform.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">To access the Services, the User must be over eighteen (18) years old.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">The User must provide a full legal name, an email address, and all information required by INBIOT during the account creation process.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">It is the User's responsibility to provide truthful and accurate information. INBIOT reserves the right to delete any account if there is suspicion of its lack of truthfulness or non-compliance with the terms of use. Likewise, the User is responsible for keeping the provided data updated, complete, and correct at all times and for assuming any damage resulting from non-compliance with these obligations.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">The User acknowledges and declares to be solely responsible for the truthfulness and accuracy of the information and personal data provided.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  background-color:#fdfdfd;"><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#333333;">The User is responsible for maintaining the privacy of their account. INBIOT is not responsible for any damage or loss that may result from a User's failure to protect their access information. In case of suspected unauthorized use of their account, the User agrees to notify INBIOT immediately through the email address: </span><a href="mailto:info@inbiot.es" style="text-decoration:none;"><u><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#0563c1;">info@inbiot.es</span></u></a><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">The parties agree to legally equate the User's handwritten signature with any other type of key, code, or identifying security element. Notwithstanding the foregoing, INBIOT may require, when deemed necessary, written confirmation from the User.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol start="3" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Contract</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Formalization of the Contract: </span></strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">The hiring of a product and/or service offered by INBIOT is formalized through the acceptance of the quotation previously provided by INBIOT MONITORING SL to the User, followed by the first payment according to the conditions specified in that quotation.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">The acceptance of the quotation by the User and the completion of the first payment constitute confirmation of contracting and adherence to the Terms of Use established by INBIOT.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Modification of rates: </span></strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT reserves the right to modify the rates of its products and/or services unilaterally and at any time. The rate changes will be applicable from the date INBIOT communicates the change to Users through any means it deems appropriate, either through direct notifications, updates on its Website, or any other established means of communication.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Acceptance of Modifications: </span></strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">The User agrees that continued use of the products and/or services after notification of the rate change implies acceptance of the new rates. In case of disagreement with the modifications, the User will have the option to cancel the contract in accordance with the established procedures.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><strong><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></strong></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol start="4" type="1" style="margin:0pt; padding-left:0pt font-size: 24px; font-weight: bold;">
        <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Payment and Access</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT will periodically charge the User a recurring fee depending on the type of account contracted.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">The service will be charged each period in advance and will not be refundable. There will be no refunds or credits for partial months of service, nor will there be refunds if the User does not use the service during the period the account is open. In no case will exceptions be made, except in the cases specified in the return policy established in clause 10 of these Terms of Use.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">By creating the account, the User agrees that it will not be necessary to confirm the receipt of the contract acceptance when it has been concluded exclusively by electronic communication.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">In case of non-payment or return of the receipt, the User will be notified of the non-compliance and will automatically lose access to the service. The data will be deleted within thirty (30) days from the date of non-compliance.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol start="5" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Modifications of Services and Plans</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT reserves the right to modify or suspend, temporarily or permanently, the service and the Website at any time and for any reason it deems appropriate, with or without prior notice.</span><span style="font-family:Roboto; color:#333333;">In such a case, the User may not accept such modifications by simply discontinuing access to the Website and the use of the Services.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt;">INBIOT reserves the right to change monthly fees with a notice of fifteen (15) calendar days. Notice of fee changes will be posted on its Website.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol start="6" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; background-color:#fdfdfd;">Deletion and Termination</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">The User is responsible for the proper deletion of their account. The account can be deleted at any time through the profile menu settings section.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">If the account is deleted, all its content will be removed after thirty (30) days. After that, no charges will be made.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT reserves the right to prevent the use of</span><span style="font-family:Roboto; color:#333333;">the Website</span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">to those who do not comply with these terms and conditions of use.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt;  font-size:12pt;"><span ></span></p>
    <ol start="7" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Copyright and Intellectual Property Rights</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; "><br><span style="font-family:Roboto; font-size:10.5pt; color:#333333;">Except for content provided by Users or related to external sources, all information and materials available on the Website as part of it and the Services (including text, graphics, images, illustrations, drawings, icons, photographs, video clips, audio clips, interface, software, logos, titles, and names, collectively "intellectual property") are owned by INBIOT, unless otherwise specified in writing and in agreement with the corresponding rights holders. These elements are subject to the provisions set out in the Terms of Use.</span><span style="font-family:Roboto; font-size:10.5pt; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">The Website and all that is subject to intellectual property are protected by copyright laws, distinctive signs, and trademarks in force in Spain and also by European Union law, treaties, and international conventions, and by the laws of other countries in applicable cases. Except for public domain information or for which the User has been authorized by INBIOT, the User may not use, reproduce, copy, modify, publish, transmit, distribute, perform, view, download, license, enter into a database, use to create derivative works, subject to reverse engineering, transfer or sell all that is subject to intellectual property, information, software, or products obtained from this Website or Services, in whole or in part.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">The User is responsible for the content and materials made available when using the Website and Services; by making materials and content available, the User declares and warrants that they do not infringe the Terms of Use.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">Trademarks, domain names, logos, slogans appearing with or relating to the Services are owned by INBIOT or its business partners, affiliates, agents, group companies. The User may not copy, publish or use such trademarks without the prior written authorization of expert.ai or the legitimate third-party rights holders.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">The use of the Website and the Services does not grant rights to use or license to use or reproduce the trademarks of expert.ai or third parties to Users.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">The user also agrees and undertakes not to use robots, spiders, and other automated devices or manual processes to monitor or copy any content from the service.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  background-color:#fdfdfd;"><br><span style=" font-size:12pt;"></span></p>
    <ol start="8" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Restrictions of Use</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto;">A non-exclusive license is granted to view the content on the Website only during the time of access. Except as necessary for reviewing material on the Website, electronic reproduction, adaptation, distribution, and execution are prohibited. Commercial use of any content for profit is strictly prohibited. Likewise, the use of any of our trademarks as a metatag on other websites is prohibited.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto;">Authorization to use the Website and the Services is automatically revoked in case of partial or total infringement of the Terms of Use and applicable laws.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto;">The User is allowed to visit, view, and keep a copy of the Website pages for personal use, provided that the material on the Website is not duplicated, downloaded, published, modified, or distributed for commercial purposes or any other purpose not described in these Terms of Use.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto;">Furthermore, the User agrees not to deep-link to the Website for any purpose unless specifically authorized by INBIOT.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">General Use Rules:</span></strong></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></strong></p>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">-</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">The user is fully responsible for the access and proper use of the Platform in accordance with current legislation, as well as the principles of good faith, morality, good customs, and public order. Specifically, the user agrees to diligently observe these Terms of Use.</span></p>
    </div>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">-</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">The user agrees not to resell, duplicate, reproduce, or exploit any part of the service without the express written consent of INBIOT. The user may not use the service to store, host, or send harmful or unsolicited email (spam).</span></p>
    </div>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:108%; font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">-</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">The user understands that the service may be used for the transmission of their content and that, during this process, their content (including invoices, payment notices, device data, and personal messages) may be transferred over the Internet.</span></p>
    </div>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:108%; font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">-</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT makes no guarantees regarding the user's ability to use the service, their satisfaction with it, that it will be available at all times, uninterrupted, and error-free, the accuracy of mathematical calculations performed by the service, or the correction of service errors.</span></p>
    </div>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:108%; font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">-</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Neither INBIOT, nor its partners, nor its sponsors are responsible for any direct, indirect, secondary, consequential, special, exemplary, punitive, or any other type of damages arising or related in any way to the user's use of the service. The user may only remedy their dissatisfaction with the service by ceasing to use it and deleting their account.</span></p>
    </div>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; line-height:108%; font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">-</span><span style="width:13.8pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">If any of the conditions described here becomes invalid or unenforceable, the application of any of the remaining conditions shall in no way be affected.</span></p>
    </div>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><br><strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">Particular Use Rules:</span></strong></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><strong><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></strong></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">By using the devices and services, you agree to the following:</span></p>
    <div style="margin-top:14pt; margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; font-size:10pt; letter-spacing:0.2pt; color:#333333;">-</span><span style="width:14pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;">You will not commercialize the devices or services by renting, selling, licensing, or distributing the devices or services.</span></p>
    </div>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; font-size:10pt; letter-spacing:0.2pt; color:#333333;">-</span><span style="width:14pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;">You will not access the services to create a similar or competitive service.</span></p>
    </div>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; font-size:10pt; letter-spacing:0.2pt; color:#333333;">-</span><span style="width:14pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;">You will not reverse engineer, disassemble, decompile, or perform derivative works of any component of the devices or services.</span></p>
    </div>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; font-size:10pt; letter-spacing:0.2pt; color:#333333;">-</span><span style="width:14pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;">You will not copy, reproduce, distribute, republish, download, display, post, or transmit the services in any form or by any means.</span></p>
    </div>
    <div style="margin-left:18pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; font-size:10pt; letter-spacing:0.2pt; color:#333333;">-</span><span style="width:14pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;">You will not use the services to distribute viruses, worms, or other software that may harm any component of the services.</span></p>
    </div>
    <div style="margin-left:18pt; margin-bottom:14pt; background-color:#fdfdfd;">
        <p style="margin-top:0pt; margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify;  font-size:10.5pt;"><span style="font-family:Roboto; font-size:10pt; letter-spacing:0.2pt; color:#333333;">-</span><span style="width:14pt; font:7pt 'Times New Roman'; display:inline-block;">    </span><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;">You will not remove, alter, or obscure any intellectual property rights notices that may be displayed in connection with the devices or services.</span></p>
    </div>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol start="9" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Security and Privacy</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT MONITORING, S.L. is duly registered with the Spanish Data Protection Agency, thus complying with current regulations on confidentiality and security of confidential data.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">The personal data entered by users on</span><span style="font-family:Roboto; color:#333333;">the Website</span><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">are hosted on Amazon Web Services (AWS) servers located in the European Union. This hosting ensures compliance with the applicable regulations on data transfer and storage, as well as the highest levels of security and accessibility available on the market. AWS guarantees adequate technical and organizational measures to protect personal data against unauthorized access, loss, or alteration.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT MONITORING S.L. has access to account data for the purpose of improving the service and providing adequate support. Access to this data is carried out under strict security measures and only for purposes related to the provision of services and maintaining their quality.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">All employees, collaborators, and partners of INBIOT have signed a confidentiality agreement in compliance with privacy regulations, including the GDPR.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">You can read our Data Privacy Policy </span><a href="../policy"><span style="font-family:Roboto; letter-spacing:0.4pt;">here.</span></a></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:18pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.2pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol start="10" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Exclusion of Warranties and Liability</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT MONITORING S.L. does not offer any warranties on its products, whether explicit, implicit, or statutory. This includes, but is not limited to, warranties of quality, fitness for a particular purpose, ownership, non-infringement of third-party rights, satisfaction, or accuracy. INBIOT does not ensure that the use of the product or service is error-free, uninterrupted, or without data processing issues.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt;  font-size:12pt;"><span ></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">The Platform provides information about indoor measurements for the User's convenience, as is and as available. INBIOT does not guarantee that this information is accurate, available, or reliable. Any use of this information, services, or product is at the User's risk. INBIOT is not liable for any losses, damages, or liabilities that may arise from the use of this information.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt;  font-size:12pt;"><span ></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">INBIOT MONITORING S.L. will not be liable for any damages arising from the use of the Website or the information, content, materials, products (including software/hardware), or services available through the site. This includes, but is not limited to, direct, indirect, incidental, punitive, and consequential damages, unless otherwise stated in these Terms of Use.</span></p>
    <p style="margin-top:0pt; margin-bottom:8pt;"></p>
    <p style="margin-top:0pt; margin-bottom:8pt;"></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <ol start="11" type="1" style="margin:0pt; padding-left:0pt; font-size: 24px; font-weight: bold;">
        <li style="margin-left:36pt; text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">General Provisions</li>
    </ol>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; letter-spacing:0.4pt; color:#333333;">In general, this Website and the relationships between INBIOT and Users are governed for all purposes by Spanish law and the Judges and Courts of Pamplona.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">The Use of the Services is not authorized in jurisdictions that do not recognize the validity of all the provisions of these terms and conditions, including, without limitation, this paragraph.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">There is no joint venture, collaboration, employment, or agency relationship between the User and INBIOT as a result of the Terms of Use or the use of our Services and the Website.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">If any provision of the Terms of Use is found to be invalid or unenforceable by law, including but not limited to the aforementioned disclaimers of warranties and limitations of liability, the remaining provisions shall remain in full force and effect, and the invalid and/or unenforceable provision will be deemed replaced by the valid and applicable provision that most closely matches the ratio and spirit of the original provision.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;">The enforcement of the Terms of Use by INBIOT is subject to the laws and applicable and current procedures, and nothing in the Terms of Use can derogate from INBIOT's right to comply with requests or requirements resulting from the application of these laws.</span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  font-size:10.5pt; background-color:#fdfdfd;"><span style="font-family:Roboto; color:#333333;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify;  background-color:#fdfdfd;"><span style="font-family:Roboto;"></span></p>
    <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
    <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
      <strong><span style=" ">Last update date:</span></strong><span
        > September 17, 2024.</span>
    </p>
    <div style="clear:both;">
        <div style="float:right;">
            <p style="margin-top:0pt; margin-bottom:0pt; ">1</p>
        </div><br style="clear:both;">
    </div>
</div>
`
  ],
  cookies: [
    `<div>
    <div style="clear:both;">
        <p style="margin-top:0pt; margin-bottom:15pt; text-align:center; ">
          <strong><span style="font-size: 28px">POLÍTICA DE COOKIES</span></strong>
        </p>
        <p style="margin-top:0pt; margin-bottom:15pt; text-align:center; ">
          <strong><u><span style=" ">www.myinbiot.com</span></u></strong>
        </p>
        <p style="margin-top:0pt; margin-bottom:15pt; text-align:justify; ">
          <strong><span style=" ">&#xa0;</span></strong>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>INBIOT MONITORING, S.L. (en adelante,  "INBIOT ") informa, a través de la presente Política de Cookies que, en </span><span style="color:black;"><a href="http://www.myinbiot.com"><span style=''>www.myinbiot.com</span></a></span><span style='color:#333333;'> utilizamos cookies y tecnologías similares para mejorar la experiencia de nuestros usuarios, analizar el tráfico del sitio web y personalizar el contenido. Esta Política de Cookies explica qué son las cookies, cómo las utilizamos, y las opciones que tienes para gestionarlas.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <ol style="margin-bottom:0cm;margin-top:0cm; font-size: 24px; font-weight: bold;" start="1" type="1">
            <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">¿Qué son las Cookies?</li>
        </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>Las cookies son archivos o ficheros que se descargan en el dispositivo del usuario con la finalidad de almacenar datos que podrán ser actualizados y recuperados por la entidad responsable de su instalación. Estos archivos permiten que el sitio web recuerde las acciones y preferencias del usuario (como inicio de sesión, idioma, tamaño de fuente y otras configuraciones) durante un período de tiempo determinado, de manera que el usuario no tenga que volver a configurarlas cada vez que regrese al sitio o navegue de una página a otra.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>Los usos concretos que hacemos de estas tecnologías se describen a continuación.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <ol style="margin-bottom:0cm;margin-top:0cm; font-size: 24px; font-weight: bold;" start="2" type="1">
            <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Tipos de Cookies que utilizamos</li>
        </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>En Myinbiot.com utilizamos los siguientes tipos de Cookies:</span></p>
        <table style="border-collapse:collapse;border:none;width:100%;">
            <tbody>
                <tr>
                    <td style="width: 120.25pt;border: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><span style='color:#333333;'>Cookies de Sesión</span></p>
                    </td>
                    <td style="width: 304.45pt;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 0cm 5.4pt;vertical-align: top;">
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><strong><span style='color:#333333;'>Finalidad</span></strong><span style='color:#333333;'>: Identificar y mantener la sesión de un usuario, así como recabar y almacenar datos mientras éste navega por nuestra web. Estas cookies permiten a LogRocket registrarlas interacciones del usuario, como clics, movimientos del ratón y otras interacciones con la página.</span></p>
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><strong><span style='color:#333333;'>Duración:</span></strong><span style='color:#333333;'> Se eliminar al cerrar el navegador.</span></p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 120.25pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><span style='color:#333333;'>Cookies de Rendimiento/ medición</span></p>
                    </td>
                    <td style="width: 304.45pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><strong><span style='color:#333333;'>Finalidad:</span></strong><span style='color:#333333;'> Recopilar información sobre cómo los usuarios interactúan con nuestra web, incluyendo métricas sobre el rendimiento de la página, tiempos de carga, y cómo se navega entre las páginas. LogRocket utiliza esta información para identificar y corregir problemas, optimizando así la experiencia del usuario.</span></p>
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><strong><span style='color:#333333;'>Duración:</span></strong><span style='color:#333333;'> Variable, generalmente persistente durante un máximo de 1 año.</span></p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 120.25pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><span style='color:#333333;'>Cookies de Terceros: Cookies de análisis</span></p>
                    </td>
                    <td style="width: 304.45pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><strong><span style='color:#333333;'>Finalidad:</span></strong><span style='color:#333333;'> Estas cookies son establecidas por terceros proveedores, como Google Analytics y Mixpanel, y se utilizan para recopilar información sobre el comportamiento del usuario en nuestra web Los datos recogidos permiten cuantificar el número de usuarios del producto ofertado y se utilizan para analizar patrones de tráfico y mejorar nuestra web, así como para personalizar la publicidad mostrada al usuario.</span></p>
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><strong><span style='color:#333333;'>Duración: </span></strong><span style='color:#333333;'>Variable según el proveedor, normalmente entre 1 día y 2 años.</span></p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 120.25pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><span style='color:#333333;'>Cookies de Preferencias</span></p>
                    </td>
                    <td style="width: 304.45pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><strong><span style='color:#333333;'>Finalidad</span></strong><span style='color:#333333;'>: Permiten recordar información para que el usuario acceda al servicio con determinadas características que pueden diferenciar su experiencia de la de otros usuarios. Por lo que almacenan información sobre las preferencias del usuario, como el idioma seleccionado o la región desde la que accede, con el fin de ofrecer una experiencia personalizada durante las visitas futuras.</span></p>
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><strong><span style='color:#333333;'>Duración:</span></strong><span style='color:#333333;'> Persistente durante un máximo de 1 año.</span></p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <ol style="margin-bottom:0cm;margin-top:0cm; font-size: 24px; font-weight: bold;" start="3" type="1">
            <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">¿Quién utiliza la información almacenada en las Cookies?</li>
        </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>La información almacenada en las Cookies de nuestra web es utilizada exclusivamente por INBIOT, salvo aquellas identificadas como cookies de terceros, que son utilizadas y gestionadas por entidades externas para proporcionarnos servicios solicitados por nosotros con el fin de mejorar nuestros servicios y la experiencia del usuario al navegar en nuestra web. Los principales terceros con los que trabajamos son:</span></p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;'>
            <ul style="margin-bottom:0cm;list-style-type: disc;">
                <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;'><strong><span style=''>LogRocket</span></strong><span style='color:#333333;'>: Recopila datos de rendimiento y comportamiento del usuario en tiempo real. LogRocket, Inc., 87 Summer Street, Boston, MA 02110, Estados Unidos.</span></li>
            </ul>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>La transferencia internacional de datos derivados de las Cookies, a proveedores en Estados Unidos, como LogRocket, se realiza con las garantías adecuadas para la protección de la privacidad de los usuarios, de acuerdo con lo establecido en el Reglamento General de Protección de Datos (RGPD). Estas garantías incluyen la formalización de las cláusulas contractuales tipo aprobadas por la Comisión Europea.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><strong><u><span style='color:#333333;'>Rechazo y revocación del consentimiento</span></u></strong></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>Puede rechazar el uso de cookies en este sitio web y revocar su consentimiento utilizando la siguiente <a href="../manage-cookies">herramienta.</a></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <ol style="margin-bottom:0cm;margin-top:0cm; font-size: 24px; font-weight: bold;" start="4" type="1">
            <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Gestión de las Cookies: Eliminación</li>
        </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>Tenga en cuenta que, si acepta las cookies de terceros, deberá eliminarlas desde las opciones del navegador o desde el sistema ofrecido por el propio tercero.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>El usuario puede permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su dispositivo. A continuación, se incluyen enlacen con la información necesaria para configurar o deshabilitar las cookies en cada navegador:</span></p>
        <ul style="list-style-type: disc;">
            <li><span style="color:black;"><a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">Firefox</a></span><span style=''> </span></li>
            <li><span style="color:black;"><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es">Chrome</a></span><span style=''> </span></li>
            <li><span style="color:black;"><a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">Safari</a></span><span style=''> </span></li>
            <li><span style="color:black;"><a href="https://support.microsoft.com/es-es/windows/administrar-cookies-en-microsoft-edge-ver-permitir-bloquear-eliminar-y-usar-168dab11-0753-043d-7c16-ede5947fc64d">Internet Explorer</a></span><span style=''> </span></li>
            <li><span style="color:black;"><a href="https://support.microsoft.com/es-es/windows/microsoft-edge-datos-de-exploraci%C3%B3n-y-privacidad-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">Microsoft Edge</a></span><span style=''> </span></li>
        </ul>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <ol style="margin-bottom:0cm;margin-top:0cm; font-size: 24px; font-weight: bold;" start="5" type="1">
            <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Transferencias de datos a terceros países realizadas por INBIOT</li>
        </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>INBIOT realiza transferencias de datos a terceros países como se ha establecido en el punto 3 de la presente política de cookies. Estas transferencias se realizan conforme a lo establecido en el artículo 46 RGPD a Estados Unidos a través de garantías adecuadas.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>Puedes informarte de las transferencias a terceros países que, en su caso, realizan los terceros identificados en esta política de cookies en sus correspondientes políticas expuestas en el punto anterior.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <ol style="margin-bottom:0cm;margin-top:0cm; font-size: 24px; font-weight: bold;" start="6" type="1">
            <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Actualizaciones y Cambios en la Política de Cookies</li>
        </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>INBIOT puede modificar esta Política de Cookies, sin necesidad de informar a los usuarios, en función de nuevas exigencias legislativas, reglamentarias, o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos. Por ello, le recomendamos revisar esta política cada vez que acceda a nuestro sitio web con el objetivo de estar adecuadamente informado sobre cómo y para qué usamos las cookies.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><strong><u><span style='color:#333333;'><span style="text-decoration: none;"> </span></span></u></strong></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <ol style="margin-bottom:0cm;margin-top:0cm; font-size: 24px; font-weight: bold;" start="7" type="1">
            <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Política de Privacidad</li>
        </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>Puede leer nuestra </span><strong><a style='color:#5B9AD5;' href="../policy">política de privacidad</a></u></strong><span style='color:#5B9AD5;'> </span><span style='color:#333333;'>para una mayor información sobre el tratamiento de datos personales. </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <ol style="margin-bottom:0cm;margin-top:0cm; font-size: 24px; font-weight: bold;" start="8" type="1">
            <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Contacto</li>
        </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>Si tuviera alguna duda, comentario o sugerencia sobre la Política de Cookies, por favor escriba a: </span><span style="color:black;"><a href="mailto:info@inbiot.es"><span style=''>info@inbiot.es</span></a></span><span style='color:#333333;'> </span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
          <span >&#xa0;</span>
        </p>
        <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
          <strong><span style=" ">Fecha Última actualización:</span></strong><span
            > 17 de septiembre de 2024.</span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
    </div>
    <div style="clear:both;"><br style="clear:both;">
        <p style="margin-top:0pt; margin-right:18pt; margin-bottom:0pt; line-height:normal;"><a href="https://wordtohtml.net" target="_blank" style="font-size:11px; color: #d0d0d0;"></a></p>
    </div>
</div>`,
    `<div>
    <div style="clear:both;">
        <p style="margin-top:0pt; margin-bottom:15pt; text-align:center; ">
          <strong><span style="font-size: 28px">COOKIE POLICY</span></strong>
        </p>
        <p style="margin-top:0pt; margin-bottom:15pt; text-align:center; ">
          <strong><u><span style=" ">www.myinbiot.com</span></u></strong>
        </p>
        <p style="margin-top:0pt; margin-bottom:15pt; text-align:justify; ">
          <strong><span style=" ">&#xa0;</span></strong>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>INBIOT MONITORING, S.L. (hereinafter referred to as "INBIOT") informs, through this Cookie Policy, that on </span><span style="color:black;"><a href="http://www.myinbiot.com"><span style=''>www.myinbiot.com</span></a></span><span style='color:#333333;'> we use cookies and similar technologies to enhance the user experience, analyze website traffic, and personalize content. This Cookie Policy explains what cookies are, how we use them, and the options you have to manage them.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <ol style="margin-bottom:0cm;margin-top:0cm; font-size: 24px; font-weight: bold;" start="1" type="1">
            <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">What are Cookies?</li>
        </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>Cookies are files that are downloaded onto the user's device to store data that can be updated and retrieved by the entity responsible for their installation. These files allow the website to remember the user's actions and preferences (such as login, language, font size, and other settings) over a specific period, so the user does not have to reset them each time they return to the site or browse from one page to another.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>The specific uses of these technologies are described below.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <ol style="margin-bottom:0cm;margin-top:0cm; font-size: 24px; font-weight: bold;" start="2" type="1">
            <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Types of Cookies We Use</li>
        </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>On Myinbiot.com, we use the following types of cookies:</span></p>
        <table style="border-collapse:collapse;border:none;width:100%;">
            <tbody>
                <tr>
                    <td style="width: 120.25pt;border: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><span style='color:#333333;'>Session Cookies</span></p>
                    </td>
                    <td style="width: 304.45pt;border-top: 1pt solid windowtext;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-image: initial;border-left: none;padding: 0cm 5.4pt;vertical-align: top;">
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><strong><span style='color:#333333;'>Purpose:</span></strong><span style='color:#333333;'> To identify and maintain a user's session, as well as collect and store data while they browse our website. These cookies allow LogRocket to log user interactions, such as clicks, mouse movements, and other interactions with the page.</span></p>
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><strong><span style='color:#333333;'>Duration:</span></strong><span style='color:#333333;'> Deleted when the browser is closed.</span></p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 120.25pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><span style='color:#333333;'>Performance/Measurement Cookies</span></p>
                    </td>
                    <td style="width: 304.45pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><strong><span style='color:#333333;'>Purpose:</span></strong><span style='color:#333333;'> To collect information about how users interact with our website, including metrics on page performance, load times, and navigation between pages. LogRocket uses this information to identify and fix issues, optimizing the user experience.</span></p>
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><strong><span style='color:#333333;'>Duration:</span></strong><span style='color:#333333;'> Variable, generally persistent for up to 1 year.</span></p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 120.25pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><span style='color:#333333;'>Third-Party Cookies: Analytics Cookies</span></p>
                    </td>
                    <td style="width: 304.45pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><strong><span style='color:#333333;'>Purpose:</span></strong><span style='color:#333333;'> These cookies are set by third-party providers, such as Google Analytics and Mixpanel, and are used to collect information about user behavior on our website. The data collected helps quantify the number of users of the offered product and is used to analyze traffic patterns, improve our website, and personalize advertising shown to the user.</span></p>
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><strong><span style='color:#333333;'>Duration:</span></strong><span style='color:#333333;'> Variable depending on the provider, usually between 1 day and 2 years.</span></p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 120.25pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;">
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><span style='color:#333333;'>Preference Cookies</span></p>
                    </td>
                    <td style="width: 304.45pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;">
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><strong><span style='color:#333333;'>Purpose:</span></strong><span style='color:#333333;'> To remember information that allows the user to access the service with certain characteristics that may differentiate their experience from that of other users. For example, they store information about the user's preferences, such as the selected language or the region from which they access, to provide a personalized experience during future visits.</span></p>
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:  150%;'><strong><span style='color:#333333;'>Duration:</span></strong><span style='color:#333333;'> Persistent for up to 1 year.</span></p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <ol style="margin-bottom:0cm;margin-top:0cm; font-size: 24px; font-weight: bold;" start="3" type="1">
            <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Who Uses the Information Stored in the Cookies?</li>
        </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>The information stored in the cookies on our website is used exclusively by INBIOT, except for those identified as third-party cookies, which are used and managed by external entities to provide us with services requested by us to improve our services and the user experience while browsing our website. The main third parties we work with are:</span></p>
        <div style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;'>
            <ul style="margin-bottom:0cm;list-style-type: disc;">
                <li style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;'><strong><span style=''>LogRocket</span></strong><span style='color:#333333;'>: Collects user performance and behavior data in real time. LogRocket, Inc., 87 Summer Street, Boston, MA 02110, United States.</span></li>
            </ul>
        </div>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>The international transfer of data derived from the cookies to providers in the United States, such as LogRocket, is carried out with adequate guarantees for the protection of users' privacy, in accordance with the provisions of the General Data Protection Regulation (GDPR). These guarantees include the formalization of standard contractual clauses approved by the European Commission.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><strong><u><span style='color:#333333;'>Refusal and Withdrawal of Consent</span></u></strong></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>You may refuse the use of cookies on this website and withdraw your consent using the following <a href="../manage-cookies">tool.</a></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <ol style="margin-bottom:0cm;margin-top:0cm; font-size: 24px; font-weight: bold;" start="4" type="1">
            <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Cookie Management: Deletion</li>
        </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>Please note that if you accept third-party cookies, you must delete them from the browser options or from the system provided by the third party itself.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>The user may allow, block, or delete cookies installed on their device through the configuration of the browser options installed on their device. Below are links with the necessary information to configure or disable cookies in each browser:</span></p>
        <ul style="list-style-type: disc;">
            <li><span style="color:black;"><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Firefox</a></span><span style=''> </span></li>
            <li><span style="color:black;"><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">Chrome</a></span><span style=''> </span></li>
            <li><span style="color:black;"><a href="https://support.apple.com/en-us/guide/safari/sfri11471/mac">Safari</a></span><span style=''> </span></li>
            <li><span style="color:black;"><a href="https://support.microsoft.com/en-us/windows/manage-cookies-in-internet-explorer-168dab11-0753-043d-7c16-ede5947fc64d">Internet Explorer</a></span><span style=''> </span></li>
            <li><span style="color:black;"><a href="https://support.microsoft.com/en-us/microsoft-edge/browsing-data-and-privacy-04d84c5f-316b-c8d7-6c5e-729d2c3c93be">Microsoft Edge</a></span><span style=''> </span></li>
        </ul>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <ol style="margin-bottom:0cm;margin-top:0cm; font-size: 24px; font-weight: bold;" start="5" type="1">
            <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Data Transfers to Third Countries by INBIOT</li>
        </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>INBIOT transfers data to third countries as established in point 3 of this cookie policy. These transfers are carried out in accordance with Article 46 of the GDPR to the United States through adequate safeguards.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>You can find out about the transfers to third countries, if any, made by the third parties identified in this cookie policy in their corresponding policies set out in the previous point.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <ol style="margin-bottom:0cm;margin-top:0cm; font-size: 24px; font-weight: bold;" start="6" type="1">
            <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Updates and Changes to the Cookie Policy</li>
        </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>INBIOT may modify this Cookie Policy without the need to inform users, based on new legislative or regulatory requirements, or in order to adapt this policy to the instructions issued by the Spanish Data Protection Agency. Therefore, we recommend reviewing this policy each time you access our website to be adequately informed about how and why we use cookies.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><strong><u><span style='color:#333333;'><span style="text-decoration: none;"> </span></span></u></strong></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <ol style="margin-bottom:0cm;margin-top:0cm; font-size: 24px; font-weight: bold;" start="7" type="1">
            <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Privacy Policy</li>
        </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>You can read our </span><strong><a style='color:#5B9AD5;' href="../policy">privacy policy</a></u></strong><span style='color:#5B9AD5;'> </span><span style='color:#333333;'>for more information about the processing of personal data. </span></p>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <ol style="margin-bottom:0cm;margin-top:0cm; font-size: 24px; font-weight: bold;" start="8" type="1">
            <li style="text-align:justify;  font-family:Roboto; font-size:18pt; letter-spacing:0.2pt; color:#333333; background-color:#fdfdfd;">Contact</li>
        </ol>
        <p style="margin-top:0pt; margin-bottom:0pt; "><br><span style=" font-size:12pt;"></span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'>If you have any questions, comments, or suggestions regarding the Cookie Policy, please write to: </span><span style="color:black;"><a href="mailto:info@inbiot.es"><span style=''>info@inbiot.es</span></a></span><span style='color:#333333;'> </span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
        <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
          <span >&#xa0;</span>
        </p>
        <p style="margin-top:0pt; margin-bottom:8pt; text-align:justify">
          <strong><span style=" ">Last Updated:</span></strong><span
            > September 17, 2024.</span>
        </p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;text-align:justify;line-height:150%;background:#FDFDFD;'><span style='color:#333333;'> </span></p>
    </div>
    <div style="clear:both;"><br style="clear:both;">
        <p style="margin-top:0pt; margin-right:18pt; margin-bottom:0pt; line-height:normal;"><a href="https://wordtohtml.net" target="_blank" style="font-size:11px; color: #d0d0d0;"></a></p>
    </div>
</div>`
  ],
  Configuration: ['Configuración', 'Configuration', 'Konfiguration'],
  accountSettings: ['Configuración de la cuenta', 'Account Settings', 'Kontoeinstellungen'],
  addUser: ['Añadir nuevo usuario', 'Add new user', 'Neuen Benutzer hinzufügen'],
  emailAddress: ['Indicar correo electrónico', 'Indicate e-mail address', 'E-Mail Adresse angeben'],
  newEmail: ['Nuevo usuario (correo)', 'New user (e-mail)', 'Neuer Benutzer (e-mail)'],
  Viewer: ['Visualización', 'Viewer', 'Betrachter'],
  Editor: ['Editor', 'Editor', 'Herausgeber'],
  Manager: ['Manager', 'Manager', 'Manager'],
  role: ['Seleccionar rol', 'Select role', 'Rolle auswählen'],
  deviceAccess: ['Acceso a dispositivos', 'Device access', 'Zugang zu Geräten'],
  selectAll: ['Seleccionar todos', 'Select all', 'Alle auswählen'],
  signal: ['Señal', 'Signal', 'Signal'],
  connectivity: ['Conectividad', 'Connectivity', 'Konnektivität'],
  serialNumber: ['Número de serie', 'Serial Number', 'Seriennummer'],
  moreRoleInfo: [
    'Más información acerca de los roles',
    'More information about roles',
    'Weitere Informationen zu den Rollen'
  ]
}
